import React, { useState } from 'react';
import * as S from 'styles';
import * as C from 'components';

export function FindPassword(props) {
  const [step, setStep] = useState('step1');

  const sampleData = {
    userName: '홍길동',
    carrier: '2',
    phone: '01012341234',
    phoneCode: '123456',
    userId: 'namo',
    password: '',
    checkPassword: '',
  };

  const [values, setValues] = useState({
    userName: '',
    carrier: '',
    phone: '',
    phoneCode: '',
    userId: '',
    password: '',
    checkPassword: '',
  });

  return (
    <>
      <C.AppBar>비밀번호 찾기</C.AppBar>
      <S.Div $padding="20px">
        {step === 'step1' && <C.AuthStep1 setStep={setStep} />}
        {step === 'step2' && (
          <C.AuthStep2 type="pw" setStep={setStep} values={values} setValues={setValues} sampleData={sampleData} />
        )}
        {step === 'step3' && <C.AuthStep3 setStep={setStep} values={values} setValues={setValues} />}
        {step === 'success' && <C.Success />}
        {step === 'failed' && <C.Failed />}
      </S.Div>
    </>
  );
}
