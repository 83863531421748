import { useEffect } from 'react';

export function useOutside(refs, callback) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (refs.every((ref) => ref.current && !ref.current.contains(event.target))) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [refs, callback]);
}
