import React, { useState, useRef } from 'react';
import * as S from 'styles';
import * as C from 'components';
import * as D from 'data';
import { Container } from 'layouts';
import * as htmlToImage from 'html-to-image';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

export function ChallengeFile(props) {
  const location = useLocation();
  const captureRef = useRef();
  const item = location.state;
  const navigate = useNavigate();
  const [toast, setToast] = useState('');

  const saveImg = () => {
    if (captureRef.current) {
      htmlToImage
        .toPng(captureRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'donation-detail.png';
          link.href = dataUrl;
          link.click();

          setToast('success');
        })
        .catch((error) => {
          setToast('error');
        });
    }
  };

  return (
    <>
      <Container title={item.challenge} $padding="0">
        <S.Div $height="calc(100% - 78px)">
          <S.Div ref={captureRef} $padding="20px">
            <S.Div $bgColor="gray50" $radius={10} $overflow="hidden" $shadow="lineShadow">
              <S.Div $padding="38px">
                <S.RowCenterDiv $gap={4}>
                  <C.Badge
                    $bgColor={
                      D.areaList.find((area) => area.name === item.host)?.color ||
                      D.areaList.find((area) => area.name === '기타').color
                    }
                  >
                    {item.host}
                  </C.Badge>
                  <C.Badge $bgColor="mint" icon="BadgeCheck">
                    성공
                  </C.Badge>
                </S.RowCenterDiv>
                <S.RowDiv $align="center" $pTop={4}>
                  <C.Img src="challengeSuccess" $width={128} />
                  <S.Div>
                    <S.Text $textAlign="center" $color="gray700" $type="m12">
                      나의 걸음수
                    </S.Text>
                    <S.Div $padding="8px 18px 4px">
                      <S.Text $textAlign="center" $type="b24" $color="gray900">
                        {item.walk.toLocaleString()}
                      </S.Text>
                      <S.Text $textAlign="center" $type="14" $color="gray500">
                        / {item.total.toLocaleString()}
                      </S.Text>
                    </S.Div>
                  </S.Div>
                </S.RowDiv>
              </S.Div>
              <S.Div $bgColor="white" $padding="20px 0">
                <S.Text $textAlign="center" $type="b20" $color="gray900">
                  {item.info}
                  <br />
                  {item.challenge}
                </S.Text>
                <S.Text $textAlign="center" $pTop={20} $color="gray600" $type="m">
                  획득 포인트&nbsp;
                  <S.Span $color="primary" $type="b">
                    {item.point} 포인트
                  </S.Span>
                </S.Text>
                <S.Text $padding="4px 0" $textAlign="center" $type="label12" $color="gray600">
                  걷기 기간
                  <S.Span $type="label12" $color="gray800" $pLeft={4}>
                    {moment(item.startDate, 'YYYY.MM.DD').format('YYYY년 MM월 DD일')} ~&nbsp;
                    {moment(item.endDate, 'YYYY.MM.DD').format('YYYY년 MM월 DD일')}
                  </S.Span>
                </S.Text>
              </S.Div>
            </S.Div>
          </S.Div>
        </S.Div>
        <S.Div $padding="8px 20px 20px" onClick={saveImg}>
          <C.Button $size="l" type="sub">
            이미지로 저장
          </C.Button>
        </S.Div>
      </Container>
      {toast && (
        <C.Toast setToast={setToast}>
          {toast === 'success' && <>이미지 저장이 완료되었습니다.</>}
          {toast === 'error' && <>다시 시도해 주세요.</>}
        </C.Toast>
      )}
    </>
  );
}
