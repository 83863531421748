import { Container } from 'layouts';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getNoticeList } from 'services/notice/NoticeService';
import * as S from 'styles';
import { formatApiDate } from 'utils';

const DEFAULT_PAGE_SIZE = 10;

export function NoticeList(props) {
	const navigate = useNavigate();
	const [page, setPage] = useState();
	const [hasNext, setHasNext] = useState(true);
	const [fetching, setFetching] = useState(false);
	const [noticeList, setNoticeList] = useState([]);
	const observer = useRef();
	const lastElementRef = useCallback(
		(node) => {
			if (!hasNext || fetching) return;

			if (observer.current) observer.current.disconnect();

			const options = {
				root: null,
				rootMargin: '0px',
				threshold: 1.0,
			};

			observer.current = new IntersectionObserver((entries) => {
				const [entry] = entries;
				if (entry.isIntersecting) {
					setPage((state) => state + 1);
				}
			}, options);
			if (node) observer.current.observe(node);
		},
		[noticeList],
	);

	const fetchNoticeList = async () => {
		if (!hasNext) {
			return;
		}

		setFetching(true);

		const response = await getNoticeList(page - 1, DEFAULT_PAGE_SIZE, 'createdDate,DESC');

		if (response && response.content && response.content.length > 0) {
			if (response.last) {
				setHasNext(false);
			}

			if (page === 1) {
				setNoticeList(response.content);
			} else {
				setNoticeList([...noticeList, ...response.content]);
			}
		}

		setFetching(false);
	};

	useEffect(() => {
		if (page) {
			fetchNoticeList();
		}
	}, [page]);

	useEffect(() => {
		setPage(1);
	}, []);

	return (
		<Container title="공지사항">
			<S.List>
				{noticeList.map((item, index) => (
					<S.ListItem
						key={`list_${item.id}`}
						ref={noticeList.length === index + 1 && hasNext ? lastElementRef : null}
						onClick={() => navigate(`${item.id}`, { state: item })}
						$padding="14.5px 8px"
						$borderBottom={noticeList.length - 1 !== index}
					>
						<S.Text $color="gray900" $type="m">
							{item.title}
						</S.Text>
						<S.Text $pTop={4} $color="gray500" $type="12" $lineHeight="14px">
							{formatApiDate(item.createdDate, 'YYYY.MM.DD')}
						</S.Text>
					</S.ListItem>
				))}
			</S.List>
		</Container>
	);
}
