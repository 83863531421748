import HttpRequest from '../HttpRequest';

export const getNoticeList = async (page, size, sort) => {
	const params = { page, size, sort };
	const response = await HttpRequest.get('/api/app-notices', params);

	return response;
};

export const getNoticeDetail = async (id) => {
	const response = await HttpRequest.get(`/api/app-notices/${id}`);
	return response;
};

export default {
	getNoticeList,
	getNoticeDetail,
};
