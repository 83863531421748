import React from 'react';
import styled from 'styled-components';
import { Img } from 'components/common';
import { Button } from 'components/button';
import { ColDiv, RowDiv, Text, theme } from 'styles';

export function AuthStep1({ setStep }) {
  return (
    <>
      <ColDiv $align="center" $gap={16} $height="calc(100svh - 160px)">
        <Img src="nameAuth" alt="휴대전화 본인확인" $width={96} $height={96} />
        <Text $type="m24">휴대전화 본인확인</Text>
        <ColDiv $gap={8}>
          <RowDiv>
            <Circle />
            <Text>생년월일, 성명, 내/외국인, 휴대폰번호, 통신사를 입력하여 본인확인을 받습니다.</Text>
          </RowDiv>
          <RowDiv>
            <Circle />
            <Text>본명 명의의 휴대전화가 아닐 경우 본인확인이 이루어지지 않습니다.</Text>
          </RowDiv>
        </ColDiv>
      </ColDiv>
      <Button $size="l" $mTop={8} onClick={() => setStep('step2')}>
        휴대폰 실명 인증
      </Button>
    </>
  );
}

const Circle = styled.span`
  min-width: 4px;
  width: 4px;
  height: 4px;
  background-color: ${theme.colors.gray700};
  border-radius: 50%;
  display: inline-block;
  margin: 10px;
`;
