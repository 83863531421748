import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colCenter, rowCenter, theme } from 'styles';

export const Header = styled.header`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 64px;
  padding-bottom: 8px;
  border-top: 1px solid ${theme.colors.fill12};
  background-color: white;

  h2 {
    display: none;
  }
`;

export const MenuList = styled.ul`
  display: flex;
`;

export const MenuItem = styled.li`
  flex: 1;
`;

export const CustomNavLink = styled(NavLink)`
  ${colCenter}
  min-width: 64px;
  padding: 6px 4px;

  div {
    ${rowCenter}
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: background-color 150ms ease;
  }

  h3 {
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    transition: color 150ms ease;
  }

  svg {
    transition:
      stroke 150ms ease,
      fill 150ms ease;
  }

  &.active {
    div {
      background-color: ${theme.colors.fill03};
    }
    h3 {
      color: ${theme.colors.primary600};
    }
    svg {
      path {
        ${({ $second }) => ($second ? `fill: ${theme.colors.primary600};` : `stroke: ${theme.colors.primary600};`)}
      }
      circle {
        stroke: ${theme.colors.primary600};
      }
    }
  }
`;
