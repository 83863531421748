import React from 'react';
import { promoList } from 'data/promoData';
import { useNavigate } from 'react-router-dom';
import { PromoListView } from './PromoListView';

export function PromoList(props) {
  const navigate = useNavigate();

  return (
    <>
      <PromoListView navigate={navigate} list={promoList} />
    </>
  );
}
