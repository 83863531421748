import { rowCenter, theme } from 'styles';
import styled, { css } from 'styled-components';

const types = {
  default: {
    enabled: css`
      border-color: ${theme.colors.gray300};
      background-color: #ffffff;

      &:checked {
        border-color: ${theme.colors.primary300};

        &:before {
          background-color: ${theme.colors.primary300};
        }
      }
    `,
    disabled: css`
      background-color: ${theme.colors.lightest};
      border-color: ${theme.colors.lighter};

      &:checked {
        background-color: ${theme.colors.primary50};
        border-color: ${theme.colors.primary100};
      }

      &:checked + &:before {
        background-color: ${theme.colors.primary100};
      }
    `,
  },
};

export const Label = styled.label`
  display: flex;
  width: fit-content;
  ${({ $children }) => $children && `gap:8px`};
  align-items: center;

  & div {
    line-height: 0;
  }

  & > p {
    font-weight: 500;
    line-height: 1.5;
    color: ${({ $disabled, $checked }) =>
      ($disabled && $checked) || $disabled
        ? theme.colors.lighter
        : $checked
          ? theme.colors.strong
          : theme.colors.gray900};
  }
`;

export const Radio = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid;
  transition: all 150ms ease;
  position: relative;

  ${types.default.enabled}

  &:disabled {
    ${types.default.disabled}
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
`;

// button radio style
export const BtnRadioLabel = styled.label`
  ${rowCenter}
  border-radius: 6px;
  background-color: white;
  border: 1px solid ${theme.colors.gray200};
  padding: 0 8px;
  height: 40px;
  flex: 1;

  ${({ $checked }) =>
    $checked &&
    css`
      border-color: ${theme.colors.primary};
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 30%;
    `}
`;

export const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  visibility: hidden;
  position: absolute;
`;
