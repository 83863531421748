import React, { useState, useEffect } from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import { adSampleList } from 'data/pointData';

export function AdList(props) {
  const [list, setList] = useState();

  useEffect(() => {
    if (adSampleList) {
      setList(adSampleList);
    }
  }, []);

  return (
    <Container title="광고">
      <S.ColList $gap={16}>
        {list?.map((item, index) => (
          <S.ListItem key={`banner_${index}`} onClick={() => item.url === '' && alert('걷쥬 광고 상세 이동')}>
            <C.Img src={item.img} alt="걷쥬 광고" $width="100%" />
          </S.ListItem>
        ))}
      </S.ColList>
    </Container>
  );
}
