export const donationList = [
  {
    id: 3,
    state: 1,
    name: '2024년 3분기 걷쥬 나눔 포인트',
    startDate: '2024.07.01',
    endDate: '2024.07.31',
    img: 'donation1',
    con: `지금은 실천할 시간입니다.\n우리들의 행복한 세상을 함께 만들어가요!\n지금은 실천할 시간입니다.\n우리들의 행복한 세상을 함께 만들어가요!`,
    currentPoint: 48000,
    targetPoint: 240000,
    contestants: 1100,
  },
  {
    id: 2,
    state: 0,
    name: '2024년 2분기 걷쥬 나눔 포인트',
    startDate: '2024.07.01',
    endDate: '2024.07.31',
    img: 'donation2',
    con: `지금은 실천할 시간입니다.\n우리들의 행복한 세상을 함께 만들어가요!\n지금은 실천할 시간입니다.\n우리들의 행복한 세상을 함께 만들어가요!`,
    currentPoint: 48000,
    targetPoint: 240000,
    contestants: 1100,
  },
  {
    id: 1,
    state: 0,
    name: '2024년 1분기 걷쥬 나눔 포인트',
    startDate: '2024.07.01',
    endDate: '2024.07.31',
    img: 'donation1',
    con: `지금은 실천할 시간입니다.\n우리들의 행복한 세상을 함께 만들어가요!\n지금은 실천할 시간입니다.\n우리들의 행복한 세상을 함께 만들어가요!`,
    currentPoint: 48000,
    targetPoint: 240000,
    contestants: 1100,
  },
];

export const couponList = [
  {
    id: 1,
    img: 'couponSample',
    name: 'GS25 모바일 상품권 3천원',
    createDate: '2022년 6월 12일 오후 03:00',
    startDate: '2022. 06. 12',
    endDate: '2024. 07. 12',
    barcode: 'barcode',
  },
  {
    id: 2,
    img: 'couponSample',
    name: 'GS25 모바일 상품권 3천원',
    createDate: '2022년 6월 12일 오후 03:00',
    startDate: '2022. 06. 12',
    endDate: '2024. 07. 12',
    barcode: 'barcode',
  },
  {
    id: 3,
    img: 'couponSample',
    name: 'GS25 모바일 상품권 3천원',
    createDate: '2022년 6월 12일 오후 03:00',
    startDate: '2022. 06. 12',
    endDate: '2024. 07. 12',
    barcode: 'barcode',
  },
];

export const adSampleList = [
  { img: 'pointBanner1', url: '' },
  { img: 'pointBanner2', url: '' },
  { img: 'pointBanner1', url: '' },
  { img: 'pointBanner2', url: '' },
  { img: 'pointBanner1', url: '' },
  { img: 'pointBanner2', url: '' },
];

export const dateList = [
  { id: 1, name: '6개월', val: 'sixMonth' },
  { id: 2, name: '1년', val: '1year' },
  { id: 3, name: '2년', val: '2year' },
  { id: 4, name: '직접입력', val: 'direct' },
];
export const historyPointList = [
  { id: 1, createDate: '2024.07.20', savePoint: 1800, name: '걷쥬 포인트' },
  { id: 2, createDate: '2024.07.20', usePoint: 3000, name: 'GS25 모바일 상품권 3천원' },
  { id: 3, createDate: '2024.07.20', savePoint: 500, name: '스마트 광고보기' },
  { id: 4, createDate: '2024.07.20', savePoint: 1800, name: '걷쥬 포인트' },
];

export const historyDonationList = [
  { id: 1, createDate: '2024.07.20', usePoint: 1000, name: '걷쥬 나눔 포인트' },
  { id: 2, createDate: '2024.07.20', usePoint: 3000, name: '이웃사랑 포인트' },
  { id: 3, createDate: '2024.07.20', usePoint: 1000, name: '걷쥬 나눔 포인트' },
  { id: 4, createDate: '2024.07.20', usePoint: 3000, name: '이웃사랑 포인트' },
];
