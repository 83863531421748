import { useEffect } from 'react';

const useScript = (url, async, loadCallback) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = async === true;
    script.addEventListener('load', () => {
      if (loadCallback) {
        loadCallback()
      }
    })

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

export default useScript;