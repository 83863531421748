import { isAndroid, isIOS } from 'mobile-bridge/functions';

export const serviceFunctions = {
  openPowerManager: () => {
    if (isAndroid()) {
      window.androidService.openPowerManager();
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "OPEN_POWER_MANAGER"
      });
    }
  },
  
  requestPermission: (permission, requestCode) => {
    if (isAndroid()) {
      window.androidService.requestPermission(permission, requestCode);
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "REQUEST_PERMISSION"
      });
    }
  }
};

export default serviceFunctions;
