import * as C from 'components';
import { Container } from 'layouts';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFaqList } from 'services/qna/QnAService';
import styled from 'styled-components';
import * as S from 'styles';

const FaqCategories = [
	{
		id: 1,
		name: '서비스문의',
		sub: [],
	},
	{
		id: 2,
		name: '기능문의',
		sub: [],
	},
	{
		id: 3,
		name: '장애신고',
		sub: [],
	},
	{
		id: 4,
		name: '제안사항',
		sub: [],
	},
	{
		id: 5,
		name: '기타',
		sub: [],
	},
	{
		id: 6,
		name: '웰뱅연동',
		sub: [],
	},
	{
		id: 7,
		name: '부정걸음수',
		sub: [],
	},
	{
		id: 8,
		name: '스팸',
		sub: [],
	},
];

const DEFAULT_PAGE_SIZE = 10;

const categoriesReducer = (state, action) => {
	switch (action.type) {
		case 'update':
			return state.map((item) => {
				if (item.id === action.item.id) {
					return { ...item, sub: action.item.sub };
				} else {
					return item;
				}
			});
		default:
			return state;
	}
};

export function FaqList(props) {
	const navigate = useNavigate();
	const refs = useRef([]);

	const [categories, dispatchCategories] = useReducer(categoriesReducer, FaqCategories);
	const [open, setOpen] = useState(null);
	const [selectedItem, setSelectedItem] = useState();
	const [heights, setHeights] = useState({});

	const fetchFaqList = async (type, page) => {
		const response = await getFaqList(type, page, DEFAULT_PAGE_SIZE);
		return response;
	};

	const selectCategory = async (item, index) => {
		const isClosed = open === index;
		setOpen(isClosed ? null : index);

		if (!isClosed && item.sub && item.sub.length <= 0) {
			let hasMore = true;
			let page = 0;
			let items = [];
			while (hasMore) {
				const res = await fetchFaqList(item.id, page);

				if (!res) {
					return;
				}

				if (res.last) {
					hasMore = false;
				}

				items = [...items, ...res.content];

				page += 1;
			}

			item.sub = items;
			dispatchCategories({ type: 'update', item });
		}
	};

	const moveToDetail = (sub, idx) => {
		setSelectedItem(idx);
		setTimeout(() => {
			navigate(`${sub.id}`, { state: sub });
		}, 300);
	};

	useEffect(() => {
		const currentHeights = refs.current.map((ref) => ref?.scrollHeight || 0);
		setHeights(currentHeights);
	}, [categories]);

	return (
		<Container title="FAQ" $padding="82px 16px 96px 16px" $height="100%">
			<ul>
				{categories.map((item, index) => (
					<S.ListItem key={`item_${item.id}`} $borderBottom={categories.length - 1 !== index}>
						<FaqMenuItem onClick={() => selectCategory(item, index)}>
							<S.Text>{item.name}</S.Text>
							<C.Icon name="FormArrow" />
						</FaqMenuItem>
						<FaqSubList
							className="subList"
							ref={(el) => (refs.current[index] = el)}
							$open={open === index}
							$height={open === index ? heights[index] : 0}
						>
							{item.sub &&
								item.sub.map((sub, idx) => (
									<S.ListItem
										key={`sub_${idx}`}
										$padding="8px"
										$bgColor={(selectedItem === idx && 'fill02') || 'transparent'}
										onClick={() => moveToDetail(sub, idx)}
									>
										<S.Text $color={selectedItem !== idx && 'gray700'}>{sub.title}</S.Text>
									</S.ListItem>
								))}
						</FaqSubList>
					</S.ListItem>
				))}
			</ul>
			<S.Div $position="fixed" $width="100vw" $height={80} $bottom="0" $left="0" $bgColor="#A1BCFF">
				<C.Img src="banner" $height={80} />
			</S.Div>
		</Container>
	);
}

const FaqMenuItem = styled(S.RowDiv)`
	width: 100%;
	padding: 0 8px;
	align-items: center;
	justify-content: space-between;
	height: 72px;
	gap: 8px;

	svg {
		width: 24px;
		height: 24px;
	}
`;

const FaqSubList = styled(S.List)`
	height: ${({ $height }) => $height && `${$height + 16}px`};
	overflow: hidden;
	background-color: ${S.theme.colors.gray50};
	transition: height 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
	border-radius: 10px;
	padding: ${({ $open }) => ($open ? '8px 4px' : '0 4px')};
`;
