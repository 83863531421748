import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import * as D from 'data';
import { Container } from 'layouts';

export function StampDetailView({ item, navigate }) {
  return (
    <Container title={`${item} 스탬프 투어`}>
      <S.List>
        {D.stampList.map((item, index) => (
          <S.ListItem
            key={`item_${index}`}
            $mTop={index !== 0 && 16}
            $radius={10}
            $overflow="hidden"
            $shadow="lineShadow"
            onClick={() => navigate(`${index + 1}`, { state: item })}
          >
            <S.Div $position="relative">
              <S.RowDiv $position="absolute" $top={8} $left={8} $gap={8} $zIndex={10}>
                <C.Badge
                  $bgColor={
                    D.areaList.find((area) => area.name === item.host)?.color ||
                    D.areaList.find((area) => area.name === '기타').color
                  }
                >
                  {item.host}
                </C.Badge>
                <C.Badge $bgColor={item.state === 0 ? 'gray400' : 'primary'} icon="BadgeCheck">
                  {item.state === 0 ? '참여가능' : '참여중'}
                </C.Badge>
              </S.RowDiv>
              <C.Img src={item.img} $height={130} $objectFit="cover" />
            </S.Div>
            <S.Div $padding="16px">
              <S.RowDiv $padding="2px 0" $align="center" $justify="space-between">
                <S.Text $color="gray500" $type="label12">
                  {item.startDate} ~ {item.endDate}
                </S.Text>
                <S.RowDiv $align="center">
                  <C.Icon name="ChallengeWalk" />
                  <S.Text $color="mint" $type="label12">
                    {item.join.toLocaleString()}명 참여중
                  </S.Text>
                </S.RowDiv>
              </S.RowDiv>
              <S.Text $type="b" $color="gray800">
                {item.title}
              </S.Text>
            </S.Div>
          </S.ListItem>
        ))}
      </S.List>
    </Container>
  );
}
