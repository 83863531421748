import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import { formatPhone } from 'utils';
import styled from 'styled-components';

export function MyInfoView({ values, onChange, invalids, navigate, setModalOpen }) {
  return (
    <>
      <C.AppBar>회원가입</C.AppBar>
      <S.ColDiv $gap={16} $padding="20px 20px 94px">
        <C.Input label="아이디" name="userId" value={values.userId} disabled />
        <C.Input label="이름" name="userName" value={values.userName} disabled />
        <C.Input label="생년월일" name="birthday" value={values.birthday} disabled />
        <C.Input label="연락처" name="phone" maxLength={13} $hide value={formatPhone(values.phone)} disabled />
        <S.Div>
          <C.InputLabel label="성별" $disabled />
          <S.RowDiv $gap={8}>
            <C.ButtonCheckbox name="gender" value="man" checked={values.gender === 'man'} disabled>
              남
            </C.ButtonCheckbox>
            <C.ButtonCheckbox name="gender" value="woman" checked={values.gender === 'woman'} disabled>
              여
            </C.ButtonCheckbox>
          </S.RowDiv>
        </S.Div>
        <S.Div>
          <C.InputLabel label="주소" required />
          <S.RowDiv $gap={4}>
            <C.Input name="postCode" required value={values.postCode} placeholder="우편번호 입력" disabled $flex={1} />
            <C.Button $size="m" $width={60} onClick={() => alert('주소검색 모달 연결')}>
              조회
            </C.Button>
          </S.RowDiv>
          <C.Input name="address" required value={values.address} $mTop={4} disabled />
          <C.Input
            name="detailAddress"
            placeholder="상세 주소 입력 (선택)"
            value={values.detailAddress}
            onChange={onChange}
            $mTop={4}
          />
        </S.Div>
        <C.Input
          label="E-mail"
          name="email"
          value={values.email}
          onChange={onChange}
          placeholder="이메일 주소 입력 (선택)"
        />
        <S.Div>
          <C.InputLabel label="수신동의" required />
          <S.RowDiv $gap={8}>
            <C.ButtonRadio name="acceptAgree" value="true" checked={values.acceptAgree === 'true'} onChange={onChange}>
              동의
            </C.ButtonRadio>
            <C.ButtonRadio
              name="acceptAgree"
              value="false"
              checked={values.acceptAgree === 'false'}
              onChange={onChange}
            >
              비동의
            </C.ButtonRadio>
          </S.RowDiv>
        </S.Div>
        <S.Div>
          <C.InputLabel label="수신채널" desc="복수 선택 가능" required />
          <S.RowDiv $gap={8}>
            <C.ButtonCheckbox
              name="acceptChannel"
              value="sms"
              onChange={onChange}
              checked={values.acceptChannel.includes('sms')}
            >
              SMS
            </C.ButtonCheckbox>
            <C.ButtonCheckbox
              name="acceptChannel"
              value="email"
              onChange={onChange}
              checked={values.acceptChannel.includes('email')}
            >
              E-mail
            </C.ButtonCheckbox>
          </S.RowDiv>
        </S.Div>
      </S.ColDiv>
      <ButtonDiv>
        <C.Button $size="l" $type="sub" onClick={() => navigate('/')}>
          취소
        </C.Button>
        <C.Button $size="l" onClick={() => setModalOpen(true)}>
          저장
        </C.Button>
      </ButtonDiv>
    </>
  );
}
const ButtonDiv = styled(S.RowDiv)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 20px 20px;
  gap: 8px;
  background-color: white;
  height: 78px;
`;
