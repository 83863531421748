import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';

export function Mall(props) {
  return (
    <Container title="포인트 몰">
      <S.ColDiv $height="100%" $align="center" $pTop={103}>
        <C.Img
          src="pointMall"
          alt="걷쥬 포인트 몰"
          $width={208}
          $height={208}
          onClick={() => alert('걷쥬 포인트 몰 이동')}
        />
        <S.Text $type="m24" $color="gray800" $mTop="-7px">
          포인트몰로
          <br />
          이동합니다
        </S.Text>
      </S.ColDiv>
    </Container>
  );
}
