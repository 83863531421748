import React, { useEffect, useState } from 'react';
import * as D from 'data';
import * as C from 'components';
import { useTab } from 'hooks';
import { useLocation } from 'react-router-dom';

export function CommunityRanking(props) {
  const location = useLocation();
  const item = location.state;
  const [tabIndex, currentTab] = useTab(0);
  const [list, setList] = useState(D.rankingList);

  const myIndex = D.rankingList.findIndex((user) => user.userName === 'Emma Moore');

  useEffect(() => {
    if (myIndex !== -1 && myIndex !== 0) {
      const updatedList = [...list];
      const myItem = updatedList.splice(myIndex, 1)[0];
      updatedList.unshift(myItem);
      setList(updatedList);
    }
  }, [myIndex]);

  return <C.CommunityRankingDetail item={item} tabIndex={tabIndex} list={list} myIndex={0} currentTab={currentTab} />;
}
