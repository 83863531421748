import React from 'react';
import { ScrollDiv } from 'styles';
import { AppBar } from 'components';

export function Container({ title, $height, $padding, children }) {
  return (
    <>
      <AppBar>{title}</AppBar>
      <ScrollDiv $height={$height || 'calc(100% - 62px)'} $padding={$padding || '20px'}>
        {children}
      </ScrollDiv>
    </>
  );
}
