import React from 'react';
import { Button } from 'components/button';
import { useNavigate } from 'react-router-dom';
import { H3, Div, Text, RowDiv } from 'styles';

export function JoinStep4(props) {
  const navigate = useNavigate();
  return (
    <>
      <H3 $display="none">회원가입이 완료 되었습니다.</H3>
      <Div $height="calc(100svh - 294px)">
        <Text $color="gray700">걷쥬에 가입해 주셔서 감사합니다.</Text>
      </Div>
      <RowDiv $pTop={8} $gap={8}>
        <Button $size="l" type="sub" onClick={() => navigate('/')}>
          홈으로
        </Button>
        <Button $size="l" onClick={() => navigate('/login')}>
          로그인
        </Button>
      </RowDiv>
    </>
  );
}
