import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import { infoList } from 'data/infoData';
import { useNavigate } from 'react-router-dom';

export function SettingInfoList(props) {
  const navigate = useNavigate();

  const detail = (item) => {
    navigate(`${item.id}`, { state: item });
  };

  return (
    <Container title="앱 정보">
      <S.Div $padding="8px 0" onClick={() => navigate('/')}>
        <C.Img src="logo" $width={78} $height={26} />
      </S.Div>
      <S.List>
        {infoList.map((info, index) => (
          <S.ListItem
            key={`info_${info.name}${index}`}
            onClick={() => detail(info)}
            $padding="24px 8px"
            $borderBottom={infoList.length - 1 !== index}
          >
            <S.Text $type="m16" $color="gray900">
              {info.name}
            </S.Text>
          </S.ListItem>
        ))}
      </S.List>
      <S.Div $position="absolute" $bottom={20}>
        <S.Text $type="m12" $color="gray500">
          앱버전 : &#123;2.0.1.2&#125;
        </S.Text>
      </S.Div>
    </Container>
  );
}
