import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import styled from 'styled-components';

export function PromoListView({ navigate, list }) {
  return (
    <Container title="지역홍보" $padding="82px 16px 96px 16px" $height="100%">
      <PropmoList>
        {list.map((item) => (
          <PropmoListItem key={`item_${item.id}`} onClick={() => navigate(`${item.id}`, { state: item })}>
            <C.Img src={item.img} />
            <TextDiv>
              <S.Text $maxLines={2} $color="white" $type="m">
                {item.name}
              </S.Text>
            </TextDiv>
          </PropmoListItem>
        ))}
      </PropmoList>
      <S.Div $position="fixed" $width="100vw" $height={80} $bottom="0" $left="0" $bgColor="#A1BCFF">
        <C.Img src="banner" $height={80} />
      </S.Div>
    </Container>
  );
}

const PropmoList = styled(S.List)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;

const PropmoListItem = styled(S.ListItem)`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  line-height: 0;
  min-height: 200px;
`;

const TextDiv = styled(S.RowDiv)`
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 4px 8px;
  width: 100%;
  height: 50px;
  align-items: center;
  background: linear-gradient(180deg, rgba(23, 23, 23, 0) 0%, rgba(23, 23, 23, 0.5) 50%, #171717 100%);
`;
