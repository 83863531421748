import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';

export function PromoDetailView({ item, navigate }) {
  return (
    <Container title={item.name}>
      <S.Div $pBottom={15} $borderBottom>
        <S.Text $type="b18" $color="gray900">
          {item.name}
        </S.Text>
        {item.createDate && (
          <S.Text $pTop={4} $type="14" $color="gray500">
            {item.createDate}
          </S.Text>
        )}
      </S.Div>
      {item.img && (
        <S.Div $pTop={16}>
          <C.Img src={item.img} $height="fit-content" />
        </S.Div>
      )}
      <S.Text $color="gray800" $padding="16px 0 20px" $whiteSpace="pre-wrap">
        {item.con}
      </S.Text>
      <C.Button $size="l" $type="sub" $leftIcon="List" $gap={4} onClick={() => navigate(-1)}>
        목록
      </C.Button>
    </Container>
  );
}
