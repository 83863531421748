import React, { useState } from 'react';
import * as S from 'styles';
import * as C from 'components';
import { useLocation, useNavigate } from 'react-router-dom';

export function CommunityCallengeDetail(props) {
  const location = useLocation();
  const item = location.state;
  const navigate = useNavigate();
  const [sheetOpen, setSheetOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [values, setValues] = useState({
    postCode: '',
    address: '',
    detailAddress: '',
  });

  const [invalids, setInvalids] = useState({
    postCode: { type: '', text: '' },
    address: { type: '', text: '' },
    detailAddress: { type: '', text: '' },
  });

  const onChange = (name, val) => {
    setValues({ ...values, [name]: val });

    if (invalids[name]?.type === 'error' && val === '') {
      setInvalids((prevInvalids) => ({
        ...prevInvalids,
        [name]: { ...prevInvalids[name], type: '' },
      }));
    }
  };

  const submit = () => {
    setSheetOpen(false);
    setTimeout(() => setModalOpen(true), 300);
  };

  return (
    <>
      <C.ChllengeDetailBox
        item={item}
        values={values}
        invalids={invalids}
        onChange={onChange}
        submit={submit}
        sheetOpen={sheetOpen}
        setSheetOpen={setSheetOpen}
      />
      <C.Modal open={modalOpen} $btn1={{ text: '닫기', onClick: () => navigate(-1) }}>
        <S.ColCenterDiv>
          <C.Img src="success" $width={60} $height={60} />
          <S.Text $textAlign="center" $pTop={16}>
            챌린지 참여가
            <br />
            완료되었습니다.
          </S.Text>
        </S.ColCenterDiv>
      </C.Modal>
    </>
  );
}
