import React, { useState } from 'react';
import * as S from 'styles';
import * as C from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from 'layouts';

export function StampDetailList(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Container title={item?.title} $height="calc(100svh - 140px)">
        <S.RowDiv $gap={16}>
          <S.RowDiv $align="center" $gap={4} $flex={1}>
            <C.Icon name="List" $width={22} $height={22} $pathStroke="gray400" />
            <S.Text $color="gray700" $type="b">
              투어 리스트
            </S.Text>
          </S.RowDiv>
          <S.Div
            onClick={() => navigate('info', { state: item })}
            $flex={1}
            $padding="6px 16px"
            $border={{ color: 'fill05' }}
            $radius={6}
          >
            <S.Text $textAlign="center" $color="gray700" $type="label14">
              스탬프 투어 규칙 보기
            </S.Text>
          </S.Div>
        </S.RowDiv>
        <S.List $mTop={16}>
          {item.list.map((item, index) => (
            <S.RowListItem key={`item_${index}`} $align="center" $gap={16} $padding="8px">
              <C.Img src={item.img} $width={96} $objectFit="cover" $radius={10} $height={74} $overflow="hidden" />
              <S.Div>
                <S.Text $whiteSpace="pre" $type="m16" $color="gray900">
                  {item.name}
                </S.Text>
                <S.Text $pTop={4} $color="gray500" $type="label12">
                  {item.date}
                </S.Text>
              </S.Div>
            </S.RowListItem>
          ))}
        </S.List>
      </Container>
      <S.Div $padding="8px 20px 20px">
        <C.Button $size="l" onClick={() => setModalOpen(true)}>
          참여하기
        </C.Button>
      </S.Div>
      <C.Modal open={modalOpen} $btn1={{ text: '닫기', onClick: () => setModalOpen(false) }}>
        <S.ColCenterDiv>
          <C.Img src="success" $width={60} $height={60} />
          <S.Text $textAlign="center" $pTop={16}>
            스템프 투어 참여
            <br />
            완료 되었습니다
          </S.Text>
        </S.ColCenterDiv>
      </C.Modal>
    </>
  );
}
