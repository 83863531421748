import React from 'react';
import { Icon } from 'components';
import { RowDiv, Text } from 'styles';
import { useNavigate } from 'react-router-dom';

export function AppBar({ children, onClick }) {
  const navigate = useNavigate();

  return (
    <RowDiv
      $gap={16}
      $padding="0 8px"
      $align="center"
      $height={62}
      $borderBottom={{ color: 'gray100' }}
      onClick={onClick || (() => navigate(-1))}
    >
      <Icon name="Arrow" />
      <Text $type="m18" $maxLines={1} $pRight={20}>
        {children}
      </Text>
    </RowDiv>
  );
}
