import React, { useState } from 'react';
import * as S from 'styles';
import * as D from 'data';
import moment from 'moment';
import * as C from 'components';
import { usePhoneMask as setPhoneMask } from 'hooks';
import { Container } from 'layouts';

export function CommunityFriend(props) {
  const initFilters = {
    friend: '',
    period: 'sixMonth',
    startDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
    endDate: moment().format(''),
  };
  const [filters, setFilters] = useState(initFilters);
  const onChange = (name, val) => {
    if (name === 'period') {
      let startDate = '';
      const endDate = moment().format('YYYY-MM-DD');

      if (val === 'sixMonth') {
        startDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
      } else if (val === '1year') {
        startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
      } else if (val === '2year') {
        startDate = moment().subtract(2, 'years').format('YYYY-MM-DD');
      } else if (val === 'direct') {
        startDate = '';
      }

      setFilters((filters) => ({
        ...filters,
        period: val,
        startDate,
        endDate,
      }));
    } else if (name === 'startDate' || name === 'endDate') {
      setFilters((filters) => ({
        ...filters,
        [name]: moment(val).format('YYYY-MM-DD'),
      }));
    } else {
      setFilters((filters) => ({
        ...filters,
        [name]: val,
      }));
    }
  };

  const sampleList = [
    {
      name: '김원영',
      userId: 'wonyoung',
      phone: '01012341234',
      area: '공주시',
      createDate: '2022.04.12',
    },
    {
      name: '김원영',
      userId: 'wonyoung',
      phone: '0101231234',
      area: '공주시',
      createDate: '2022.04.12',
    },
    {
      name: '김원영',
      userId: 'wonyoung',
      phone: '01012341234',
      area: '공주시',
      createDate: '2022.04.12',
    },
    {
      name: '김원영',
      userId: 'wonyoung',
      phone: '01012341234',
      area: '공주시',
      createDate: '2022.04.12',
    },
  ];

  return (
    <Container title="친구추천이력">
      <S.RowDiv $gap={4}>
        <C.Input
          name="friend"
          value={filters.friend}
          onChange={onChange}
          placeholder="친구명 입력"
          icon="Search"
          $width="100%"
        />
        <C.Button $type="sub" $size="m" $minWidth={60} $width={60} onClick={() => alert('검색')}>
          검색
        </C.Button>
      </S.RowDiv>
      <S.RowDiv $gap={4} $pTop={8}>
        {D.dateList.map((btn) => (
          <C.Button
            key={`btn_${btn.id}`}
            $size="m"
            type="line"
            $color="gray700"
            $focusColor="gray800"
            $active={btn.val === filters.period}
            onClick={() => onChange('period', btn.val)}
          >
            {btn.name}
          </C.Button>
        ))}
      </S.RowDiv>
      <C.Date filters={filters} onChange={onChange} $mTop={8} disabled={filters?.period !== 'direct'} />
      <S.Div $mTop={16}>
        <S.RowDiv $align="center" $justify="space-between">
          <S.RowDiv $gap={4}>
            <C.Icon name="Users" $pathFill="primary" $width={16} $height={16} />
            <S.Text $type="m14" $color="gray700">
              나의 추천 친구
            </S.Text>
          </S.RowDiv>
          <S.Text $type="b24" $color="gray900">
            {sampleList.length}
            <S.Span $type="20" $color="gray900">
              &nbsp;명
            </S.Span>
          </S.Text>
        </S.RowDiv>
      </S.Div>
      <S.List $mTop={16}>
        {sampleList.map((item, index) => (
          <S.ListItem key={`item_${index}`} $padding="8px">
            <S.Text $color="gray500" $type="label12">
              {item.name}
            </S.Text>
            <S.Text $type="b20" $color="gray900">
              {item.userId}
            </S.Text>
            <S.RowDiv $align="center" $gap={4}>
              <S.Text $type="lebel12" $color="gray500">
                {setPhoneMask(item.phone)}
              </S.Text>
              <C.Line $height={8} />
              <S.Text $type="lebel12" $color="gray500">
                {item.area}
              </S.Text>
              <C.Line $height={8} />
              <S.Text $type="lebel12" $color="gray500">
                {item.createDate}
              </S.Text>
            </S.RowDiv>
          </S.ListItem>
        ))}
      </S.List>
    </Container>
  );
}
