import React from 'react';
import { Text } from 'styles';
import * as S from './RadioStyle';

export function Radio({ name, value, onChange, children, checked, required, disabled }) {
  const handleOnChange = (event) => {
    const { name, checked } = event.target;
    onChange(name, checked);
  };

  return (
    <S.Label htmlFor={name} required={required} $disabled={disabled} $checked={checked} $children={children}>
      <div>
        <S.Radio id={name} name={name} checked={checked || false} onChange={handleOnChange} disabled={disabled} />
      </div>
      {children && <p>{children}</p>}
    </S.Label>
  );
}

export function ButtonRadio({ name, value, onChange, checked, children }) {
  const handleOnChange = (event) => {
    event.preventDefault();
    onChange(name, value);
  };

  return (
    <S.BtnRadioLabel htmlFor={`${name}-${value}`} $checked={checked}>
      <S.HiddenRadio id={`${name}-${value}`} name={name} value={value} checked={checked} onChange={handleOnChange} />
      {children && (
        <Text $type="m14" $color="gray700">
          {children}
        </Text>
      )}
    </S.BtnRadioLabel>
  );
}
