import React, { useState } from 'react';
import { myConnectList } from 'data/myData';
import { MyDeviceView } from './MyDeviceView';

export function MyDevice(props) {
  const [list, setList] = useState(myConnectList);

  const onClick = (id) => {
    const temp = list.map((item) => (item.id === id ? { ...item, connection: item.connection === 0 ? 1 : 0 } : item));
    setList(temp);
  };

  return <MyDeviceView list={list} onClick={onClick} />;
}
