/* eslint-disable no-undef */

import React, { useEffect, useState } from 'react';
import { isEmptyValue } from 'utils';
import { useCookie } from 'hooks';
import { useNavigate } from 'react-router-dom';
import AuthenticationService from 'services/authentication/AuthenticationService';
import userAuthenticationStore from 'stores/authentication/AuthenticationStore';


import { ACCESS_TOKEN, REFRESH_TOKEN } from 'data/constants';
import { LoginView } from './LoginView';

export function Login(props) {
  const setAuthenticated = userAuthenticationStore((state) => state.setAuthenticated);
  const setUserInfo = userAuthenticationStore((state) => state.setUserInfo);


  const navigate = useNavigate();
  const { setCookies } = useCookie();
  const [btnActive, setBtnActive] = useState(false);
  const [values, setValues] = useState({
    id: '',
    password: '',
  });

  const [invalids, setInvalids] = useState({
    id: { type: '', text: '' },
    password: { type: '', text: '' },
  });

  const onChange = (name, value) => {
    setValues({ ...values, [name]: value });

    setInvalids({
      id: { type: '', text: '' },
      password: { type: '', text: ''},
    });
  };

  const handleKakaoLogin = () => {
    const redirectUrl = `${process.env.REACT_APP_KAKAO_LOGIN_REDIRECT_URL}`;
    console.log(redirectUrl);
    Kakao.Auth.authorize({
      redirectUri: redirectUrl
    });
  };

  const handleNaverLogin = () => {
    window.location.href = document.getElementById('naver_id_login').querySelector('a').href;
  };

  const submit = () => {

    if (!isEmptyValue(values)) {
      AuthenticationService.login(values.id, values.password).then((response)=>{
        if(response && response.access_token){
          setCookies('access_token', response.access_token);
          localStorage.setItem(ACCESS_TOKEN, response.access_token);
          localStorage.setItem(REFRESH_TOKEN, response.refresh_token);

          setAuthenticated(true);

          AuthenticationService.getMyInfo().then((account)=>{
            setUserInfo(account);
          });

          navigate('/');
        }else{
          setInvalids({
            id: { type: 'error', text: '' },
            password: { type: 'error', text: ''},
          });
        }
      });


    }
  };

  useEffect(() => {
    if (!isEmptyValue(values)) {
      setBtnActive(true);
    } else {
      setBtnActive(false);
    }
  }, [values]);

  return (
    <LoginView
      values={values}
      onChange={onChange}
      submit={submit}
      handleKakaoLogin={handleKakaoLogin}
      handleNaverLogin={handleNaverLogin}
      invalids={invalids}
      btnActive={btnActive}
      navigate={navigate}
    />
  );
}
