import React from 'react';
import { Container } from 'layouts';
import * as C from 'components';
import * as S from 'styles';
import * as D from 'data';
import { useLocation, useNavigate } from 'react-router-dom';

export function MyCommunity(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;

  const goDetail = (item) => {
    if (item.state === 0) {
      navigate(`/community/profile`, { state: { type: 'edit' } });
    } else {
      navigate(`challenge/${item.id}`, { state: item });
    }
  };

  return (
    <>
      <Container title="홍성군 공식 커뮤니티">
        <C.Img src="CommunityImg" $height={160} />
        <S.Div $pBottom={16}>
          <C.Badge
            $shadow="lineShadow"
            $bgColor={
              D.areaList.find((area) => area.name === item.host)?.color ||
              D.areaList.find((area) => area.name === '기타').color
            }
          >
            {item.host}
          </C.Badge>
          <S.Text $padding="4px 0" $type="b20" $color="gray900">
            충남체육회 공식 커뮤니티
          </S.Text>
          <S.RowDiv $align="center" $gap={4}>
            <C.Icon name="Users" $pathFill="gray500" $width={16} $height={16} />
            <S.Text $color="gray500" $type="label12">
              {item.join.toLocaleString()} 참여중
            </S.Text>
          </S.RowDiv>
        </S.Div>
        <C.Button $type="sub" $size="m">
          참여중
        </C.Button>
        <S.GridList $pTop={16} $gap={8} $grid={3}>
          <S.ColListItem $padding="16px 8px" $align="center">
            <C.Icon name="AddUser" />
            <S.Text $pTop={4} $color="gray900" $type="m14" $textAlign="center">
              친구 초대
            </S.Text>
          </S.ColListItem>
          <S.ColListItem $padding="16px 8px" $align="center">
            <C.Icon name="Share" />
            <S.Text $pTop={4} $color="gray900" $type="m14" $textAlign="center">
              공유
            </S.Text>
          </S.ColListItem>
          <S.ColListItem $padding="16px 8px" $align="center">
            <C.Icon name="CommuSetting" onClick={() => navigate(`setting`)} />
            <S.Text $pTop={4} $color="gray900" $type="m14" $textAlign="center">
              설정
            </S.Text>
          </S.ColListItem>
        </S.GridList>
        <S.Div $padding="20px 0">
          <C.HomeTitle icon="ChallengeGray" onClick={() => navigate('challenge')}>
            챌린지
          </C.HomeTitle>
          <S.List $pTop={8}>
            {D.stampList.slice(0, 2).map((item, index) => (
              <S.ListItem
                key={`item_${index}`}
                $mTop={index !== 0 && 16}
                $radius={10}
                $overflow="hidden"
                $shadow="lineShadow"
                onClick={() => goDetail(item)}
              >
                <S.Div $position="relative">
                  <S.RowDiv $position="absolute" $top={8} $left={8} $gap={8} $zIndex={10}>
                    <C.Badge $bgColor={item.state === 0 ? 'gray400' : 'primary'} icon="BadgeCheck">
                      {item.state === 0 ? '참여가능' : '참여중'}
                    </C.Badge>
                  </S.RowDiv>
                  <C.Img src={item.img} $height={130} $objectFit="cover" />
                </S.Div>
                <S.Div $padding="16px">
                  <S.RowDiv $padding="2px 0" $align="center" $justify="space-between">
                    <S.Text $color="gray500" $type="label12">
                      {item.startDate} ~ {item.endDate}
                    </S.Text>
                    <S.RowDiv $align="center">
                      <C.Icon name="ChallengeWalk" />
                      <S.Text $color="mint" $type="label12">
                        {item.join?.toLocaleString()}명 참여중
                      </S.Text>
                    </S.RowDiv>
                  </S.RowDiv>
                  <S.Text $type="b" $color="gray800">
                    {item.title}
                  </S.Text>
                </S.Div>
              </S.ListItem>
            ))}
          </S.List>
          {/* !list */}
          {/* <S.Div $mTop={8} $padding="32px 16px" $radius={10} $border={{ style: 'dashed' }}>
          <S.Text $color="gray500" $type="m14" $textAlign="center">
            조회된 챌린지가 없습니다.
          </S.Text>
        </S.Div> */}
          {/* !list */}
        </S.Div>
        <S.Div $padding="20px 0">
          <C.HomeTitle icon="Megaphone" onClick={() => navigate('challenge/notice')}>
            공지사항
          </C.HomeTitle>
          <S.List $mTop={8}>
            {D.myNoticeList.slice(0, 1).map((item) => (
              <S.ListItem
                key={`notice_${item.id}`}
                $padding="3px 8px"
                onClick={() => navigate(`challenge/notice/${item.id}`, { state: { parentId: item.id, item } })}
              >
                <S.Text $color="gray900" $type="m">
                  {item.name}
                </S.Text>
                <S.Text $pTop={4} $color="gray500" $type="label12">
                  {item.createDate}
                </S.Text>
              </S.ListItem>
            ))}
          </S.List>
        </S.Div>
        <S.Div>
          <C.HomeTitle icon="NoticeGray">게시판</C.HomeTitle>
          <S.RowDiv $align="center" $justify="space-between" $mTop={8} $padding="16px" $bgColor="gray50" $radius={10}>
            <S.Text $color="gray600" $type="m14">
              커뮤니티 멤버들과 소통해보세요!
            </S.Text>
            <C.Button $size="s" $width={85} $leftIcon="Pencil" $gap={4}>
              글쓰기
            </C.Button>
          </S.RowDiv>
        </S.Div>
        <S.Div
          $padding="20px 0"
          onClick={() => navigate('challenge/ranking', { state: { title: '전체 멤버 중 나의 걷기 랭킹', type: 'my' } })}
        >
          <C.HomeTitle icon="NoticeGray">전체 멤버 중 나의 걷기 랭킹</C.HomeTitle>
          <C.RankingBox />
        </S.Div>
        <S.Div
          $padding="20px 0"
          onClick={() => navigate('challenge/ranking', { state: { title: '그룹 내 나의 걷기 랭킹', type: 'my' } })}
        >
          <C.HomeTitle icon="NoticeGray">그룹 내 나의 걷기 랭킹</C.HomeTitle>
          <C.RankingBox />
        </S.Div>
        <S.Div
          $padding="20px 0"
          onClick={() => navigate('challenge/ranking', { state: { title: '오늘의 그룹별 걷기 랭킹', type: 'group' } })}
        >
          <C.HomeTitle icon="NoticeGray">오늘의 그룹별 걷기 랭킹</C.HomeTitle>
          <C.RankingBox />
        </S.Div>
        <C.Button $size="l" $type="sub" onClick={() => navigate('myscore')}>
          나의 현재 걸음수 자랑하기
        </C.Button>
      </Container>
    </>
  );
}
