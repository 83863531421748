export const isEmptyValue = (obj) => {
  if (!obj || typeof obj !== 'object') {
    return true; // obj가 null, undefined이거나 객체가 아닌 경우
  }

  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      const value = obj[prop];
      if (Array.isArray(value)) {
        // 배열 내부의 각 객체가 비어있는지 확인
        for (const item of value) {
          if (isEmptyValue(item)) {
            return true; // 배열 내 객체에 비어있는 값이 있으면 true 반환
          }
        }
      } else if (value === '' || value === null || value === undefined) {
        return true; // 값이 비어있는 경우
      }
    }
  }

  return false; // 모든 객체에 값이 있으면 false 반환
};
