import React from 'react';
import * as S from 'styles';
import { HomeTitle } from './HomeTitle';

export function HomeCommunity({ navigate }) {
  return (
    <S.Section $padding="20px">
      <HomeTitle icon="Megaphone" onClick={() => alert('어디로 이동?')}>
        나의 친구
      </HomeTitle>
      <S.LineDiv $padding="16px" $mTop={16} $shadow></S.LineDiv>
    </S.Section>
  );
}
