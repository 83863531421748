export const stampTabList = ['스탬프 투어', '스탬프 이력'];

export const areaList = [
  { name: '전체', value: '1', color: '#007CC2' },
  { name: '충남체육회', value: '2', color: '#46494B' },
  { name: '천안시', value: '3', color: '#7334F8' },
  { name: '공주시', value: '4', color: '#EF7B22' },
  { name: '보령시', value: '5', color: '#02B28E' },
  { name: '아산시', value: '6', color: '#0D5EAA' },
  { name: '서산시', value: '7', color: '#E53574' },
  { name: '논산시', value: '8', color: '#F8BB34' },
  { name: '계룡시', value: '9', color: '#111C7C' },
  { name: '당진시', value: '10', color: '#91AA37' },
  { name: '금산군', value: '11', color: '#54A32F' },
  { name: '부여군', value: '12', color: '#A76518' },
  { name: '서천군', value: '13', color: '#00827E' },
  { name: '청양군', value: '14', color: '#C40000' },
  { name: '홍성군', value: '15', color: '#02B28E' },
  { name: '예산군', value: '16', color: '#EC2222' },
  { name: '태안군', value: '17', color: '#1BACD6' },
  { name: '기타', value: '18', color: '#798F9A' },
];

export const challengeList = [
  {
    id: 1,
    host: '충남체육회',
    img: 'challengeSample',
    title: '7.7 챌린지 (7일 7만보)',
    startDate: '2024-06-24',
    endDate: '2024-06-30',
    point: 2000,
    join: 38918,
    state: 0,
    mission: `-6.24 ~ 6.30 해당 기간 동안 7만보 이상 달성한 충남도민(부정 걸음 사용자는 포인트 미지급 및 회수, 계정정지 처리)\n위치 인증은 2주에 한 번씩 이루어집니다.\n1째주, 3째주, 5째주 - 인증하셔야 합니다. (인증은 자동으로 실시 됩니다.)`,
  },
  {
    id: 2,
    host: '천안시',
    img: 'challengeSample',
    title: '7.7 챌린지 (7일 7만보)',
    startDate: '2024-06-24',
    endDate: '2024-06-30',
    point: 750,
    join: 38918,
    state: 0,
    mission: `-6.24 ~ 6.30 해당 기간 동안 7만보 이상 달성한 충남도민(부정 걸음 사용자는 포인트 미지급 및 회수, 계정정지 처리)\n위치 인증은 2주에 한 번씩 이루어집니다.\n1째주, 3째주, 5째주 - 인증하셔야 합니다. (인증은 자동으로 실시 됩니다.)`,
  },
  {
    id: 3,
    host: '아산시',
    img: 'challengeSample',
    title: '7.7 챌린지 (7일 7만보)',
    startDate: '2024-06-24',
    endDate: '2024-06-30',
    point: 750,
    join: 38918,
    state: 0,
    mission: `-6.24 ~ 6.30 해당 기간 동안 7만보 이상 달성한 충남도민(부정 걸음 사용자는 포인트 미지급 및 회수, 계정정지 처리)\n위치 인증은 2주에 한 번씩 이루어집니다.\n1째주, 3째주, 5째주 - 인증하셔야 합니다. (인증은 자동으로 실시 됩니다.)`,
  },
  {
    id: 4,
    host: '금산군',
    img: 'challengeSample',
    title: '7.7 챌린지 (7일 7만보)',
    startDate: '2024-06-24',
    endDate: '2024-06-30',
    point: 750,
    join: 38918,
    state: 1,
    mission: `-6.24 ~ 6.30 해당 기간 동안 7만보 이상 달성한 충남도민(부정 걸음 사용자는 포인트 미지급 및 회수, 계정정지 처리)\n위치 인증은 2주에 한 번씩 이루어집니다.\n1째주, 3째주, 5째주 - 인증하셔야 합니다. (인증은 자동으로 실시 됩니다.)`,
  },
  {
    id: 5,
    host: '기타',
    img: 'challengeSample',
    title: '7.7 챌린지 (7일 7만보)',
    startDate: '2024-06-24',
    endDate: '2024-06-30',
    point: 750,
    join: 38918,
    state: 0,
    mission: `-6.24 ~ 6.30 해당 기간 동안 7만보 이상 달성한 충남도민(부정 걸음 사용자는 포인트 미지급 및 회수, 계정정지 처리)\n위치 인증은 2주에 한 번씩 이루어집니다.\n1째주, 3째주, 5째주 - 인증하셔야 합니다. (인증은 자동으로 실시 됩니다.)`,
  },
  {
    id: 6,
    host: '충남체육회',
    img: 'challengeSample',
    title: '7.7 챌린지 (7일 7만보)',
    startDate: '2024-06-24',
    endDate: '2024-06-30',
    point: 750,
    join: 38918,
    state: 1,
    mission: `-6.24 ~ 6.30 해당 기간 동안 7만보 이상 달성한 충남도민(부정 걸음 사용자는 포인트 미지급 및 회수, 계정정지 처리)\n위치 인증은 2주에 한 번씩 이루어집니다.\n1째주, 3째주, 5째주 - 인증하셔야 합니다. (인증은 자동으로 실시 됩니다.)`,
  },
  {
    id: 7,
    host: '충남체육회',
    img: 'challengeSample',
    title: '7.7 챌린지 (7일 7만보)',
    startDate: '2024-06-24',
    endDate: '2024-06-30',
    point: 750,
    join: 38918,
    state: 0,
    mission: `-6.24 ~ 6.30 해당 기간 동안 7만보 이상 달성한 충남도민(부정 걸음 사용자는 포인트 미지급 및 회수, 계정정지 처리)\n위치 인증은 2주에 한 번씩 이루어집니다.\n1째주, 3째주, 5째주 - 인증하셔야 합니다. (인증은 자동으로 실시 됩니다.)`,
  },
  {
    id: 8,
    host: '보령시',
    img: 'challengeSample',
    title: '7.7 챌린지 (7일 7만보)',
    startDate: '2024-06-24',
    endDate: '2024-06-30',
    point: 750,
    join: 38918,
    state: 1,
    mission: `-6.24 ~ 6.30 해당 기간 동안 7만보 이상 달성한 충남도민(부정 걸음 사용자는 포인트 미지급 및 회수, 계정정지 처리)\n위치 인증은 2주에 한 번씩 이루어집니다.\n1째주, 3째주, 5째주 - 인증하셔야 합니다. (인증은 자동으로 실시 됩니다.)`,
  },
];

export const rankingList = [
  { ranking: 1, userName: 'Jacob Jones', walk: 92771 },
  { ranking: 2, userName: 'Albert Flores', img: 'rankingSample', walk: 74875 },
  { ranking: 3, userName: 'Mia Wang', walk: 50963 },
  { ranking: 4, userName: 'Ethan Brown', walk: 20796 },
  { ranking: 5, userName: 'Sophia Wilson', walk: 18599 },
  { ranking: 6, userName: 'Michael Garcia', walk: 16708 },
  { ranking: 7, userName: 'Olivia Martinez', walk: 12708 },
  { ranking: 8, userName: 'James Rodriguez', walk: 11500 },
  { ranking: 9, userName: 'Isabella Davis', walk: 10708 },
  { ranking: 10, userName: 'William Hernandez', walk: 9700 },
  { ranking: 713780, userName: 'Emma Moore', walk: 9695 },
  { ranking: 713781, userName: 'Isabella Davis', walk: 6980 },
  { ranking: 713782, userName: 'William Hernandez', walk: 6500 },
  { ranking: 713783, userName: 'Albert Taylor', walk: 3398 },
  { ranking: 713784, userName: 'Mason Taylor', walk: 3392 },
  { ranking: 713785, userName: 'Ava Anderson', walk: 2570 },
  { ranking: 713786, userName: 'Noah Lee', walk: 2548 },
];

export const challengeHistory = [
  {
    id: 1,
    host: '충남체육회',
    state: 1,
    info: `[충남도민이면 누구나 참여 가능]`,
    challenge: '내포 문화숲길 걷기 챌린지',
    point: 1000,
    walk: 10000,
    total: 10000,
    startDate: '2024.06.24',
    endDate: '2024.06.30',
  },
  {
    id: 2,
    host: '천안시',
    state: 0,
    info: `[충남도민이면 누구나 참여 가능]`,
    challenge: '내포 문화숲길 걷기 챌린지',
    point: 1000,
    walk: 7600,
    total: 10000,
    startDate: '2024.06.24',
    endDate: '2024.06.30',
  },
  {
    id: 3,
    host: '예산군',
    state: 1,
    info: `[충남도민이면 누구나 참여 가능]`,
    challenge: '내포 문화숲길 걷기 챌린지',
    point: 1000,
    walk: 10000,
    total: 10000,
    startDate: '2024.06.24',
    endDate: '2024.06.30',
  },
  {
    id: 4,
    host: '충남체육회',
    state: 0,
    info: `[충남도민이면 누구나 참여 가능]`,
    challenge: '내포 문화숲길 걷기 챌린지',
    point: 1000,
    walk: 5000,
    total: 10000,
    startDate: '2024.06.24',
    endDate: '2024.06.30',
  },
];

export const stampList = [
  {
    id: 1,
    host: '논산시',
    state: 1,
    startDate: '20204.06.24',
    endDate: '2024.06.30',
    join: 38918,
    title: '논산 2024년 7월 스탬프 투어',
    img: 'challengeSample',
    period: '2024년 7월 1일 ~ 2024년 7월 31일까지',
    event: [
      `논산시 공식 커뮤니티 가입\n(논산시민만 참여 가능)`,
      `07. 01 ~ 07. 31 기간 내 20만보 달성`,
      `07. 01 ~ 07. 31 기간내 논산시 스탬프 중 8개 이상 획득`,
    ],
    info: `*세가지 미션을 모두 다 성공하신 분 150명을 추첨, 모바일 상품권 제공\n*부정걸음 행위자는 추첨 대상에서 제외\n*문의사항 : 논산시 보건소 건강정책팀\n041-746-8053, 8131로 문의주세요.`,
    point: 0,
    prize: 0,
    list: [
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
    ],
  },
  {
    id: 2,
    host: '논산시',
    state: 0,
    startDate: '20204.06.24',
    endDate: '2024.06.30',
    join: 38918,
    title: '논산 여름휴가 명소 투어',
    img: 'challengeSample',
    list: [
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
    ],
  },
  {
    id: 3,
    host: '논산시',
    state: 0,
    startDate: '20204.06.24',
    endDate: '2024.06.30',
    join: 38918,
    title: '논산 맛집 투어',
    img: 'challengeSample',
    period: '2024년 7월 1일 ~ 2024년 7월 31일까지',
    event: [
      `논산시 공식 커뮤니티 가입\n(논산시민만 참여 가능)`,
      `07. 01 ~ 07. 31 기간 내 20만보 달성`,
      `07. 01 ~ 07. 31 기간내 논산시 스탬프 중 8개 이상 획득`,
    ],
    info: `*세가지 미션을 모두 다 성공하신 분 150명을 추첨, 모바일 상품권 제공\n*부정걸음 행위자는 추첨 대상에서 제외\n*문의사항 : 논산시 보건소 건강정책팀\n041-746-8053, 8131로 문의주세요.`,
    point: 0,
    prize: 0,
    list: [
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
    ],
  },
  {
    id: 4,
    host: '논산시',
    state: 0,
    startDate: '20204.06.24',
    endDate: '2024.06.30',
    join: 38918,
    title: '논산 특산품 투어',
    img: 'challengeSample',
    period: '2024년 7월 1일 ~ 2024년 7월 31일까지',
    event: [
      `논산시 공식 커뮤니티 가입\n(논산시민만 참여 가능)`,
      `07. 01 ~ 07. 31 기간 내 20만보 달성`,
      `07. 01 ~ 07. 31 기간내 논산시 스탬프 중 8개 이상 획득`,
    ],
    info: `*세가지 미션을 모두 다 성공하신 분 150명을 추첨, 모바일 상품권 제공\n*부정걸음 행위자는 추첨 대상에서 제외\n*문의사항 : 논산시 보건소 건강정책팀\n041-746-8053, 8131로 문의주세요.`,
    point: 0,
    prize: 0,
    list: [
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
        period: '2024년 7월 1일 ~ 2024년 7월 31일까지',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
      {
        name: `주곡마을둘레길\n(선비계단)`,
        img: 'stampImg',
        date: '2024.07.20',
      },
    ],
  },
];

export const stampHistoryList = [
  {
    id: 1,
    host: '부여군',
    myStamp: 2,
    stamp: 5,
    createDate: '20204.07.20',
    title: '부여 2024년 7월 스탬프',
    img: 'stampHistory',
    list: [
      { img: 'stampImg', name: '부여 부소산 영일루', createDate: '2024.07.20', state: 1 },
      { img: 'stampImg', name: '부여 낙화암 가는길 연리지 나무 옆', createDate: '2024.07.20', state: 0 },
      { img: 'stampImg', name: '부여궁남지 중혼합 가는길 (입구)', createDate: '2024.07.20', state: 1 },
      { img: 'stampImg', name: `주곡마을둘레길\n(선비계단)`, createDate: '2024.07.20', state: 0 },
      { img: 'stampImg', name: '부여 부소산 영일루', createDate: '2024.07.20', state: 0 },
    ],
  },
  {
    id: 2,
    host: '부여군',
    myStamp: 10,
    stamp: 10,
    createDate: '20204.06.20',
    title: '부여 2024년 6월 스탬프',
    img: 'stampHistory',
    list: [
      { img: 'stampImg', name: '부여 부소산 영일루', createDate: '2024.07.20', state: 1 },
      { img: 'stampImg', name: '부여 낙화암 가는길 연리지 나무 옆', createDate: '2024.07.20', state: 0 },
      { img: 'stampImg', name: '부여궁남지 중혼합 가는길 (입구)', createDate: '2024.07.20', state: 1 },
      { img: 'stampImg', name: `주곡마을둘레길\n(선비계단)`, createDate: '2024.07.20', state: 0 },
      { img: 'stampImg', name: '부여 부소산 영일루', createDate: '2024.07.20', state: 0 },
    ],
  },
  {
    id: 3,
    host: '부여군',
    myStamp: 1,
    stamp: 10,
    createDate: '20204.05.20',
    title: '부여 2024년 5월 스탬프',
    img: 'stampHistory',
    list: [
      { img: 'stampImg', name: '부여 부소산 영일루', createDate: '2024.07.20', state: 1 },
      { img: 'stampImg', name: '부여 낙화암 가는길 연리지 나무 옆', createDate: '2024.07.20', state: 0 },
      { img: 'stampImg', name: '부여궁남지 중혼합 가는길 (입구)', createDate: '2024.07.20', state: 1 },
      { img: 'stampImg', name: `주곡마을둘레길\n(선비계단)`, createDate: '2024.07.20', state: 0 },
      { img: 'stampImg', name: '부여 부소산 영일루', createDate: '2024.07.20', state: 0 },
    ],
  },
];
