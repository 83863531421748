import React from 'react';
import * as C from 'components';
import * as S from 'styles';
import { Container } from 'layouts';
import nativeAppSettingsFunctions from 'mobile-bridge/appSettingsFunctions';

import useSettingsStore from 'stores/settings/SettingsStore';

export function SettingListView({ subMenu, navigate }) {
  const pushNotificationEnabled = useSettingsStore((state) => state.pushNotificationEnabled);
  const activity = useSettingsStore((state) => state.activity);
  const beaconYN = useSettingsStore((state) => state.beaconYN);
  const pointOption = useSettingsStore((state) => state.pointOption);

  return (
    <Container title="설정">
      <ul>
        {subMenu?.map((menu, index) => (
          <S.List
            key={`menu__${menu.label}_${index}`}
            $padding={index !== 0 && '16px 0'}
            $pBottom={index === 0 && 16}
            $borderBottom={index !== subMenu.length - 1}
          >
            <S.Text $type="m14" $color="gray600" $pBottom={8}>
              {menu.name}
            </S.Text>
            <S.List>
              {menu.list.map((item, idx) => (
                <S.ListItem key={`item_${idx}`} $padding="8px" onClick={item.path && (() => navigate(item.path))}>
                  <S.RowDiv $minHeight={56} $align="center" $justify="space-between">
                    <div>
                      <S.Text $type="m16" $color="gray900">
                        {item.label}
                      </S.Text>
                      <S.Text $type="m12" $color="gray500" $whiteSpace="pre">
                        {item.desc}
                      </S.Text>
                    </div>
                    {item.name === 'push' && <C.Switch checked={pushNotificationEnabled} onChange={(value) => {
                      nativeAppSettingsFunctions.requestPushNotification(value);
                    }}/>}
                    {item.name === 'activity' && <C.Switch checked={activity} onChange={(value) => {
                      nativeAppSettingsFunctions.setSharedPreferenceBoolean('activity', value);
                    }}/>}
                    {item.name === 'beaconYN' && <C.Switch checked={beaconYN} onChange={(value) => {
                      nativeAppSettingsFunctions.setSharedPreferenceBoolean('beaconYN', value);
                    }}/>}
                    {item.name === 'point_option' && <C.Switch checked={pointOption} onChange={(value) => {
                      nativeAppSettingsFunctions.setSharedPreferenceBoolean('point_option', value);
                    }}/>}
                    {(item.name !== 'push' && item.name !== 'activity' && item.name !== 'beaconYN' && item.name !== 'point_option') && <C.Switch />}
                  </S.RowDiv>
                </S.ListItem>
              ))}
            </S.List>
          </S.List>
        ))}
      </ul>
    </Container>
  );
}
