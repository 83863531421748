import React from 'react';
import * as S from 'styles';
import * as C from 'components';

export function HomeTitle({ icon, children, onClick, ...props }) {
  return (
    <S.RowDiv $justify="space-between" $align="center">
      <S.RowDiv $gap={4} $align="center">
        <C.Icon name={icon} $width={24} $height={24} {...props} />
        <S.H3 $type="b" $color="gray700">
          {children}
        </S.H3>
      </S.RowDiv>
      <S.RowDiv $align="center" onClick={onClick}>
        <S.Text $type="b14" $color="primary200">
          더보기
        </S.Text>
        <C.Icon name="Plus" $pathFill="primary200" />
      </S.RowDiv>
    </S.RowDiv>
  );
}
