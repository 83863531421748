import styled from 'styled-components';
import { theme } from 'styles';

export const SwitchLabel = styled.label`
  position: relative;
  display: block;
  width: 40px;
  height: 24px;
  border-radius: 16px;
`;

export const SwitchInput = styled.input.attrs({ type: 'checkbox' })`
  display: none;

  &:checked + .slider {
    background-color: ${theme.colors.primary};
  }

  &:checked + .slider:before {
    transform: translateX(16px);
  }

  &:not(:checked) + .slider:before {
    background-color: white;
  }

  &:disabled + .slider {
    background-color: ${theme.colors.disabled};
  }

  &:disabled + .slider:before {
    background-color: ${theme.colors.disabled};
  }
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  width: 40px;
  height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.gray300};
  transition: 0.4s;
  border-radius: 16px;

  &::before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 0px;
    top: 0;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    ${({ $disabled }) =>
      !$disabled &&
      `box-shadow:
      0px 1px 2px 0px rgba(0, 0, 0, 0.25),
      0px 3px 8px 2px rgba(0, 0, 0, 0.08)`};
  }
`;
