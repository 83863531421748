import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import { useRandomColor } from 'hooks';
import { useLocation } from 'react-router-dom';

export function CommunityRankingDetail({ tabIndex, list, myIndex, currentTab }) {
  const randomColor = useRandomColor();
  const location = useLocation();
  const { title, type } = location.state;

  const groupList = [
    {
      id: 1,
      rank: 1,
      name: '충남체육회',
      score: 92771,
    },
    {
      id: 2,
      rank: 2,
      name: '보령군',
      score: 74875,
    },
    {
      id: 3,
      rank: 3,
      name: '홍성군',
      score: 50963,
    },
    {
      id: 4,
      rank: 4,
      name: '논산시',
      score: 20796,
    },
    {
      id: 5,
      rank: 5,
      name: '천안시',
      score: 18599,
    },
    {
      id: 6,
      rank: 6,
      name: '공주시',
      score: 16708,
    },
  ];

  return (
    <Container title={title} $padding="20px 16px">
      {type === 'my' ? (
        <>
          <C.Tab list={['일일', '주간', '월간']} tabIndex={tabIndex} currentTab={currentTab} />
          <S.Text $padding="16px 0" $color="gray500" $type="label12" $textAlign="center">
            집계 기준 시간
            <S.Span $pLeft={8} $color="gray700" $type="label12">
              08월 09일 00시 ~ 오후 10:05
            </S.Span>
          </S.Text>
          {tabIndex === 0 && (
            <S.List>
              {list?.map((item, index) => (
                <S.ListItem
                  key={`item_${index}`}
                  $padding="16px 8px"
                  $borderBottom={index !== myIndex && list.length - 1 !== index}
                  $bgColor={index === myIndex && 'gray50'}
                  $radius={10}
                >
                  <S.RowDiv $align="center" $gap={8}>
                    <S.Text $color="gray700" $textAlign="center" $type="m12" $minWidth={50}>
                      {item.ranking?.toLocaleString()}
                    </S.Text>
                    <S.RowDiv $gap={8} $flex={1} $align="center">
                      <S.Div $width={24} $height={24} $radius="50%" $bgColor={!item.img && randomColor()}>
                        <C.Img src={item.img || 'randomUser'} />
                      </S.Div>
                      <S.Text $color="gray700" $type="14">
                        {index === myIndex ? '나' : item.userName}
                      </S.Text>
                    </S.RowDiv>
                    <S.Text $color={index === myIndex ? 'primary' : 'gray700'} $type="m">
                      {item.walk?.toLocaleString()}
                    </S.Text>
                  </S.RowDiv>
                </S.ListItem>
              ))}
            </S.List>
          )}
          {tabIndex === 1 && (
            <S.List>
              {list?.map((item, index) => (
                <S.ListItem
                  key={`item_${index}`}
                  $padding="16px 8px"
                  $borderBottom={index !== myIndex && list.length - 1 !== index}
                  $bgColor={index === myIndex + 1 && 'gray50'}
                  $radius={10}
                >
                  <S.RowDiv $align="center" $gap={8}>
                    <S.Text $color="gray700" $textAlign="center" $type="m12" $minWidth={50}>
                      {item.ranking?.toLocaleString()}
                    </S.Text>
                    <S.RowDiv $gap={8} $flex={1} $align="center">
                      <S.Div $width={24} $height={24} $radius="50%" $bgColor={!item.img && randomColor()}>
                        <C.Img src={item.img || 'randomUser'} />
                      </S.Div>
                      <S.Text $color="gray700" $type="14">
                        {index === myIndex ? '나' : item.userName}
                      </S.Text>
                    </S.RowDiv>
                    <S.Text $color={index === myIndex ? 'primary' : 'gray700'} $type="m">
                      {item.walk?.toLocaleString()}
                    </S.Text>
                  </S.RowDiv>
                </S.ListItem>
              ))}
            </S.List>
          )}
          {tabIndex === 2 && (
            <S.List>
              {list?.map((item, index) => (
                <S.ListItem
                  key={`item_${index}`}
                  $padding="16px 8px"
                  $borderBottom={index !== myIndex && list.length - 1 !== index}
                  $bgColor={index === myIndex + 1 && 'gray50'}
                  $radius={10}
                >
                  <S.RowDiv $align="center" $gap={8}>
                    <S.Text $color="gray700" $textAlign="center" $type="m12" $minWidth={50}>
                      {item.ranking?.toLocaleString()}
                    </S.Text>
                    <S.RowDiv $gap={8} $flex={1} $align="center">
                      <S.Div $width={24} $height={24} $radius="50%" $bgColor={!item.img && randomColor()}>
                        <C.Img src={item.img || 'randomUser'} />
                      </S.Div>
                      <S.Text $color="gray700" $type="14">
                        {index === myIndex ? '나' : item.userName}
                      </S.Text>
                    </S.RowDiv>
                    <S.Text $color={index === myIndex ? 'primary' : 'gray700'} $type="m">
                      {item.walk?.toLocaleString()}
                    </S.Text>
                  </S.RowDiv>
                </S.ListItem>
              ))}
            </S.List>
          )}
        </>
      ) : (
        <>
          <S.RowDiv $justify="space-between" $align="center">
            <S.RowDiv $align="center" $gap={4}>
              <C.Icon name="Users" $width={16} $height={16} $pathFill="primary" />
              <S.Text $color="gray700" $type="btn14" $fontWeight={500}>
                총 그룹
              </S.Text>
            </S.RowDiv>
            <S.Text $color="gray900" $type="b24">
              31
              <S.Span $pLeft={4} $type="20" $color="gray900">
                그룹
              </S.Span>
            </S.Text>
          </S.RowDiv>
          <S.List $pTop={16}>
            {groupList.map((item, index) => (
              <S.RowListItem
                key={`item_${item.id}`}
                $borderBottom={groupList.length - 1 !== index}
                $padding="16px 8px"
                $align="center"
              >
                <S.Text $color="gray700" $type="btn12" $fontWeight={500} $width={48} $textAlign="center">
                  {item.rank}
                </S.Text>
                <S.Text $padding="0 8px" $color="gray900" $type="btn14" style={{ flex: 1 }}>
                  {item.name}
                </S.Text>
                <S.Text $padding="0 8px" $color="gray700" $type="btn16" $fontWeight={500}>
                  {item.score.toLocaleString()}
                </S.Text>
              </S.RowListItem>
            ))}
          </S.List>
        </>
      )}
    </Container>
  );
}
