import React from 'react';
import styled from 'styled-components';
import { RowCenterDiv, Span, theme } from 'styles';

export function Line({ ...props }) {
  return (
    <RowCenterDiv>
      <LineSpan {...props} />
    </RowCenterDiv>
  );
}

const LineSpan = styled(Span)`
  width: 1px;
  background-color: ${theme.colors.gray300};
`;
