import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Div, Text } from 'styles';
import { Img } from './Img';

export function SubMenuList({ list }) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    list && (
      <ul>
        {list.map((menu, index) => (
          <SubItem
            key={`menu_${index}`}
            $mt={index !== 0}
            onClick={() => navigate(`${location.pathname}/${menu.path}`)}
            $disabled={menu.disabled}
          >
            <Img src={menu.disabled ? `${menu.img}Disabled` : menu.img} alt={menu.name} $width={96} $height={96} />
            <Div $padding="13px 20px 13px 0">
              <Text $type="b16" $color="gray900">
                {menu.name}
              </Text>
              <Text $type="14" $color="gray800" $pt={4}>
                {menu.desc}
              </Text>
            </Div>
          </SubItem>
        ))}
      </ul>
    )
  );
}

const SubItem = styled.li`
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 8px;
  gap: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  ${({ $disabled }) => $disabled && `opacity:0.8;pointer-events: none;`};
  ${({ $mt }) => $mt && `margin-top:8px`};
`;
