import React from 'react';
import * as C from 'components';
import { Div, Text } from 'styles';
import * as S from './InputStyle';

export function FileInput({ values, limit = 3, onChange }) {
  const fileUpload = (e) => {
    if (values.files.length < limit) {
      const newFiles = [...values.files, e.target.files[0]];
      onChange('files', newFiles);
    }
  };

  const deleteFile = (index) => {
    const updatedFiles = [...values.files];
    updatedFiles.splice(index, 1);
    onChange('files', updatedFiles);
  };

  return (
    <Div>
      <S.FileLabel htmlFor="file" $disabled={values.files.length >= limit}>
        <S.FileDiv $border>
          {values.files.length < limit && <C.Icon name="File" />}
          <Text $color={values.files.length >= limit ? 'disabled' : 'gray700'} $type="m">
            {(values.files.length >= limit && `파일은 최대 ${limit}개까지 첨부 가능합니다.`) || `파일 첨부하기`}
          </Text>
        </S.FileDiv>
        <input id="file" type="file" disabled={values.files.length >= limit} onChange={fileUpload} />
      </S.FileLabel>
      {values.files.length > 0 && (
        <S.FileList>
          {values.files.map((file, index) => (
            <S.FileListItem key={index} onClick={() => deleteFile(index)} $border={{ color: 'fill05' }}>
              <Text $type="m14">{file.name}</Text>
              <C.Icon name="Del" />
            </S.FileListItem>
          ))}
        </S.FileList>
      )}
    </Div>
  );
}
