import React, { useState } from 'react';
import * as S from './SwitchStyle';

export function Switch({ name, onChange, disabled, checked }) {
  const handleOnChange = (e) => {
    onChange(e.target.checked)
  };

  return (
    <S.SwitchLabel>
      <S.SwitchInput type="checkbox" disabled={disabled} onChange={handleOnChange} checked={checked} />
      <S.Slider className="slider" $disabled={disabled} />
    </S.SwitchLabel>
  );
}
