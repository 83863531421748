import React from 'react';
import * as S from 'styles';
import styled from 'styled-components';
import { HomeTitle } from './HomeTitle';

export function HomeNotice({ notice, navigate }) {
  return (
    <S.Section $padding="20px">
      <HomeTitle icon="Megaphone" onClick={() => navigate('/center/notice')}>
        공지사항
      </HomeTitle>
      <NoticeList>
        {notice?.map((item, index) => (
          <S.ListItem
            key={`home_notice_${index}`}
            onClick={() => navigate(`/center/notice/${item.id}`, { state: item })}
          >
            <S.LineDiv $flex={5} $padding="16px" $shadow>
              <S.Text $color="gray900" $type="m">
                {item.name}
              </S.Text>
              <S.Text $type="m12" $color="gray500" $mTop={8}>
                {item.createDate}
              </S.Text>
            </S.LineDiv>
          </S.ListItem>
        ))}
      </NoticeList>
    </S.Section>
  );
}

const NoticeList = styled(S.List)`
  display: flex;
  gap: 8px;
  padding-top: 16px;
`;
