import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';

export function CouponListView({ list, navigate }) {
  return (
    <Container title="쿠폰함">
      <S.ColList $gap={16}>
        {list?.map((item, index) => (
          <S.ListItem key={`coupon_${index}`} onClick={() => navigate(`/point/coupon/${item.id}`, { state: item })}>
            <S.LineDiv $shadow>
              <C.Img src={item.img} alt="쿠폰" $height={82} $padding="20px 0" />
              <S.Text $pTop={8} $type="b20" $color="gray900">
                {item.name}
              </S.Text>
              <S.Text $pTop={8} $type="m12" $color="gray600" $lineHeight="14px">
                구매일자 : {item.createDate}
              </S.Text>
            </S.LineDiv>
          </S.ListItem>
        ))}
      </S.ColList>
    </Container>
  );
}
