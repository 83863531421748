import { useEffect } from 'react';

import useSettingsStore from 'stores/settings/SettingsStore';
import nativeAppSettingsFunctions from 'mobile-bridge/appSettingsFunctions';

const useSettingsHandler = () => {
  const setPushNotificationEnabled = useSettingsStore((state) => state.setPushNotificationEnabled);
  const setActivity = useSettingsStore((state) => state.setActivity);
  const setBeaconYN = useSettingsStore((state) => state.setBeaconYN);
  const setPointOption = useSettingsStore((state) => state.setPointOption);

  useEffect(() => {
    const handlePushNotificationStatus = (e) => {
      const { enabled } = e.detail || {};

      setPushNotificationEnabled(enabled)
    };

    window.addEventListener('pushNotificationStatus', handlePushNotificationStatus);
    return () => window.removeEventListener('pushNotificationStatus', handlePushNotificationStatus);
  }, []);

  useEffect(() => {
    const handleSharePreferenceValue = (e) => {
      const { key, value } = e.detail || {};

      if (key === 'activity') {
        setActivity(value);
      }

      if (key === 'beaconYN') {
        setBeaconYN(value);
      }

      if (key === 'point_option') {
        setPointOption(value);
      }
    };

    window.addEventListener('sharedPreferenceValue', handleSharePreferenceValue);
    return () => window.removeEventListener('sharedPreferenceValue', handleSharePreferenceValue);
  }, []);

  useEffect(() => {
    nativeAppSettingsFunctions.getPushNotificationStatus();
    nativeAppSettingsFunctions.getSharedPreferenceBoolean('activity');
    nativeAppSettingsFunctions.getSharedPreferenceBoolean('beaconYN');
    nativeAppSettingsFunctions.getSharedPreferenceBoolean('point_option');
  }, [])
};

export default useSettingsHandler;