export const headerMenu = [
  {
    name: '홈',
    icon: 'Home',
    path: '/',
  },
  {
    name: '포인트',
    icon: 'Point',
    path: 'point',
  },
  {
    name: '챌린지',
    icon: 'Challenge',
    path: 'challenge',
  },
  {
    name: '커뮤니티',
    icon: 'Community',
    path: 'community',
  },
  {
    name: 'MY서비스',
    icon: 'My',
    path: 'my',
  },
];

export const sideMenu = [
  {
    name: '홈',
    icon: 'Home',
    path: '/',
  },
  {
    name: '포인트',
    icon: 'Point',
    path: 'point',
    sub: [
      {
        name: '포인트몰',
        path: 'mall',
        img: 'pointMall',
        desc: '포인트로 필요한 상품을 구매할 수 있습니다.',
      },
      {
        name: '기부통',
        path: 'donation',
        img: 'pointDonation',
        desc: '포인트를 사회를 위해 기부할 수 있습니다.',
      },
      {
        name: '광고',
        path: 'ad',
        img: 'pointAd',
        desc: '광고보고 포인트를 쌓으세요!',
      },
      {
        name: '포인트이력',
        path: 'history',
        img: 'pointHistory',
        desc: '쌓은 포인트와 사용한 포인트를 조회하세요.',
      },
      {
        name: '쿠폰함',
        path: 'coupon',
        img: 'pointCoupon',
        desc: '나의 쿠폰을 한눈에 보실 수 있습니다.',
      },
    ],
  },
  {
    name: '챌린지',
    icon: 'Challenge',
    path: 'challenge',
    sub: [
      {
        name: '챌린지',
        path: 'basic',
        img: 'challenge',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      },
      {
        name: '스탬프 투어',
        path: 'stamp',
        img: 'stamp',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      },
    ],
  },
  {
    name: '커뮤니티',
    icon: 'Community',
    path: 'community',
    sub: [
      {
        name: '커뮤니티',
        path: '',
      },
      {
        name: '나의 커뮤니티',
        path: '',
      },
    ],
  },
  {
    name: '고객센터',
    icon: 'Customer',
    path: 'center',
    sub: [
      {
        name: '공지사항',
        path: 'notice',
        img: 'notice',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      },
      {
        name: '지역홍보',
        path: 'promo',
        img: 'promo',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      },
      {
        name: 'FAQ',
        path: 'faq',
        img: 'faq',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      },
      {
        name: '1:1 문의',
        path: 'inquiry',
        img: 'inquiry',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      },
    ],
  },
  {
    name: 'MY서비스',
    icon: 'My',
    path: 'my',
    sub: [
      {
        name: '나의 정보',
        path: 'info',
        img: 'myInfo',
        desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
      },
      {
        name: '나의 문의',
        path: 'inquiry',
        img: 'myInquiry',
        desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
      },
      {
        name: '디바이스 연결관리',
        path: 'device',
        img: 'myDevice',
        desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
      },
      {
        name: '나의 운동 분석',
        path: 'sports',
        img: 'mySports',
        desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
        disabled: true,
      },
    ],
  },
  {
    name: '설정',
    icon: 'Setting',
    path: 'setting',
    sub: [
      {
        name: '알림 설정',
        list: [
          { name: 'push', label: '푸시 알림 받기', desc: `주요 업데이트, 알림 등을 실시간으로 받습니다.` },
          // {
          //   name: 'challenge',
          //   label: '챌린지 알림 받기',
          //   desc: `참여 중인 챌린지의 진행 상황, 새로운 챌린지\n시작 등을 알림으로 받을 수 있습니다.`,
          // },
          // { name: 'community', label: '커뮤니티 알림 받기', desc: `커뮤니티 내 이벤트 및 공지` },
          // { name: 'friends', label: '친구 활동 알림 받기', desc: `친구의 활동과 관련된 알림을 받습니다.` },
        ],
      },
      {
        name: '기록 설정',
        list: [
          { name: 'activity', label: '위치 기록 설정' },
          { name: 'beaconYN', label: '비콘 감지' },
          { name: 'count', label: '목표 걸음수 달성' },
          { name: 'open', label: '나의 운동기록 커뮤니티 공개설정' },
        ],
      },
      {
        name: '안내',
        list: [{ name: 'point_option', label: '포인트 사용팁 표시', desc: `포인트 적립 및 사용과 관련된 팁을 표시합니다.` }],
      },
      {
        name: '계정 관리',
        list: [{ path: '/my', label: '계정 관리', desc: `로그인 계정 정보 관리` }],
      },
      {
        name: '정보',
        path: 'info',
        list: [
          {
            path: `/setting/info`,
            label: '앱 정보',
            desc: `앱 버전 및 이용약관`,
            sub: [
              { id: 1, name: '이용약관' },
              { id: 2, name: '개인정보 처리방침' },
              { id: 3, name: '위치정보 이용약관' },
              { id: 4, name: '제3자 정보제공 이용약관' },
            ],
          },
        ],
      },
    ],
  },
];
