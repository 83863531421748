import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import { useNavigate, useLocation } from 'react-router-dom';

export function CommunityNoticeDetail(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { parentId, item } = location.state || {};

  return (
    <Container title={item.name}>
      <S.Div $minHeight="calc(100% - 50px)">
        <S.Div $pBottom={15} $borderBottom>
          <S.Text $type="b18" $color="gray900">
            {item.name}
          </S.Text>
          {item.createDate && (
            <S.Text $pTop={4} $type="14" $color="gray500">
              {item.createDate}
            </S.Text>
          )}
        </S.Div>
        {item.img && (
          <S.Div>
            <C.Img src={item.img} />
          </S.Div>
        )}
        <S.Text $color="gray800" $padding="16px 0 20px" $whiteSpace="pre-wrap">
          {item.con}
        </S.Text>
      </S.Div>
      <C.Button
        $size="l"
        $type="sub"
        $leftIcon="List"
        $gap={4}
        onClick={() => navigate(`/community/${parentId}/challenge/notice`)}
      >
        목록
      </C.Button>
    </Container>
  );
}
