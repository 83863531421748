import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { useSubMenu } from 'hooks';
import { Container } from 'layouts';

export function ChallengeHome(props) {
  const subMenu = useSubMenu();

  return (
    <Container title="챌린지" $position="relative">
      <C.SubMenuList list={subMenu} />
      <S.Div $position="absolute" $width="100vw" $height={80} $bottom={64} $left="0" $bgColor="#A1BCFF">
        <C.Img src="banner" $height={80} />
      </S.Div>
    </Container>
  );
}
