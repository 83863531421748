export const checkPixel = (params) => {
  // 단위가 붙어있지 않은 경우, 자동으로 px 단위로 반환
  if (!Number.isNaN(params) && typeof params === 'number') {
    return `${params}px`;
  }

  // 이미 픽셀 값인 경우 또는 % 단위가 붙어 있는 경우 그대로 반환
  if (typeof params === 'string' && (params.trim().match(/^\d*\.?\d+px$/) || params.trim().match(/^\d*\.?\d+%$/))) {
    return params;
  }

  // 단위가 붙어 있지 않은 경우, px 단위로 반환
  return params;
};
