import { ColDiv } from 'styles';
import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
100%   {transform: rotate(360deg)}`;

const prixClipFix = keyframes`
    0%   {border-width:3px;clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {border-width:3px;clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {border-width:3px;clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {border-width:3px;clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {border-width:3px;clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;

// type === inside
const loader10g = keyframes`
  0%{background-color: rgba(172,172,172, .2);}
  25%{background-color: rgba(172,172,172, 1);}
  50%{background-color: rgba(172,172,172, .2);}
  75%{background-color: rgba(172,172,172, .2);}
  100%{background-color: rgba(172,172,172, .2);}`;

const loader10m = keyframes`
    0%{background-color: rgba(172,172,172, .2);  }
    25%{background-color: rgba(172,172,172, .2);  }
    50%{background-color: rgba(172,172,172, 1);  }
    75%{background-color: rgba(172,172,172, .2);  }
    100%{background-color: rgba(172,172,172, .2);  }
  `;

const loader10d = keyframes`
  0%{background-color: rgba(172,172,172, .2);}
    25%{background-color: rgba(172,172,172, .2);}
    50%{background-color: rgba(172,172,172, .2);}
    75%{background-color: rgba(172,172,172, 1);}
    100%{background-color: rgba(172,172,172, .2);}`;

export const LoadingStyles = styled(ColDiv)`
  z-index: 9999;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  justify-content: center;
  align-items: center;

  ${({ $type, theme }) =>
    ($type === 'inside' &&
      css`
        position: absolute;

        & > .loader {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 10px;
          height: 10px;
          border-radius: 12px;
          -webkit-animation: ${loader10m} 2s ease-in-out infinite;
          animation: ${loader10m} 2s ease-in-out infinite;

          &:before {
            content: '';
            position: absolute;
            top: 0px;
            left: -20px;
            height: 10px;
            width: 10px;
            border-radius: 12px;
            -webkit-animation: ${loader10g} 2s ease-in-out infinite;
            animation: ${loader10g} 2s ease-in-out infinite;
          }

          &:after {
            content: '';
            position: absolute;
            top: 0px;
            left: 20px;
            height: 10px;
            width: 10px;
            border-radius: 12px;
            -webkit-animation: ${loader10d} 2s ease-in-out infinite;
            animation: ${loader10d} 2s ease-in-out infinite;
          }
        }
      `) ||
    css`
      & > .loader {
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        animation: ${spin} 1.5s linear infinite;
      }

      & > .loader::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 0px solid ${theme.colors.primary};
        animation: ${prixClipFix} 1.5s linear infinite;
        animation-delay: 1.5s;
      }
    `}
`;
