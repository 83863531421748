import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StampDetailView } from './StampDetailView';

export function StampDetail(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const item = location.state;

  return <StampDetailView item={item} navigate={navigate} />;
}
