import { useCookies } from 'react-cookie';

export function useCookie() {
  const [cookie, setCookie, removeCookie] = useCookies();

  const setCookies = (key, value, expiresParams, path) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * (expiresParams || 7));

    const options = (expiresParams && { path: path || '/', expires }) || {
      path: path || '/',
    };
    setCookie(key, encodeURI(value), {
      ...options,
    });
  };

  const getCookie = (key) => (cookie[key] && decodeURI(cookie[key])) || null;

  const removeCookies = (key, path) =>
    removeCookie(key, {
      path: path || '/',
    });
  return { getCookie, setCookies, removeCookies };
}
