import React, { useState, useEffect } from 'react';
import { Text, theme } from 'styles';
import styled, { css } from 'styled-components';

const stepList = [
  { step: 'step1', num: '1' },
  { step: 'step2', num: '2' },
  { step: 'step3', num: '3' },
  { step: 'step4', num: '4' },
];

export function JoinStepTab({ step }) {
  const [current, setCurrent] = useState('1');

  useEffect(() => {
    const tempStep = stepList.find((item) => item.step === step);
    if (tempStep) {
      setCurrent(tempStep);
    }
  }, [step]);

  return (
    <StepList>
      {stepList.map((item, index) => (
        <StepItem key={`item_${index}`} $current={item.step === current.step}>
          <Text
            $type={(item.step === current.step && 'm20') || 'r16'}
            $color={(item.step === current.step && 'gray700') || 'gray500'}
          >
            {item.num}
          </Text>
        </StepItem>
      ))}
    </StepList>
  );
}

const StepList = styled.ul`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  height: 30px;
`;

const StepItem = styled.li`
  position: relative;

  ${({ $current }) =>
    $current &&
    css`
      flex: 1;

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 24px;
        bottom: 0;
        height: 1px;
        background-color: ${theme.colors.gray200};
        width: calc(100% - 24px);
      }
    `}

  p {
    text-align: center;
    width: 16px;
  }
`;
