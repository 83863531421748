import styled, { css } from 'styled-components';
import { flex, rowCenter, spacing, theme } from 'styles';
import { checkPixel } from 'utils';

const sizes = {
  s: css`
    height: 30px;
    font-size: 14px;
    border-radius: 6px;
  `,
  m: css`
    height: 40px;
    font-size: 14px;
    border-radius: 8px;
  `,
  l: css`
    height: 50px;
    font-size: 16px;
    border-radius: 10px;
  `,
};

const types = {
  default: {
    enabled: css`
      background-color: ${theme.colors.primary};
      color: white;
    `,
    focus: css`
      background-color: ${theme.colors.primary600};
      color: white;
    `,
    active: css`
      background-color: ${theme.colors.primary600};
      color: white;
    `,
    disabled: css`
      background-color: #ccecfb;
      color: ${theme.colors.white05};
    `,
  },
  sub: {
    enabled: css`
      border: 1px solid ${theme.colors.fill05};
      color: ${theme.colors.gray700};
      background-color: white;
    `,
    focus: css`
      border: 1px solid ${theme.colors.fill02};
      background-color: ${theme.colors.fill02};
    `,
    active: css`
      border: 1px solid ${theme.colors.fill02};
      background-color: ${theme.colors.fill02};
    `,
    disabled: css`
      border: 1px solid ${theme.colors.fill05};
      color: ${theme.colors.disabled};
      background-color: white;
    `,
  },
  line: {
    enabled: css`
      border: 1px solid ${theme.colors.gray200};
      background-color: white;
      color: ${({ $color }) => theme.colors[$color] || theme.colors.gray800};
    `,
    focus: css`
      border: 1px solid ${theme.colors.primary600};
      background-color: white;
      color: ${({ $focusColor }) => theme.colors[$focusColor] || theme.colors.primary600};
    `,
    active: css`
      border: 1px solid ${theme.colors.primary600};
      background-color: white;
      color: ${({ $focusColor }) => theme.colors[$focusColor] || theme.colors.primary600};
    `,
    disabled: css`
      opacity: 30%;
      border: 1px solid ${theme.colors.fill05};
      background-color: white;
      color: ${({ $color }) => theme.colors[$color] || theme.colors.gray800};
    `,
  },
};

export const Btn = styled.button`
  ${rowCenter}
  ${spacing}
  ${flex}
  transition: all 150ms ease;
  line-height: 1;
  width: ${({ $width }) => checkPixel($width) || '100%'};
  ${({ $size }) => sizes[$size] || sizes.m}
  ${({ $type }) => types[$type]?.enabled || types.default.enabled}

  padding: ${({ $leftIcon, $rightIcon }) =>
    $leftIcon && !$rightIcon ? '0 16px 0 12px' : !$leftIcon && $rightIcon ? '0 12px 0 16px' : '0'};

  &:focus {
    ${({ $type }) => types[$type]?.focus || types.default.focus}
  }

  ${({ $active, $type }) => $active && types[$type]?.active}

  &:disabled {
    ${({ $type }) => types[$type]?.disabled || types.default.disabled}
  }
`;
