import * as C from 'components';
import { useFormik } from 'formik';
import { Container } from 'layouts';
import { useEffect, useState } from 'react';
import QnAService from 'services/qna/QnAService';
import * as S from 'styles';
import * as Yup from 'yup';

const QNA_TYPE_LIST = [
	{
		name: '서비스 문의',
		value: '1',
	},
	{
		name: '기능문의',
		value: '2',
	},
	{
		name: '장애신고',
		value: '3',
	},
	{
		name: '제안사항',
		value: '4',
	},
	{
		name: '기타',
		value: '5',
	},
	{
		name: '웰뱅연동',
		value: '6',
	},
	{
		name: '부정걸음수',
		value: '7',
	},
];

export function CenterInquiry(props) {
	const [invalids, setInvalids] = useState({});
	const [type, setType] = useState(null);
	const validationSchema = Yup.object().shape({
		eMail: Yup.string()
			.required('이메일 주소를 입력해 주세요.')
			.email('입력하신 이메일 형식이 맞지 않습니다. 다시 입력 해주세요.'),
		type: Yup.string().required('문의사항 타입을 선택해 주세요.'),
		title: Yup.string().required('제목을 입력해 주세요.'),
		content: Yup.string().required('내용을 입력해 주세요.'),
	});

	const formik = useFormik({
		validationSchema,
		initialValues: {
			eMail: '',
			type: '',
			title: '',
			content: '',
			imgFile: { files: [] },
		},
		onSubmit: (values) => {
			const data = {};

			for (const key in values) {
				if (Object.prototype.hasOwnProperty.call(values, key)) {
					const element = values[key];

					if (key === 'imgFile') {
						data[key] = element.files;
					} else if (key === 'type') {
						data[key] = type.name;
					} else {
						data[key] = element;
					}
				}
			}

			QnAService.write(data).then((res) => {
				if (res) {
					setModalOpen(true);
				}
			});
		},
	});

	const [modalOpen, setModalOpen] = useState(false);

	const close = () => {
		setModalOpen(false);
		formik.resetForm();
	};

	useEffect(() => {
		const invalids = {};
		for (const key in formik.errors) {
			if (Object.prototype.hasOwnProperty.call(formik.errors, key)) {
				const element = formik.errors[key];
				invalids[key] = { type: 'error', text: element };
			}
		}

		setInvalids(invalids);
	}, [formik.errors]);

	return (
		<>
			<Container title="1:1 문의" $height="calc(100% - 140px)">
				<form id="form" onSubmit={formik.handleSubmit}>
					<S.Text $color="gray900" $type="b18">
						문의하기
					</S.Text>
					<S.ColDiv $gap={8} $padding="16px 0">
						<C.Input
							name="eMail"
							placeholder="이메일 주소 입력"
							value={formik.values.eMail}
							onChange={(name, value) => {
								formik.setFieldValue(name, value);
							}}
							onBlur={() => formik.setFieldTouched('eMail', true)}
							$invalid={formik.touched.eMail && invalids.eMail}
						/>
						<C.Dropdown
							name="type"
							placeholder="문의 분야 선택"
							value={formik.values.type}
							onChange={(name, value) => {
								const type = QNA_TYPE_LIST.find((item) => item.value === value);

								setType(type);
								formik.setFieldValue(name, value);
							}}
							onClose={() => {
								formik.setFieldTouched('type', true);
							}}
							title="문의 분야 선택"
							list={QNA_TYPE_LIST}
							$invalid={formik.touched.type && invalids.type}
						/>
						<C.Input
							name="title"
							placeholder="제목 입력"
							value={formik.values.title}
							onChange={(name, value) => {
								formik.setFieldValue(name, value);
							}}
							onBlur={() => formik.setFieldTouched('title', true)}
							$invalid={formik.touched.title && invalids.title}
						/>
						<C.Textarea
							name="content"
							placeholder="내용 입력"
							value={formik.values.content}
							onChange={(name, value) => {
								formik.setFieldValue(name, value);
							}}
							onBlur={() => formik.setFieldTouched('content', true)}
							$invalid={formik.touched.content && invalids.content}
						/>
					</S.ColDiv>
					<C.FileInput
						values={formik.values.imgFile}
						limit={5}
						onChange={(__, value) => {
							const newValue = {
								files: value,
							};

							formik.setFieldValue('imgFile', newValue);
						}}
					/>
				</form>
			</Container>
			<S.Div $padding="8px 20px 20px">
				<C.Button $size="l" form="form" type="submit">
					문의하기
				</C.Button>
			</S.Div>

			<C.Modal open={modalOpen} $btn1={{ text: '홈으로', onClick: close }}>
				<S.ColCenterDiv>
					<C.Img src="success" $width={60} $height={60} />
					<S.Text $textAlign="center" $pTop={16}>
						문의하신 내용이 저장되었습니다.
						<br />
						답변은 My 서비스에서
						<br />
						확인하실 수 있습니다.
					</S.Text>
				</S.ColCenterDiv>
			</C.Modal>
		</>
	);
}
