import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import styled from 'styled-components';

export function StampListView({ navigate, tabIndex, currentTab }) {
  return (
    <Container title="스탬프 투어" $padding="20px 16px">
      <C.Tab list={['스탬프 투어', '스탬프 이력']} tabIndex={tabIndex} currentTab={currentTab} />
      <ImgMap>
        {tabIndex === 0 ? (
          <>
            <C.Img src="stampMap" $width={312} $height={323} />
            <C.SvgText text="태안" $top="0" $left="2%" onClick={() => navigate('1', { state: '태안군' })} />
            <C.SvgText text="서산" $top="5%" $left="19%" onClick={() => navigate('2', { state: '서산시' })} />
            <C.SvgText text="당진" $top="-14%" $left="31%" onClick={() => navigate('3', { state: '당진시' })} />
            <C.SvgText text="아산" $top="-3%" $left="50%" onClick={() => navigate('4', { state: '아산시' })} />
            <C.SvgText text="천안" $top="3%" $left="69%" onClick={() => navigate('5', { state: '천안시' })} />
            <C.SvgText text="예산" $top="19%" $left="37%" onClick={() => navigate('6', { state: '예산군' })} />
            <C.SvgText text="홍성" $top="31%" $left="17%" onClick={() => navigate('7', { state: '홍성군' })} />
            <C.SvgText text="공주" $top="26%" $left="58%" onClick={() => navigate('8', { state: '공주시' })} />
            <C.SvgText text="보령" $top="55%" $left="19%" onClick={() => navigate('9', { state: '보령시' })} />
            <C.SvgText text="부여" $top="47%" $left="40%" onClick={() => navigate('10', { state: '부여군' })} />
            <C.SvgText text="논산" $top="59%" $left="56%" onClick={() => navigate('11', { state: '논산시' })} />
            <C.SvgText text="서천" $top="69%" $left="34%" onClick={() => navigate('12', { state: '서천군' })} />
            <C.SvgText text="금산" $top="68%" $left="83%" onClick={() => navigate('13', { state: '금산군' })} />
          </>
        ) : (
          <>
            <C.Img src="stampHistoryMap" $width={312} $height={323} />
            <C.SvgTextYellow
              text="태안"
              $top="0"
              $left="2%"
              onClick={() => navigate('history/1', { state: '태안군' })}
            />
            <C.SvgTextYellow
              text="서산"
              $top="5%"
              $left="19%"
              onClick={() => navigate('history/2', { state: '서산시' })}
            />
            <C.SvgTextYellow
              text="당진"
              $top="-14%"
              $left="31%"
              onClick={() => navigate('history/3', { state: '당진시' })}
            />
            <C.SvgTextYellow
              text="아산"
              $top="-3%"
              $left="50%"
              onClick={() => navigate('history/4', { state: '아산시' })}
            />
            <C.SvgTextYellow
              text="천안"
              $top="3%"
              $left="69%"
              onClick={() => navigate('history/5', { state: '천안시' })}
            />
            <C.SvgTextYellow
              text="예산"
              $top="19%"
              $left="37%"
              onClick={() => navigate('history/6', { state: '예산군' })}
            />
            <C.SvgTextYellow
              text="홍성"
              $top="31%"
              $left="17%"
              onClick={() => navigate('history/7', { state: '홍성군' })}
            />
            <C.SvgTextYellow
              text="공주"
              $top="26%"
              $left="58%"
              onClick={() => navigate('history/8', { state: '공주시' })}
            />
            <C.SvgTextYellow
              text="보령"
              $top="55%"
              $left="19%"
              onClick={() => navigate('history/9', { state: '보령시' })}
            />
            <C.SvgTextYellow
              text="부여"
              $top="47%"
              $left="40%"
              onClick={() => navigate('history/10', { state: '부여군' })}
            />
            <C.SvgTextYellow
              text="논산"
              $top="59%"
              $left="56%"
              onClick={() => navigate('history/11', { state: '논산시' })}
            />
            <C.SvgTextYellow
              text="서천"
              $top="69%"
              $left="34%"
              onClick={() => navigate('history/12', { state: '서천군' })}
            />
            <C.SvgTextYellow
              text="금산"
              $top="68%"
              $left="83%"
              onClick={() => navigate('history/13', { state: '금산군' })}
            />
          </>
        )}
      </ImgMap>
    </Container>
  );
}

const ImgMap = styled(S.Div)`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 89px auto 36px;
  width: 312px;
  height: 313px;
`;
