import React from 'react';
import { Container } from 'layouts';
import * as S from 'styles';
import * as C from 'components';
import { myInquiryList } from 'data/myData';
import { inquiryList } from 'data/centerData';
import { useNavigate } from 'react-router-dom';

export function MyInquiryList(props) {
  const navigate = useNavigate();
  const list = myInquiryList;

  const findType = (item) => {
    const itemType = inquiryList.find((el) => String(el.value) === String(item.type));
    if (itemType) {
      return itemType.name;
    }
  };

  return (
    <Container title="나의 문의">
      <S.List>
        {list.map((item, index) => (
          <S.ListItem
            key={`list_${item.id}`}
            onClick={() => navigate(`${item.id}`, { state: item })}
            $padding="8px"
            $borderBottom={list.length - 1 !== index}
          >
            <C.Badge $bgColor={item.answerType === 0 && 'gray400'} $mBottom={4}>
              {item.answerType === 0 ? '처리중' : '답변 완료'}
            </C.Badge>
            <S.Text $color="gray900" $type="m">
              [{findType(item)}]&nbsp;{item.name}
            </S.Text>
            <S.Text $pTop={4} $color="gray500" $type="12" $lineHeight="14px">
              {item.createDate}
            </S.Text>
          </S.ListItem>
        ))}
      </S.List>
    </Container>
  );
}
