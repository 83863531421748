import styled, { css } from 'styled-components';
import { List, ListItem, RowCenterDiv, Span, theme } from 'styles';
import { checkPixel } from 'utils';

const types = {
  default: css`
    border: 1px solid ${theme.colors.gray200};
    background-color: #ffffff;
  `,
  focused: css`
    border: 1px solid ${theme.colors.primary};
    box-shadow: inset 0px 0px 0px 3px #dff4f5;
    background-color: #ffffff;
  `,
  disabled: css`
    background-color: ${theme.colors.fill02};
    border: 1px solid ${theme.colors.fill05};
  `,
  error: css`
    border: 1px solid ${theme.colors.alert};
    background-color: #ffffff;
    box-shadow: inset 0px 0px 0px 3px rgba(225, 44, 37, 0.2);
  `,
};

export const InputBox = styled.div`
  display: flex;
  gap: 4px;
  border-radius: 8px;
  padding: 7px;
  height: ${({ $height }) => ($height && checkPixel($height)) || '40px'};
  transition: all 150ms;
  align-items: center;

  ${({ disabled, $invalid }) => (disabled ? types.disabled : $invalid ? types.error : types.default)}

  &:focus-within {
    ${({ disabled, $invalid }) => !disabled && !$invalid && types.focused}
  }
`;

export const CustomInput = styled.input`
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;

  &::placeholder {
    color: ${theme.colors.supportive};
  }
`;

export const CodeSpan = styled(Span)`
  display: inline-block;
  min-width: 42px;
  width: 42px;
`;

// file input css
export const FileLabel = styled.label`
  input {
    display: none;
  }
`;

export const FileDiv = styled(RowCenterDiv)`
  border-radius: 8px;
  gap: 4px;
  height: 40px;
`;

export const FileList = styled(List)`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FileListItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 16px;
  height: 30px;
  border-radius: 6px;
`;
