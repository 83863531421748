import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';

export function StampHistoryDetailView({ i, navigate }) {
  return (
    <Container title={`${i.title} 이력`}>
      <S.RowDiv $padding="8px 16px" $bgColor="gray50" $radius={6} $justify="space-between">
        <S.RowDiv $gap={4} $align="center">
          <C.Icon name="Point" $pathFill="primary" $circleStroke="primary" $width={18} $height={18} />
          <S.Text $type="label14" $color="gray700">
            {i.title}
          </S.Text>
        </S.RowDiv>
        <S.Text $color="gray900" $type="b24">
          {i.myStamp}&nbsp;
          <S.Span $color="gray900" $type="20">
            / {i.stamp}
          </S.Span>
        </S.Text>
      </S.RowDiv>
      <S.List $pTop={16}>
        {i.list.map((item, index) => (
          <S.RowListItem key={`item_${index}`} $padding="8px" $gap={16}>
            <C.Img src={item.img} $width={96} $height={72} $objectFit="cover" $radius={8} $overflow="hidden" />
            <S.ColDiv $justify="center" $flex={1}>
              <S.Text $whiteSpace="pre-wrap" $color={item.state === 0 ? 'gray500' : 'gray900'} $type="m">
                {item.name}
              </S.Text>
              {item.state === 1 && (
                <S.Text $color="gray500" $type="m12" $pTop={4}>
                  {item.createDate}
                </S.Text>
              )}
            </S.ColDiv>
            <C.Img src={item.state === 0 ? 'stampDisabled' : 'stamp'} $width={48} $height={72} $objectFit="cover" />
          </S.RowListItem>
        ))}
      </S.List>
    </Container>
  );
}
