import HttpRequest from '../HttpRequest';


export const createAccount = async (formData) => {
  const response = await HttpRequest.ajaxPost('/api/accounts', {}, formData, 'POST');
  return response;
};

export default {
  createAccount,
};