import React, { useEffect, useState } from 'react';
import * as D from 'data';
import * as C from 'components';
import { useTab } from 'hooks';
import { useLocation } from 'react-router-dom';

export function ChallengeRanking(props) {
  const location = useLocation();
  const item = location.state;
  const [tabIndex, currentTab] = useTab(0);
  const [list, setList] = useState();

  const myIndex = D.rankingList.findIndex((user) => user.userName === 'Emma Moore');

  useEffect(() => {
    let tempList;
    if (tabIndex === 0) {
      const topTenList = D.rankingList.slice(0, 10);
      const myPositionList = D.rankingList.slice(myIndex);
      tempList = [...topTenList, ' ', ...myPositionList];
    } else {
      tempList = D.rankingList;
    }

    setList(tempList);
  }, [tabIndex]);

  return (
    <C.ChallengeRankingBox item={item} tabIndex={tabIndex} list={list} myIndex={myIndex} currentTab={currentTab} />
  );
}
