import React, { useEffect, useState } from 'react';
import * as D from 'data';
import moment from 'moment';
import { useTab } from 'hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HistoryListView } from './HistoryListView';

export function HistoryList(props) {
  const initFilters = {
    period: 'sixMonth',
    startDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [list, setList] = useState();
  const [tabIndex, currentTab] = useTab(parseInt(searchParams.get('tab'), 10) || 0);
  const [filters, setFilters] = useState(initFilters);

  const onChange = (name, val) => {
    if (name === 'period') {
      let startDate = '';
      let endDate = moment().format('YYYY-MM-DD');

      if (val === 'sixMonth') {
        startDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
      } else if (val === '1year') {
        startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
      } else if (val === '2year') {
        startDate = moment().subtract(2, 'years').format('YYYY-MM-DD');
      } else if (val === 'direct') {
        startDate = '';
        endDate = moment().format('YYYY-MM-DD');
      }

      setFilters({
        ...filters,
        period: val,
        startDate,
        endDate,
      });
    } else if (name === 'startDate' || name === 'endDate') {
      setFilters({
        ...filters,
        [name]: moment(val).format('YYYY-MM-DD'),
      });
    }
  };
  useEffect(() => {
    setFilters(initFilters);

    if (tabIndex === 0) {
      setList(D.historyPointList);
    } else {
      setList(D.historyDonationList);
    }
  }, [tabIndex]);

  useEffect(() => {
    setSearchParams({ tab: tabIndex, ...filters }, { replace: true });
  }, [tabIndex, filters, setSearchParams]);

  return (
    <HistoryListView
      list={list}
      navigate={navigate}
      tabIndex={tabIndex}
      currentTab={currentTab}
      onChange={onChange}
      filters={filters}
    />
  );
}
