import React from 'react';
import styled from 'styled-components';
import { Div, List, ListItem, Text, theme } from 'styles';

export function Tab({ list, tabIndex, currentTab }) {
  return (
    <Div $position="relative">
      <TabSpan $tabIndex={tabIndex} $tabCount={list?.length} />
      <TabList>
        {list?.map((tab, index) => (
          <TabListItem key={`tab_${index}`} onClick={() => currentTab(index)}>
            <Text $type="m14" $color={tabIndex === index ? 'gray800' : 'gray600'}>
              {tab}
            </Text>
          </TabListItem>
        ))}
      </TabList>
    </Div>
  );
}

const TabList = styled(List)`
  display: flex;
  border-radius: 100px;
  padding: 3px;
  background-color: ${theme.colors.gray50};
  border: 1px solid ${theme.colors.gray100};
`;

const TabListItem = styled(ListItem)`
  flex: 5;
  padding: 9.5px 0;

  p {
    position: relative;
    z-index: 5;
    text-align: center;
  }
`;

const TabSpan = styled.span`
  position: absolute;
  width: ${({ $tabCount }) => `calc(${100 / $tabCount}% - 8px)`};
  height: 40px;
  top: 4px;
  left: ${({ $tabIndex, $tabCount }) => `calc((${100 / $tabCount}%) * ${$tabIndex} + 4px)`};
  background-color: white;
  border-radius: 100px;
  box-shadow: ${theme.colors.lineShadow};
  transition: left 150ms cubic-bezier(0.39, 0.575, 0.565, 1);
`;
