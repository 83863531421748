import { css } from 'styled-components';
import { checkPixel } from 'utils';
import { theme } from './theme';

const apply = (prop, value) => (value !== undefined && value !== null ? `${prop}: ${value};` : '');

// common
export const common = css`
  ${({ $display, $overflow, $overflowY, $visibility, $transform, $cursor, $transition, $shadow, $bgColor }) => css`
    ${apply('display', $display)}
    ${apply('overflow', $overflow)}
    ${apply('overflow-y', $overflowY)}
    ${apply('visibility', $visibility)}
    ${apply('transform', $transform)}
    ${apply('cursor', $cursor)}
    ${apply('transition', $transition)}
    ${apply('box-shadow', theme.colors[$shadow])}
    ${apply('background-color', theme.colors[$bgColor] || $bgColor)}


   
    &::-webkit-scrollbar {
      display: none;
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }
  `}
`;

// flex
export const flex = css`
  ${({ $flex, $flexGrow, $wrap, $direction, $justify, $align, $order, $gap, $rowGap, $colGap }) => css`
    ${apply('flex', $flex)}
    ${apply('flex-grow', $flexGrow)}
    ${apply('flex-wrap', $wrap)}
    ${apply('flex-direction', $direction)}
    ${apply('justify-content', $justify)}
    ${apply('align-items', $align)}
    ${apply('order', $order)}
    ${apply('gap', checkPixel($gap))}
    ${apply('row-gap', checkPixel($rowGap))}
    ${apply('column-gap', checkPixel($colGap))}
  `}
`;

// spacing
export const spacing = css`
  ${({
    $padding,
    $pTop,
    $pRight,
    $pBottom,
    $pLeft,
    $margin,
    $mTop,
    $mRight,
    $mBottom,
    $mLeft,
    $position,
    $top,
    $right,
    $bottom,
    $left,
    $zIndex,
    $width,
    $maxWidth,
    $minWidth,
    $height,
    $maxHeight,
    $minHeight,
  }) => css`
    ${apply('padding', checkPixel($padding))}
    ${apply('padding-top', checkPixel($pTop))}
    ${apply('padding-right', checkPixel($pRight))}
    ${apply('padding-bottom', checkPixel($pBottom))}
    ${apply('padding-left', checkPixel($pLeft))}
    ${apply('margin', checkPixel($margin))}
    ${apply('margin-top', checkPixel($mTop))}
    ${apply('margin-right', checkPixel($mRight))}
    ${apply('margin-bottom', checkPixel($mBottom))}
    ${apply('margin-left', checkPixel($mLeft))}
    ${apply('position', $position)}
    ${apply('top', checkPixel($top))}
    ${apply('right', checkPixel($right))}
    ${apply('bottom', checkPixel($bottom))}
    ${apply('left', checkPixel($left))}
    ${apply('z-index', $zIndex)}
    ${apply('width', checkPixel($width))}
    ${apply('max-width', checkPixel($maxWidth))}
    ${apply('min-width', checkPixel($minWidth))}
    ${apply('height', checkPixel($height))}
    ${apply('max-height', checkPixel($maxHeight))}
    ${apply('min-height', checkPixel($minHeight))}
  `}
`;

// typography
const getTypography = ($type = 'r') => {
  const weights = { r: 400, m: 500, b: 700 };
  const match = $type.match(/([a-z]*)(\d+)?/i);
  const type = match[1] || 'r';
  const size = parseInt(match[2], 10) || 16;

  const weight = weights[type] || 400;
  const lineHeight = type === 'label' || size >= 38 ? 1.2 : type === 'btn' ? 1 : 1.5;

  return `font-weight: ${weight}; font-size: ${size}px; line-height: ${lineHeight};`;
};

export const typography = css`
  ${({ $type, $color, $wordBreak, $whiteSpace, $textAlign, $maxLines, $fontWeight, $lineHeight }) => css`
    ${getTypography($type)}
    ${apply('color', theme.colors[$color] || $color || theme.colors.normal)}
    ${apply('word-break', $wordBreak)}
    ${apply('white-space', $whiteSpace)}
    ${apply('text-align', $textAlign)}
    ${$maxLines &&
    `overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: ${$maxLines};`}
    ${apply('font-weight', $fontWeight)}
    ${apply('line-height', $lineHeight)}
  `}
`;

// border
const getBorderStyle = ({ width = 1, style = 'solid', color = 'gray200' }, theme) =>
  `${checkPixel(width)} ${style} ${theme.colors[color] || color}`;

export const border = css`
  ${({ $radius, $border, $borderTop, $borderRight, $borderBottom, $borderLeft, theme }) => css`
    ${apply('border-radius', checkPixel($radius))}
    ${$border && apply('border', getBorderStyle($border, theme))}
    ${$borderTop && apply('border-top', getBorderStyle($borderTop, theme))}
    ${$borderRight && apply('border-right', getBorderStyle($borderRight, theme))}
    ${$borderBottom && apply('border-bottom', getBorderStyle($borderBottom, theme))}
    ${$borderLeft && apply('border-left', getBorderStyle($borderLeft, theme))}
  `}
`;
