import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Button } from 'components/button';
import { BottomSheet } from 'components/dialogs';
import { Div, RowDiv, Span, Text, theme } from 'styles';
import { Icon } from './Icon';
import { CustomDatePicker } from './CustomDatePicker';

export function Date({ filters, disabled, name, value, onChange, ...props }) {
  const [open, setOpen] = useState();

  return (
    <>
      <DateContainer {...props}>
        <DateDiv onClick={() => !disabled && setOpen('startDate')}>
          <Text $type="m14" $color={disabled ? 'disabled' : 'gray700'}>
            {filters?.startDate}
          </Text>
          <Icon name="Calendar" $pathFill={!disabled && 'supportive'} $pathOpacity={!disabled && '1'} />
        </DateDiv>
        <Span>-</Span>
        <DateDiv onClick={() => !disabled && setOpen('endDate')}>
          <Text $type="m14" $color={disabled ? 'disabled' : 'gray700'}>
            {moment(filters?.endDate).format('YYYY-MM-DD')}
          </Text>
          <Icon name="Calendar" $pathFill={!disabled && 'supportive'} $pathOpacity={!disabled && '1'} />
        </DateDiv>
        <Button $size="m" $width={60} disabled={disabled} onClick={() => alert('조회')}>
          조회
        </Button>
      </DateContainer>
      <BottomSheet open={open} setOpen={setOpen}>
        <Div $padding="0 20px 20px">
          <Text $type="m14" $color="gray500">
            날짜 선택
          </Text>
          <Div $padding="16px 0">
            <CustomDatePicker
              name={open}
              value={open === 'startDate' ? filters.startDate : filters.endDate}
              onChange={onChange}
              minDate={filters.startDate}
              maxDate={filters.endDate}
            />
          </Div>
          <RowDiv $gap={4}>
            <Button
              $size="l"
              $type="sub"
              onClick={() => {
                onChange(open, undefined);
                setOpen('');
              }}
            >
              취소
            </Button>
            <Button $size="l" onClick={() => setOpen('')}>
              선택
            </Button>
          </RowDiv>
        </Div>
      </BottomSheet>
    </>
  );
}

const DateContainer = styled(RowDiv)`
  align-items: center;
  gap: 4px;
`;

const DateDiv = styled(RowDiv)`
  align-items: center;
  justify-content: space-between;
  flex: 1;
  gap: 8px;
  border-radius: 8px;
  padding: 8.5px 8px;
  background-color: ${theme.colors.gray50};
  border: 1px solid ${theme.colors.gray100};

  p {
    flex: 1;
    text-align: center;
  }
`;
