import React from 'react';
import * as C from 'components';
import * as S from 'styles';
import styled from 'styled-components';
import { HomeTitle } from './HomeTitle';

export function HomePath(props) {
  return (
    <S.Section $padding="20px">
      <HomeTitle icon="Map" onClick={() => alert('나의경로 더보기')}>
        나의경로
      </HomeTitle>
      <S.LineDiv $padding="0" $mTop={16} $shadow>
        <S.Div $padding="8px 16px" $borderBottom>
          <S.Text>모닝 출근</S.Text>
        </S.Div>
        <InfoDiv>
          <div>
            <S.Text $type="14">시간</S.Text>
            <S.Text $type="b18">00:15</S.Text>
          </div>
          <div>
            <S.Text $type="14">이동거리</S.Text>
            <S.Text $type="b18">0.9km</S.Text>
          </div>
          <div>
            <S.Text $type="14">소모칼로리</S.Text>
            <S.Text $type="b18">80kcal</S.Text>
          </div>
        </InfoDiv>
        <S.Div $padding="8px 16px 16px">
          <C.Img src="mapSample" $radius={8} $overflow="hidden" />
        </S.Div>
      </S.LineDiv>
    </S.Section>
  );
}

const InfoDiv = styled(S.RowDiv)`
  padding: 8px 0;
  gap: 4px;

  div {
    padding: 8px;
    flex: 3.33;
  }
  p {
    text-align: center;
    color: ${S.theme.colors.gray900};
  }
`;
