import styled, { css } from 'styled-components';
import { theme } from 'styles';

const types = {
  default: css`
    border: 1px solid ${theme.colors.gray200};
    background-color: #ffffff;
  `,
  disabled: css`
    background-color: white;
    border: 1px solid ${theme.colors.fill05};
    cursor: not-allowed;
  `,
  error: css`
    border: 1px solid ${theme.colors.alert};
    background-color: #ffffff;
    box-shadow: inset 0px 0px 0px 3px rgba(225, 44, 37, 0.2);
  `,
};

export const DropDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${theme.colors.gray200};
  border-radius: 8px;
  padding: 7px 12px;
  ${({ $disabled, $invalid }) => ($disabled ? types.disabled : $invalid === 'error' ? types.error : types.default)}
  ${({ $disabled }) => $disabled && `cursor: not-allowed;`}
`;

export const ConDiv = styled.div`
  padding: 0 20px;
`;

export const BottomSheet = styled.div`
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: ${({ $open }) => ($open ? '0' : '-100%')};
  background-color: white;
  border-radius: 28px 28px 0 0;
  transition: bottom 350ms linear;
  z-index: 100;
`;

export const Handle = styled.div`
  width: 32px;
  height: 4px;
  margin: 16px auto;
  background-color: ${theme.colors.dark};
  border-radius: 100px;
`;

export const List = styled.ul`
  margin-top: 16px;
  padding-bottom: 20px;
  overflow-y: auto;
  max-height: 280px;
`;

export const ListItem = styled.li`
  width: 100%;
  padding: 8px 0;
  cursor: pointer;

  background-color: ${({ $selected }) => ($selected ? theme.colors.gray100 : 'transparent')};

  &:hover {
    background-color: ${theme.colors.gray200};
  }
`;
