import React, { useRef } from 'react';
import { Container } from 'layouts';
import * as S from 'styles';
import * as C from 'components';
import * as htmlToImage from 'html-to-image';

export function HistoryDetailView({ item, toast, setToast }) {
  const captureRef = useRef();

  const saveImg = () => {
    if (captureRef.current) {
      htmlToImage
        .toPng(captureRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'donation-detail.png';
          link.href = dataUrl;
          link.click();

          setToast('success');
        })
        .catch((error) => {
          setToast('error');
        });
    }
  };

  return (
    <>
      <Container title="기부 내용" $padding="0">
        <S.Div $height="calc(100% - 78px)">
          <S.Div ref={captureRef} $padding="20px">
            <S.Div $bgColor="gray50" $radius={10} $overflow="hidden" $shadow="lineShadow">
              <S.RowCenterDiv $padding="68px 0" $bgColor="gray50">
                <C.Img src="donation" alt="기부 이미지" $width={128} />
                <S.Div>
                  <S.Text $textAlign="center" $type="label12" $color="gray700">
                    기부 포인트
                  </S.Text>
                  <S.Text $type="b24" $color="gray900" $padding="12px 16px 4px">
                    {item.usePoint} P
                  </S.Text>
                </S.Div>
              </S.RowCenterDiv>
              <S.Div $padding="16px 0 20px" $bgColor="white">
                <S.Text $pTop={4} $textAlign="center" $type="b20" $color="gray900">
                  {item.name}
                </S.Text>
                <S.Text $textAlign="center" $type="b" $color="gray800">
                  기부에 동참하였습니다!
                </S.Text>
                <S.Text $padding="24px 0 8px" $textAlign="center" $type="label12" $color="gray800" $fontWeight={500}>
                  <S.Span $type="label12" $color="gray600" $fontWeight={500} $pRight={4}>
                    기부일자
                  </S.Span>
                  {item.createDate}
                </S.Text>
              </S.Div>
            </S.Div>
          </S.Div>
        </S.Div>
        <S.Div $padding="8px 20px 20px" onClick={saveImg}>
          <C.Button $size="l" type="sub">
            이미지로 저장
          </C.Button>
        </S.Div>
      </Container>
      {toast && (
        <C.Toast setToast={setToast}>
          {toast === 'success' && <>이미지 저장이 완료되었습니다.</>}
          {toast === 'error' && <>다시 시도해 주세요.</>}
        </C.Toast>
      )}
    </>
  );
}
