import React from 'react';
import styled from 'styled-components';
import { Span, theme } from 'styles';

export function InputLabel({ label, desc, required, $disabled }) {
  return (
    <Label $disabled={$disabled}>
      {label}
      {desc && (
        <Span $type="m12" $color="supportive">
          {desc}
        </Span>
      )}
      {required && (
        <Span $type="12" $color="alert">
          필수
        </Span>
      )}
    </Label>
  );
}

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-bottom: 4px;
  font-size: 16px;
  line-height: 1.5;
  color: ${({ $disabled }) => ($disabled ? theme.colors.disabled : theme.colors.assistive)};
`;
