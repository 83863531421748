import React from 'react';
import * as C from 'components';
import { Container } from 'layouts';
import { useSubMenu } from 'hooks';

export function CenterHome(props) {
  const subMenu = useSubMenu();

  return (
    <Container title="고객센터">
      <C.SubMenuList list={subMenu} />
    </Container>
  );
}
