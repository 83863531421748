import { useEffect, useState } from 'react';
import { Input } from './Input';

export function FormikInput({ invalid, showValid, touched, ...props }) {
  const [invalidValue, setInvalidValue] = useState(null);

  useEffect(() => {
    if (touched && invalid && invalid.length > 0) {
      setInvalidValue('error');
    } else if (touched && showValid && !invalid) {
      setInvalidValue('success');
    } else {
      setInvalidValue(null);
    }
  }, [invalid, showValid, touched]);

  return (
    <Input
      $invalid={touched && invalid && invalid.length > 0 ? {type: 'error', text: invalid} : undefined}
      {...props}
    />
  );
}
