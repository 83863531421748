import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ko } from 'date-fns/locale';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Div, RowDiv, Text, theme } from 'styles';
import { Icon } from './Icon';

export function CustomDatePicker({ name, value, onChange, minDate, maxDate }) {
  const [selectedDate, setSelectedDate] = useState(value ? new Date(value) : undefined);

  useEffect(() => {
    if (value) {
      setSelectedDate(new Date(value));
    }
  }, [value]);

  const selectedOnChange = (date) => {
    setSelectedDate(moment(date).format('YYYY-MM-DD'));
    onChange(name, date);
  };

  return (
    <Container>
      <DatePicker
        selected={selectedDate}
        onChange={selectedOnChange}
        dateFormat="yyyy년 M월"
        locale={ko}
        inline
        minDate={minDate}
        maxDate={maxDate}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <RowDiv $pBottom={8}>
            <Icon
              name="FormArrow"
              $transform="rotate(90deg)"
              $width={24}
              $height={24}
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            />
            <Div $flex={1}>
              <Text $color="gray700">
                {date.getFullYear()}년 {date.getMonth() + 1}월
              </Text>
            </Div>
            <Icon
              name="FormArrow"
              $transform="rotate(-90deg)"
              $width={24}
              $height={24}
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            />
          </RowDiv>
        )}
      />
    </Container>
  );
}

const Container = styled(Div)`
  * {
    margin: 0;
  }
  .react-datepicker {
    font-family: 'Pretendard', sans-serif;
    font-size: 16px;
    border: none;
    width: 100%;
  }
  .react-datepicker__header {
    background-color: white;
    border-radius: 0;
    padding: 0;
    border-color: ${theme.colors.gray200};
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
  }
  .react-datepicker__day-name {
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    color: ${theme.colors.gray400};
    flex: 1;
  }

  .react-datepicker__day {
    color: ${theme.colors.gray700};
    border-radius: 6px;
    flex: 1;
    margin: 5px;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__month-container {
    float: unset;
    width: 100%;
  }

  .react-datepicker__day--today {
    background-color: ${theme.colors.fill02};
    color: ${theme.colors.gray700};
  }

  .react-datepicker__day--selected {
    background-color: ${theme.colors.primary};
    color: white;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: white;
  }

  .react-datepicker__day--disabled {
    color: ${theme.colors.disabled};
  }

  .react-datepicker__day--outside-month {
    color: ${theme.colors.disabled};
  }
`;
