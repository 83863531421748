import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import styled from 'styled-components';

export function DonationDetailView({ item, values, onChange, invalids, submit }) {
  return (
    <Container title={item.name} $padding="0">
      <S.Div $padding="20px">
        <MyPointDiv>
          <S.RowDiv $align="center">
            <C.Icon name="Point" $width={16} $height={16} />
            <S.Text $type="m14" $color="gray700" $pLeft={4}>
              나의 사용 가능 포인트
            </S.Text>
          </S.RowDiv>
          <S.Text $type="b24" $color="gray900" $minWidth={140} $textAlign="end">
            {values.point.toLocaleString()}
            <S.Span $type="20" $color="gray900">
              P
            </S.Span>
          </S.Text>
        </MyPointDiv>
        <S.Div $margin="16px 0" $position="relative" $radius={10} $overflow="hidden">
          <C.Badge $position="absolute" $left={8} $top={8} $zIndex={1} $bgColor={item.state === 0 && 'gray400'} icon>
            {item.state === 0 ? '참여가능' : '참여중'}
          </C.Badge>
          <C.Img src={item.img} $objectFit="cover" $height={138} />
        </S.Div>
        <S.Div>
          <S.Text $type="b20" $color="gray900">
            {item.name}
          </S.Text>
          <S.RowDiv $gap={8} $padding="4px 0">
            <S.Text $type="b14" $color="gray500">
              기부기간
            </S.Text>
            <S.Text $type="b14" $color="gray500">
              {item.startDate} ~ {item.endDate}
            </S.Text>
          </S.RowDiv>
          <S.Text $whiteSpace="pre-wrap" $color="gray800">
            {item.con}
          </S.Text>
          <S.Div $pTop={20}>
            <S.Text $type="b24" $color="secondary700">
              {(item.currentPoint / item.targetPoint) * 100}%
            </S.Text>
            <C.ProgressBar $mTop={4} $height={8} $percentage={(item.currentPoint / item.targetPoint) * 100} />
            <S.Text $pTop={8} $type="m14" $color="gray500">
              현재 포인트
            </S.Text>
            <S.Text $type="b24" $color="gray900">
              {item.currentPoint.toLocaleString()} P
            </S.Text>
            <S.RowDiv $pTop={8} $gap={16}>
              <S.Div>
                <S.Text $type="m14" $color="gray500">
                  목표 포인트
                </S.Text>
                <S.Text $type="m18" $color="gray900">
                  {item.targetPoint.toLocaleString()} P
                </S.Text>
              </S.Div>
              <C.Line $height="100%" />
              <S.Div>
                <S.Text $type="m14" $color="gray500">
                  총 참여자
                </S.Text>
                <S.Text $type="m18" $color="gray900">
                  {item.contestants.toLocaleString()} 명
                </S.Text>
              </S.Div>
            </S.RowDiv>
          </S.Div>
        </S.Div>
      </S.Div>
      <S.Div $width="100vw" $height={8} $bgColor="fill03" />
      <S.Div $padding="16px 20px 20px">
        <S.Text $type="b14" $color="gray500">
          참여 방법
        </S.Text>
        <S.Text $type="14" $color="gray700" $padding="4px 8px 8px">
          1. 나눔 포인트에 기부하실 포인트를 입력해 주세요.
          <br />
          2. 포인트 입력 후 기부하기 버튼을 누르면 끝!
        </S.Text>
        <S.Div $mTop={16}>
          <IconDiv>
            <C.Icon name="Point" />
            <S.Text $type="m18" $color="gray900">
              나눔 포인트
            </S.Text>
          </IconDiv>
          <C.Input
            name="donation"
            $invalid={invalids.donation}
            value={values.donation.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            onChange={onChange}
            placeholder="숫자 입력"
            type="tel"
            $height={50}
            $margin="8px 0 20px"
          />
          <C.Button $size="l" onClick={submit}>
            기부하기
          </C.Button>
        </S.Div>
      </S.Div>
    </Container>
  );
}

const MyPointDiv = styled(S.RowDiv)`
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 6px;
  background-color: ${S.theme.colors.gray50};

  svg > path {
    fill: ${S.theme.colors.primary};
  }
  svg > circle {
    stroke: ${S.theme.colors.primary};
  }
`;

const IconDiv = styled(S.RowDiv)`
  align-items: center;
  gap: 4px;

  svg {
    width: 24px;
    height: 24px;
  }

  svg > path {
    fill: ${S.theme.colors.primary};
  }
  svg > circle {
    stroke: ${S.theme.colors.primary};
  }
`;
