import React from 'react';
import styled from 'styled-components';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from './header/Header';

export function Layout(props) {
  const location = useLocation();
  const show = ['/', '/point', '/challenge', `/community`].some((path) => location.pathname === path);

  return (
    <>
      <h1>걷쥬</h1>
      <Main $hide={!show}>
        <Outlet />
      </Main>
      {show && <Header />}
    </>
  );
}

const Main = styled.main`
  height: ${({ $hide }) => (!$hide ? 'calc(100svh - 64px)' : '100svh')};
`;
