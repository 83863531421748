import React from 'react';
import * as S from 'styles';
import { HomeBar } from 'layouts';
import { HomePath, Walk, HomeNotice, HomeCommunity } from 'components/page';

export function HomeView({ count, max, percentage, navigate, notice }) {
  return (
    <>
      <HomeBar />
      <S.Section $overflow="auto" $height="calc(100% - 62px)">
        <S.H2 $display="none">home</S.H2>
        <Walk count={count} max={max} percentage={percentage} />
        <S.Div $width="100vw" $height={8} $bgColor="fill03" />
        <HomePath navigate={navigate} />
        <HomeNotice notice={notice} navigate={navigate} />
        <HomeCommunity navigate={navigate} />
      </S.Section>
    </>
  );
}
