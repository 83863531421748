import React, { useEffect } from 'react';
import { Text } from 'styles';
import * as S from './ToastStyle';

export function Toast({ children, setToast }) {
  useEffect(() => {
    const toastOpen = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };

    document.addEventListener('click', toastOpen, true);
    document.addEventListener('touchstart', toastOpen, { passive: false });

    const timer = setTimeout(() => {
      setToast(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
      document.removeEventListener('click', toastOpen, true);
      document.removeEventListener('touchstart', toastOpen, { passive: false });
    };
  }, [setToast]);

  return (
    <S.ToastContainer>
      <Text $color="white" $type="14">
        {children}
      </Text>
    </S.ToastContainer>
  );
}
