import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StampHistoryDetailView } from './StampHistoryDetailView';

export function StampHistoryDetail(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const i = location.state;

  return <StampHistoryDetailView i={i} navigate={navigate} />;
}
