import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import styled from 'styled-components';

export function Walk({ count, max, percentage }) {
  return (
    <S.Section $padding="20px">
      <S.H3 $display="none">나의 걸음 수</S.H3>
      <S.LineDiv>
        <S.RowDiv $justify="space-between">
          <S.Text $color="gray500" $type="m14">
            나의 걸음 수 / 목표 걸음 수
          </S.Text>
          <S.RowCenterDiv $gap={2} $padding="3px 0" onClick={() => alert('걸음수 동기화')}>
            <S.Text $color="primary" $type="m12">
              걸음수 동기화
            </S.Text>
            <C.Icon name="Sync" />
          </S.RowCenterDiv>
        </S.RowDiv>
        <S.Div $padding="8px 0">
          <S.RowDiv $justify="space-between" $align="center">
            <S.RowDiv $align="center" $gap={4}>
              <S.Span $type="b32" $color="gray900" $lineHeight="38px">
                {count.toLocaleString()}
              </S.Span>
              <S.Text $type="b14" $color="gray900">
                걸음
              </S.Text>
            </S.RowDiv>
            <S.Text $type="b14" $color="gray600">
              / {max}
            </S.Text>
          </S.RowDiv>
          <C.ProgressBar $percentage={percentage} />
        </S.Div>
        <WalkInfoDiv>
          <S.Text>00:40</S.Text>
          <C.Line $height={10} />
          <S.Text>1.25km</S.Text>
          <C.Line $height={10} />
          <S.Text>120kcal</S.Text>
          <C.Line />
        </WalkInfoDiv>
      </S.LineDiv>
    </S.Section>
  );
}

const WalkInfoDiv = styled(S.RowDiv)`
  p {
    color: ${S.theme.colors.gray800};
    width: 33.33%;
    text-align: center;
    padding: 8px 4px;
  }
`;
