import React from 'react';
import { headerMenu } from 'data/menuData';
import { Icon } from 'components';
import * as S from './HeaderStyle';

export function Header(props) {
  return (
    <S.Header>
      <nav>
        <h2>네비게이션 메뉴</h2>
        <S.MenuList>
          {headerMenu.map((menu, index) => (
            <S.MenuItem key={`menu_${index}`}>
              <S.CustomNavLink to={menu.path} $second={index === 1}>
                <div>
                  <Icon name={menu.icon} />
                </div>
                <h3>{menu.name}</h3>
              </S.CustomNavLink>
            </S.MenuItem>
          ))}
        </S.MenuList>
      </nav>
    </S.Header>
  );
}
