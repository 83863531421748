import { create } from 'zustand'

const userAuthenticationStore = create((set) => ({
  isAuthenticated: false,
  userInfo: null,

  setAuthenticated: (isAuthenticated) => set(() => ({ isAuthenticated })),
  setUserInfo: (userInfo) => set(() => ({ userInfo })),
}))

export default userAuthenticationStore;