import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PromoDetailView } from './PromoDetailView';

export function PromoDetail(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;

  return <PromoDetailView item={item} navigate={navigate} />;
}
