import React, { useState, useRef } from 'react';
import { Dim } from 'components/dialogs/Dim';
import * as S from './BottomSheetStyle';

export function BottomSheet({ open, setOpen, children }) {
  const dropRef = useRef();
  const [touchPosition, setTouchPosition] = useState({ startY: 0, currentY: 0 });

  const handleTouchStart = (e) => {
    setTouchPosition((prev) => ({ ...prev, startY: e.touches[0].clientY }));
  };

  const handleTouchMove = (e) => {
    setTouchPosition((prev) => ({ ...prev, currentY: e.touches[0].clientY }));
  };

  const handleTouchEnd = () => {
    if (touchPosition.currentY - touchPosition.startY > 50) {
      setOpen(false);
    }
  };

  return (
    <>
      <Dim open={open} />
      <S.BottomSheet $open={open}>
        <S.HandleWrap
          ref={dropRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <S.Handle />
        </S.HandleWrap>
        {children}
      </S.BottomSheet>
    </>
  );
}
