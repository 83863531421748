import { createGlobalStyle } from 'styled-components';
import 'pretendard/dist/web/static/pretendard.css';
import { theme } from './theme';

export const GlobalStyles = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing:border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, #root {
  height: 100%;
}

body {
  font-family: 'Pretendard', sans-serif;
  font-size: 16px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
}

abbr[title] {
  text-decoration: none;
}
  
nav, section {
  display: block;
}

ol, ul, li {
  list-style: none;
}


a {
  display: inline-block;
  text-decoration: none;
  color: initial;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}


input, textarea, button {
  outline: none;
  font-family: inherit; 
}

input[disabled]{
   color: ${theme.colors.disabled};
}

:focus-visible {
    outline: none;
}

textarea {
  resize:none;
}


h1 {
  display:none;
}

button{
  background-color:transparent;
}

`;
