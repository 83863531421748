/* eslint-disable no-undef */

import React, { useEffect, useState } from 'react';
import { useCookie } from 'hooks';
import { useSearchParams, useNavigate } from "react-router-dom";
import AuthenticationService from 'services/authentication/AuthenticationService';
import userAuthenticationStore from 'stores/authentication/AuthenticationStore';


import { ACCESS_TOKEN, REFRESH_TOKEN } from 'data/constants';

export function KaKaoCallback(props) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setCookies } = useCookie();

  const setAuthenticated = userAuthenticationStore((state) => state.setAuthenticated);
  const setUserInfo = userAuthenticationStore((state) => state.setUserInfo);
  const code = searchParams.get("code");

  useEffect(() => {

    if(code){
      AuthenticationService.getKakaoAccessToken(code).then((response)=>{
        if(response){
          Kakao.Auth.setAccessToken(response.accessToken);
          Kakao.API.request({
            url: '/v2/user/me',
          })
            .then((res) =>{
              const userId = "KAKAO_" + res.id;
              const {email} = res.kakao_account;
              const {nickname} = res.kakao_account.profile;
              console.log(`email ${email}, nickname: ${nickname}`);
              AuthenticationService.login(userId, userId).then((response)=>{
                if(response && response.access_token){
                  setCookies(ACCESS_TOKEN, response.access_token);
                  localStorage.setItem(ACCESS_TOKEN, response.access_token);
                  localStorage.setItem(REFRESH_TOKEN, response.refresh_token);

                  setAuthenticated(true);

                  AuthenticationService.getMyInfo().then((account)=>{
                    setUserInfo(account);
                  });

                  navigate('/');
                }else{
                  const params = {userId, email, nickname: nickname};
                  navigate('/join', {state: params});
                }
              });

            }).catch((err)=> {
            alert(
              'failed to request user information: ' + JSON.stringify(err)
            );
            navigate('/login');
          });

        }
      });
    }else{
      const errorDescription = searchParams.get("error_description");
      const error = searchParams.get("error");
      alert(errorDescription);
      navigate('/login');
    }


  }, []);

  return (
    <></>
  );
}
