import {ACCESS_TOKEN, REFRESH_TOKEN} from "data/constants";


const getMyInfo = async () => {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/oauth/me`;

  try {
    let authorizationValue = '';
    if (localStorage.getItem(ACCESS_TOKEN)) {
      authorizationValue = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: authorizationValue,
        }
      });

      const jsonResponse = await response.json();

      if (!jsonResponse) {
        return null;
      } else {
        return jsonResponse;
      }
    }else{
      return null;
    }


  } catch (e) {
    console.error(e);
  }
};

const login = async (username, password) => {
  const oauthUrl = `${process.env.REACT_APP_API_DOMAIN}/oauth/token`;
  const data = new URLSearchParams();
  data.append("grant_type", "password");
  data.append("username", username);
  data.append("password", password);

  try {
    const response = await fetch(oauthUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Basic bXlBcHA6cGFzcw==',
      },
      body: data
    });

    if(response.status === 200){
      const jsonResponse = await response.json();
      return jsonResponse;
    }else{
      return null;
    }

  } catch (e) {
    console.error(e);
  }

};

const getKakaoAccessToken = async (code) => {
  const oauthUrl = `${process.env.REACT_APP_API_DOMAIN}/api/kakao/getAccessToken`;
  const data = new URLSearchParams();
  data.append("code", code);

  try {
    const response = await fetch(oauthUrl, {
      method: 'POST',
      body: data
    });

    if(response.status === 200){
      const jsonResponse = await response.json();
      return jsonResponse;
    }else{
      return null;
    }

  } catch (e) {
    console.error(e);
  }

};


const logout = async () => {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/oauth/logout`;

  try {
    let accessToken = '';
    if (localStorage.getItem(ACCESS_TOKEN)) {
      accessToken = `${localStorage.getItem(ACCESS_TOKEN)}`;
    }

    let refreshToken = '';
    if (localStorage.getItem(REFRESH_TOKEN)) {
      refreshToken = `${localStorage.getItem(REFRESH_TOKEN)}`;
    }

    const data = new URLSearchParams();
    data.append("access_token", accessToken);
    data.append("refresh_token", refreshToken);

    const response = await fetch(url, {
      method: 'POST',
      body: data
    });


    return response.status === 200;
  } catch (e) {
    console.error(e);
  }

};


export default {
  login,
  logout,
  getKakaoAccessToken,
  getMyInfo
};
