import React from 'react';
import { RowDiv, Text } from 'styles';
import { Icon } from './Icon';

export function Badge({ $bgColor, children, icon, ...props }) {
  return (
    <RowDiv
      $align="center"
      $width="fit-content"
      $bgColor={$bgColor || 'primary'}
      $padding={icon ? '3px 12px 3px 8px' : '4px 8px'}
      $radius={100}
      {...props}
    >
      {icon && <Icon name={icon} />}
      <Text $color="white" $type="12" $lineHeight="14px">
        {children}
      </Text>
    </RowDiv>
  );
}
