import { useState, useEffect } from 'react';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,16}$/;
const pwText = `8~16자의 영문 대/소문자, 숫자, 특수기호 조합 사용`;
const checkPwText = `비밀번호를 동일하게 다시 입력해 주세요.`;

export function usePassword(password, checkPassword) {
  const [passwordInvalids, setPasswordInvalids] = useState({
    password: { type: 'helper', text: pwText },
    checkPassword: { type: 'helper', text: checkPwText },
  });

  useEffect(() => {
    const tempInvalids = { ...passwordInvalids };

    tempInvalids.password = passwordRegex.test(password)
      ? { type: 'helper', text: pwText }
      : { type: password ? 'error' : '', text: pwText };

    tempInvalids.checkPassword =
      checkPassword === '' || password === checkPassword
        ? { type: 'helper', text: checkPwText }
        : { type: 'error', text: '비밀번호가 일치하지 않습니다.' };

    setPasswordInvalids(tempInvalids);
  }, [password, checkPassword]);

  const checkedValid = passwordRegex.test(password) && password === checkPassword;

  return { passwordInvalids, checkedValid };
}
