import React from 'react';
import * as S from 'styles';
import { Container } from 'layouts';
import { useLocation } from 'react-router-dom';

export function StampInfo(props) {
  const location = useLocation();
  const item = location.state;

  return (
    <Container title={`${item.title} 규칙`}>
      <S.Text $type="b14" $color="gray800">
        진행기간
      </S.Text>
      <S.Text $padding="0 8px">{item.period}</S.Text>
      <S.Div $padding="16px 0">
        <S.Text $type="b14" $color="gray800">
          스탬프 이벤트
        </S.Text>
        <S.Div $padding="4px 8px">
          <S.List>
            {item?.event.map((e, idx) => (
              <S.ListItem key={`event_${idx}`}>
                <S.RowDiv>
                  <S.Text $color="gray800">{idx + 1}.&nbsp;</S.Text>
                  <S.Text $color="gray800" $whiteSpace="pre-wrap">
                    {e}
                  </S.Text>
                </S.RowDiv>
              </S.ListItem>
            ))}
          </S.List>
          <S.Text $pTop={24} $color="gray800" $whiteSpace="pre-wrap">
            {item.info}
          </S.Text>
        </S.Div>
        <S.Text $pTop={16} $type="b14" $color="gray800">
          추첨 결과 포인트 지급
        </S.Text>
        <S.Text $pTop={4} $color="gray800">
          {item.point}
        </S.Text>
        <S.Text $pTop={16} $type="b14" $color="gray800">
          추첨 인원
        </S.Text>
        <S.Text $pTop={4} $color="gray800">
          {item.prize}
        </S.Text>
      </S.Div>
    </Container>
  );
}
