import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { sideMenu } from 'data/menuData';

export function useSubMenu() {
  const location = useLocation();
  const [subMenu, setSubMenu] = useState(null);

  useEffect(() => {
    const path = location.pathname.split('/');
    const last = path[path.length - 1];

    const currentMenu = sideMenu.find((menu) => last === menu.path);

    if (currentMenu) {
      setSubMenu(currentMenu.sub || []);
    } else {
      setSubMenu([]);
    }
  }, [location.pathname]);

  return subMenu;
}

export function useLastMenu() {
  const location = useLocation();
  const [current, setCurrent] = useState(null);
  const [lastMenu, setLastMenu] = useState([]);

  useEffect(() => {
    const path = location.pathname.split('/');
    const last = path[path.length - 1];

    let find;

    sideMenu.forEach((menu) => {
      const match = menu?.sub?.find((sub) => sub.path === last);
      if (match) {
        find = match;
        setLastMenu(menu.sub);
      }
    });

    if (find) {
      setCurrent(find);
    } else {
      setCurrent(null);
    }
  }, [location.pathname]);

  return { current, lastMenu };
}
