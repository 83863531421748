import styled from 'styled-components';
import { theme } from 'styles';

export const Floating = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${theme.colors.gray800};
  box-shadow: ${theme.colors.shadows3};

  p {
    font-weight: 700;
    line-height: 1.5;
    color: white;
  }
`;
