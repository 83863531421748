import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import useAlertContext from 'hooks/useAlertContext';

import { usePassword } from 'hooks';
import styled from 'styled-components';
import { Button } from 'components/button';
import { useNavigate } from 'react-router-dom';
import { ColDiv, Div, H3, RowDiv } from 'styles';
import * as C from 'components';
import DaumPostcodeEmbed from "react-daum-postcode";
import _ from 'lodash';
import { formatPhone, formatApiDate, getByteSize } from 'utils';
import AccountService from 'services/join/AccountService';
import { ButtonCheckbox, ButtonRadio, FormikInput, Input, InputLabel } from 'components/form';

export function JoinStep3({ setStep, values, setValues }) {
  const { t } = useTranslation();
  const { alert, toggleAlert } = useAlertContext();

  const [modalSearchAddressOpen, setModalSearchAddressOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [btnActive, setBtnActive] = useState(false);

  const { passwordInvalids, checkedValid } = usePassword(values.password, values.checkPassword);
  const initInvalids = {
    username: { type: '', text: '' },
    ...passwordInvalids,
  };
  const [invalids, setInvalids] = useState(initInvalids);

  const isSnsLogin = !!values.username;
  const navigate = useNavigate();


  const onChange = (name, value, checked) => {
    if (name === 'acceptChannel') {
      let temp = [...(values.acceptChannel || [])];
      if (checked) {
        temp.push(value);
      } else {
        temp = temp.filter((item) => item !== value);
      }
      setValues((values) => ({ ...values, acceptChannel: temp }));
    } else {
      setValues((values) => ({ ...values, [name]: value }));
    }

    if (invalids[name]?.type === 'error' && value === '') {
      setInvalids((prevInvalids) => ({
        ...prevInvalids,
        [name]: { ...prevInvalids[name], type: '' },
      }));
    }
  };





  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,16}$/;
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const schema = yup.object().shape({
    username: yup
      .string()
      .required(t('validation.required.entry'))
      .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
    password: yup.string().when([], {
      is: () => !isSnsLogin,
      then: () => yup.string()
        .required(t('validation.required.entry'))
        .matches(passwordRegex, t("validation.password.rule"))
    }),
    checkPassword: yup.string().when([], {
      is: () => !isSnsLogin,
      then: () => yup.string()
        .required(t('validation.required.entry'))
        .oneOf([yup.ref('password'), null], t("validation.password.identical"))
    }),
    memNickNm: yup
      .string()
      .required(t('validation.required.entry'))
      .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
    name: yup
      .string()
      .required(t('validation.required.entry'))
      .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
    birthday: yup
      .string()
      .required(t('validation.required.entry')),
    email: yup
      .string()
      .required(t('validation.required.entry'))
      .matches(emailRegex, t("validation.valid.email.address"))
      .test('maxByte', t('validation.max.byte', { maxByte: 200 }), (val) => getByteSize(val) <= 200),
    tel: yup
      .string()
      .required(t('validation.required.entry')),
    zipCode: yup
      .string()
      .required(t('validation.required.entry')),
    address: yup
      .string()
      .required(t('validation.required.entry')),
    detailAddress: yup
      .string()
      .required(t('validation.required.entry'))
      .test('maxByte', t('validation.max.byte', { maxByte: 500 }), (val) => getByteSize(val) <= 500),

  });

  const handleOnSubmit = async (values) => {
    setBtnActive(false);
    setLoading(true);
    const response = await AccountService.createAccount(values);
    if(response && response.status === 200){
      setStep('step4');
    }else if(response && response.status === 400){
      alert({
        message: response.result.defaultMessage,
        $btn1: {text: '닫기', onClick: () => toggleAlert(false)}
      });
    }else{
      /* TODO */
      alert({
        message: t('common.error.server'),
        $btn1: {text: '닫기', onClick: () => toggleAlert(false)}
      });

    }
    setBtnActive(true);
    setLoading(false);

  };

  const formik = useFormik({
    initialValues: {
      username: values?.username || '',
      password: '',
      checkPassword: '',
      memNickNm: values?.memNickNm || '',
      name: values?.name || '',
      email: values?.email || '',
      tel: values?.tel || '',
      birthday: values?.birthday || '',
      zipCode: values?.zipCode || '',
      address: values?.address || '',
      detailAddress: values?.detailAddress || '',
      acceptAgree: 'Y',
      acceptChannel: 'email',
      agreeTerms: 'Y',
      agreePrivate: 'Y'
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleOnSubmit
  });


  useEffect(() => {
    if(!firstLoad){
      setBtnActive(_.isEmpty(formik.errors));
      console.log(formik.errors);
    }else{
      setFirstLoad(false);
    }

  }, [formik.errors]);

  return (
    <>
      <H3 $display="none">회원 정보를 입력해 주세요.</H3>
      <ColDiv $gap={16} $padding="16px 0 78px">
        <FormikInput
          label="아이디"
          required
          name="username"
          readonly={isSnsLogin}
          placeholder="사용중인 아이디 입력"
          value={formik.values.username}
          invalid={formik.errors.username}
          touched={formik.touched.username}
          onChange={(_name, value) => {
            console.log(value);
            formik.setFieldValue('username', value);
          }}
          onBlur={formik.handleBlur}
        />
        {!isSnsLogin && (
          <>
            <FormikInput
              label="비밀번호"
              type="password"
              required
              name="password"
              placeholder="비밀번호 입력"
              value={formik.values.password}
              invalid={formik.errors.password}
              touched={formik.touched.password}
              onChange={(_name, value) => {
                formik.setFieldValue('password', value);
              }}
              onBlur={formik.handleBlur}
            />
            <FormikInput
              label="비밀번호 확인"
              type="password"
              required
              name="checkPassword"
              placeholder="비밀번호 다시 입력"

              value={formik.values.checkPassword}
              invalid={formik.errors.checkPassword}
              touched={formik.touched.checkPassword}
              onChange={(_name, value) => {
                formik.setFieldValue('checkPassword', value);
              }}
              onBlur={formik.handleBlur}
            />
          </>
        )}

        <FormikInput label="닉네임" name="memNickNm"
           value={formik.values.memNickNm}
           invalid={formik.errors.memNickNm}
           touched={formik.touched.memNickNm}
           onChange={(_name, value) => {
             formik.setFieldValue('memNickNm', value);
           }}
           onBlur={formik.handleBlur}
        />
        <FormikInput label="이름" name="name" disabled
           value={formik.values.name}
           invalid={formik.errors.name}
           touched={formik.touched.name}
           onChange={(_name, value) => {
             formik.setFieldValue('name', value);
           }}
           onBlur={formik.handleBlur}
        />

        <FormikInput label="생년월일" name="birthday" disabled
           value={formatApiDate(formik.values.birthday, 'YYYY-MM-DD')}
        />
        <FormikInput label="연락처" name="tel" maxLength={13} disabled
           value={formatPhone(formik.values.tel)}
        />
        <Div>
          <InputLabel label="성별" $disabled />
          <RowDiv $gap={8}>
            <ButtonCheckbox name="gender" value="man" checked={values.gender === 'MALE'} disabled>
              남
            </ButtonCheckbox>
            <ButtonCheckbox name="gender" value="woman" checked={values.gender === 'FEMALE'} disabled>
              여
            </ButtonCheckbox>
          </RowDiv>
        </Div>
        <Div>
          <InputLabel label="주소" required />
          <RowDiv $gap={4}>
            <FormikInput
              name="zipCode"
              required
              value={formik.values.zipCode}
              placeholder="우편번호 입력"
              disabled
              $flex={1}
            />
            <Button $size="m" $width={60} onClick={(e)=>setModalSearchAddressOpen(true)}>
              조회
            </Button>
          </RowDiv>
          <FormikInput name="address" required value={formik.values.address} $mTop={4} disabled />
          <FormikInput
            name="detailAddress"
            placeholder="상세 주소 입력 (선택)"
            $mTop={4}
            value={formik.values.detailAddress}
            invalid={formik.errors.detailAddress}
            touched={formik.touched.detailAddress}
            onChange={(_name, value) => {
              formik.setFieldValue('detailAddress', value);
            }}
            onBlur={formik.handleBlur}
          />
        </Div>

        <FormikInput
          label="E-mail"
          required
          name="email"
          readonly={isSnsLogin}
          placeholder="이메일 주소 입력 (선택)"
          value={formik.values.email}
          invalid={formik.errors.email}
          touched={formik.touched.email}
          onChange={(_name, value) => {
            formik.setFieldValue('email', value);
          }}
          onBlur={formik.handleBlur}
        />

        <Div>
          <InputLabel label="수신동의" required />
          <RowDiv $gap={8}>
            <ButtonRadio name="acceptAgree" value="true"
                         checked={formik.values.acceptAgree === 'Y'} onChange={onChange}>
              동의
            </ButtonRadio>
            <ButtonRadio name="acceptAgree" value="false" checked={values.acceptAgree === 'N'} onChange={onChange}>
              비동의
            </ButtonRadio>
          </RowDiv>
        </Div>
        <Div>
          <InputLabel label="수신채널" desc="복수 선택 가능" required />
          <RowDiv $gap={8}>
            <ButtonCheckbox
              name="acceptChannel"
              value="sms"
              onChange={onChange}
              checked={formik?.values.acceptChannel.includes('sms')}
            >
              SMS
            </ButtonCheckbox>
            <ButtonCheckbox
              name="acceptChannel"
              value="email"
              onChange={onChange}
              checked={formik?.values.acceptChannel.includes('email')}
            >
              E-mail
            </ButtonCheckbox>
          </RowDiv>
        </Div>
      </ColDiv>
      <ButtonDiv>
        <Button $size="l" type="sub" onClick={() => navigate('/')}>
          취소
        </Button>
        <Button $size="l" disabled={!btnActive} onClick={formik.handleSubmit} loading={loading} >
          회원가입
        </Button>
      </ButtonDiv>

      <C.FullModal open={modalSearchAddressOpen} setOpen={setModalSearchAddressOpen}>
        <DaumPostcodeEmbed
          autoClose={false}
          style={{ width: "100%", height: "100%" }}
          onComplete={(data) => {
            console.info(data);
            setModalSearchAddressOpen(false);
            formik.setFieldValue('zipCode', data.zonecode);
            formik.setFieldValue('address', data.address);

          }}
        />
      </C.FullModal>

    </>
  );
}

const ButtonDiv = styled(RowDiv)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 20px 20px;
  gap: 8px;
  background-color: white;
  height: 78px;
`;
