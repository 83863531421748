import React from 'react';
import { Div } from 'styles';

export function SvgText({ text, onClick, ...props }) {
  return (
    <Div onClick={onClick} $position="absolute" $lineHeight="0" $width={54} $height={76} {...props}>
      <svg width="54" height="76" viewBox="0 0 54 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M54 28.1481C54 45.4512 31.9798 59.0988 27.5518 73.664C27.4204 74.0964 26.5796 74.0964 26.4482 73.664C22.0202 59.0988 0 45.4512 0 28.1481C0 12.6024 11.3782 0 27 0C42.6218 0 54 12.6024 54 28.1481Z"
          fill="url(#paint0_linear_811_10812)"
        />
        <rect x="6" y="6" width="42" height="42" rx="21" fill="#171717" fillOpacity="0.2" />
        <text
          x="27"
          y="27"
          fontFamily="Pretendard"
          fontSize="14"
          fontWeight="500"
          fill="white"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {text}
        </text>
        <defs>
          <linearGradient id="paint0_linear_811_10812" x1="27" y1="0" x2="27" y2="76" gradientUnits="userSpaceOnUse">
            <stop stopColor="#46C7F9" />
            <stop offset="1" stopColor="#00AEF8" />
          </linearGradient>
        </defs>
      </svg>
    </Div>
  );
}

export function SvgTextYellow({ text, ...props }) {
  return (
    <Div $position="absolute" $lineHeight="0" $width={54} $height={76} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="54" height="74" viewBox="0 0 54 74" fill="none">
        <path
          d="M54 28.1481C54 45.4512 31.9798 59.0988 27.5518 73.664C27.4204 74.0964 26.5796 74.0964 26.4482 73.664C22.0202 59.0988 0 45.4512 0 28.1481C0 12.6024 11.3782 0 27 0C42.6218 0 54 12.6024 54 28.1481Z"
          fill="url(#paint0_linear_835_11094)"
        />
        <rect x="6" y="6" width="42" height="42" rx="21" fill="#171717" fillOpacity="0.2" />
        <text
          x="27"
          y="27"
          fontFamily="Pretendard"
          fontSize="14"
          fontWeight="500"
          fill="white"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {text}
        </text>
        <defs>
          <linearGradient id="paint0_linear_835_11094" x1="27" y1="0" x2="27" y2="76" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FEE540" />
            <stop offset="1" stopColor="#FBD23B" />
          </linearGradient>
        </defs>
      </svg>
    </Div>
  );
}
