import styled, { keyframes } from 'styled-components';

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

export const ToastContainer = styled.div`
  position: fixed;
  bottom: 90px;
  left: 50%;
  padding: 8px 16px;
  transform: translateX(-50%);
  min-width: fit-content;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.toast};
  animation: ${slideUp} 0.5s ease-in-out;

  p {
    white-space: nowrap;
  }
`;
