import { useState, useEffect } from 'react';

export function usePhoneMask(phone) {
  const [maskedPhone, setMaskedPhone] = useState('');

  useEffect(() => {
    const temp = phone.replace(/\D/g, '');
    if (temp.length === 10) {
      setMaskedPhone(temp.replace(/(\d{3})(\d{3})(\d{4})/, '$1***$3'));
    } else if (temp.length === 11) {
      setMaskedPhone(temp.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3'));
    } else {
      setMaskedPhone(phone);
    }
  }, [phone]);

  return maskedPhone;
}
