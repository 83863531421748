import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CouponDetailView } from './CouponDetailView';

export function CouponDetail(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;

  return <CouponDetailView item={item} />;
}
