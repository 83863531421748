import React, { useRef, useState } from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import * as htmlToImage from 'html-to-image';

export function MyScore(props) {
  const captureRef = useRef();
  const [modalOpen, setModalOpen] = useState();
  const [toast, setToast] = useState('');

  const saveImg = () => {
    if (captureRef.current) {
      htmlToImage
        .toPng(captureRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'donation-detail.png';
          link.href = dataUrl;
          link.click();

          setToast('success');
        })
        .catch((error) => {
          setToast('error');
        });
    }
  };

  return (
    <>
      <Container title="걷쥬 나눔 상세 페이지" $padding="0">
        <S.Div $height="calc(100% - 78px)">
          <S.Div ref={captureRef} $padding="20px" onClick={saveImg}>
            <S.Div $bgColor="gray50" $radius={10} $overflow="hidden" $shadow="lineShadow">
              <S.RowCenterDiv $padding="68px 0" $bgColor="gray50">
                <C.Img src="walk" alt="기부 이미지" $width={128} />
                <S.Div>
                  <S.Text $textAlign="center" $type="label12" $color="gray700">
                    나의 걸음수
                  </S.Text>
                  <S.Text $type="b24" $color="gray900" $padding="12px 16px 4px">
                    2,000
                  </S.Text>
                </S.Div>
              </S.RowCenterDiv>
              <S.Div $padding="16px 0 20px" $bgColor="white">
                <S.Text $pTop={4} $textAlign="center" $type="b20" $color="gray900">
                  이만큼 걸었어요!
                </S.Text>
                <S.Text $textAlign="center" $type="b" $color="gray800">
                  나의 걸음수 자랑하기
                </S.Text>
                <S.Text $padding="24px 0 8px" $textAlign="center" $type="label12" $color="gray800" $fontWeight={500}>
                  <S.Span $type="label12" $color="gray600" $fontWeight={500} $pRight={4}>
                    현재일자
                  </S.Span>
                  2024년 7월 10일 22:54
                </S.Text>
              </S.Div>
            </S.Div>
          </S.Div>
        </S.Div>
        <S.Div
          $padding="8px 20px 20px"
          onClick={() => {
            alert('커뮤니티 게시판에 해당 이미지와 함께 글 업로드');
            setModalOpen(true);
          }}
        >
          <C.Button $size="l" type="sub">
            나의 걸음수 자랑하기
          </C.Button>
        </S.Div>
      </Container>
      {toast && (
        <C.Toast setToast={setToast}>
          {toast === 'success' && <>이미지 저장이 완료되었습니다.</>}
          {toast === 'error' && <>다시 시도해 주세요.</>}
        </C.Toast>
      )}
      <C.Modal
        open={modalOpen}
        $btn1={{
          text: '닫기',
          onClick: () => {
            setModalOpen(false);
          },
        }}
      >
        <S.ColCenterDiv>
          <C.Img src="success" $width={60} $height={60} />
          <S.Text $textAlign="center" $pTop={16}>
            등록에
            <br />
            성공하였습니다.
          </S.Text>
        </S.ColCenterDiv>
      </C.Modal>
    </>
  );
}
