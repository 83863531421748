import React, { useEffect, useRef } from 'react';
import { Div, RowCenterDiv, RowDiv } from 'styles';
import { Icon } from 'components/common';
import * as S from './ModalStyle';
import { Dim } from '../Dim';

export function Modal({ open, children, $btn1, $btn2, ...props }) {
  const ref = useRef();
  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollTop = 0;
      }
    }, 0);
  }, [open]);

  return (
    <Dim open={open}>
      <S.ModalWrap {...props}>
        <S.ModalConDiv ref={ref}>{children}</S.ModalConDiv>
        <S.BtnDiv>
          {$btn1 && <S.Btn1 onClick={$btn1.onClick}>{$btn1.text}</S.Btn1>}
          {$btn2 && (
            <S.Btn2 onClick={$btn2.onClick} $color={$btn2.color}>
              {$btn2.text}
            </S.Btn2>
          )}
        </S.BtnDiv>
      </S.ModalWrap>
    </Dim>
  );
}

export function FullModal({ open, setOpen, children }) {
  const ref = useRef();
  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollTop = 0;
      }
    }, 0);
  }, [open]);

  return (
    <Dim open={open}>
      <Div $height="100svh" $width="100vw" $bgColor="gray900">
        <RowDiv $height={56} $justify="flex-end" $align="center" $padding="0 20px">
          <Icon name="Close" onClick={() => setOpen()} />
        </RowDiv>
        <RowCenterDiv ref={ref} $height="calc(100% - 112px)">
          {children}
        </RowCenterDiv>
      </Div>
    </Dim>
  );
}
