import React, { useState } from 'react';
import * as S from 'styles';
import * as C from 'components';
import { useLocation } from 'react-router-dom';
import { DonationDetailView } from './DonationDetailView';

export function DonationDetail(props) {
  const location = useLocation();
  const item = location.state;

  const [modalOpen, setModalOpen] = useState(false);
  const [values, setValues] = useState({ state: 0, point: 18800, donation: '' });
  const [invalids, setInvalids] = useState({ donation: { type: '', text: '' } });

  const onChange = (name, val) => {
    setValues({ ...values, [name]: val });

    if (invalids[name]?.type === 'error') {
      setInvalids((prevInvalids) => ({
        ...prevInvalids,
        [name]: { ...prevInvalids[name], type: '' },
      }));
    }
  };

  const submit = () => {
    if (!values.donation) {
      setInvalids({ donation: { type: 'error', text: '포인트를 입력해주세요.' } });
    } else if (values.donation && Number(values.point) <= Number(values.donation)) {
      setInvalids({ donation: { type: 'error', text: '포인트가 부족합니다.' } });
    } else {
      setModalOpen(true);
    }
  };

  return (
    <>
      <DonationDetailView item={item} values={values} onChange={onChange} submit={submit} invalids={invalids} />
      <C.Modal
        open={modalOpen}
        $btn1={{
          text: '닫기',
          onClick: () => {
            setModalOpen(false);
            window.location.reload();
          },
        }}
      >
        <S.ColCenterDiv>
          <C.Img src="success" $width={60} $height={60} />
          <S.Text $textAlign="center" $pTop={16}>
            포인트 기부가
            <br />
            완료되었습니다.
          </S.Text>
        </S.ColCenterDiv>
      </C.Modal>
    </>
  );
}
