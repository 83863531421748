import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import * as D from 'data';
import { Container } from 'layouts';
import styled from 'styled-components';

export function HistoryListView({ list, navigate, tabIndex, currentTab, onChange, filters }) {
  return (
    <Container title="포인트 이력" $padding="20px 16px" $height="calc(100% - 142px)">
      <C.Tab list={['포인트 이력', '기부 이력']} tabIndex={tabIndex} currentTab={currentTab} />
      <S.Div $pTop={16}>
        <S.Div $padding="16px 0">
          <MyPointDiv>
            <S.RowDiv $align="center">
              <C.Icon name="Point" $width={16} $height={16} />
              <S.Text $type="m14" $color="gray700" $pLeft={4}>
                나의 {(tabIndex === 0 && `사용 가능`) || `기부`} 포인트
              </S.Text>
            </S.RowDiv>
            <S.Text $type="b24" $color="gray900" $minWidth={140} $textAlign="end">
              18,800
              <S.Span $type="20" $color="gray900">
                P
              </S.Span>
            </S.Text>
          </MyPointDiv>
          <ExpiringPointDiv>
            <S.RowDiv $align="center">
              <C.Icon name="Point" $width={16} $height={16} />
              <S.Text $type="m12" $color="gray500" $padding="0 4px">
                소멸예정 포인트
              </S.Text>
              <C.Badge $bgColor="gray500" $padding="2px 8px">
                D-7
              </C.Badge>
            </S.RowDiv>
            <S.Text $type="m18" $color="gray500" $minWidth={140} $textAlign="end">
              1,000
              <S.Span $type="18" $color="gray500">
                P
              </S.Span>
            </S.Text>
          </ExpiringPointDiv>
        </S.Div>
        <S.RowDiv $gap={4}>
          {D.dateList.map((btn) => (
            <C.Button
              key={`btn_${btn.id}`}
              $size="m"
              type="line"
              $color="gray700"
              $focusColor="gray800"
              $active={btn.val === filters.period}
              onClick={() => onChange('period', btn.val)}
            >
              {btn.name}
            </C.Button>
          ))}
        </S.RowDiv>
        <C.Date filters={filters} onChange={onChange} $mTop={8} disabled={filters?.period !== 'direct'} />
        <S.List $mTop={16}>
          {list?.map((item, index) => (
            <S.ListItem
              key={`point_${index}`}
              $padding="16px 8px"
              $borderBottom={{ color: list.length - 1 === index ? 'transparent' : 'gray200' }}
              onClick={() => tabIndex === 1 && navigate(`${item.id}`, { state: item })}
            >
              <S.Text $color="gray700" $type="14">
                {item.createDate}
              </S.Text>
              <S.Text $mTop={4} $color="gray900">
                {item.name}
              </S.Text>
              <S.ColDiv $align="flex-end" $mTop={4}>
                <S.RowDiv $align="center" $gap={8}>
                  <S.Span $color="gray500" $type="12">
                    {tabIndex === 0 ? (item.savePoint ? '적립내역' : '사용내역') : '기부내역'}
                  </S.Span>
                  <S.Text $type="18" $color="gray900">
                    <S.Span $color={item.savePoint ? 'primary' : 'alert'} $type="b18" $pRight={4}>
                      {item.savePoint ? `+${item.savePoint}` : `-${item.usePoint}`}
                    </S.Span>
                    P
                  </S.Text>
                </S.RowDiv>
                <S.Text $visibility={item.savePoint ? 'visible' : 'hidden'} $type="12" $color="gray500" $mTop={4}>
                  소멸예정 D-180
                </S.Text>
              </S.ColDiv>
            </S.ListItem>
          ))}
        </S.List>
      </S.Div>
      <S.Div $position="fixed" $width="100vw" $height={80} $bottom="0" $left="0" $bgColor="#A1BCFF">
        <C.Img src="banner" $height={80} />
      </S.Div>
    </Container>
  );
}

const MyPointDiv = styled(S.RowDiv)`
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 8px;

  svg > path {
    fill: ${S.theme.colors.primary};
  }
  svg > circle {
    stroke: ${S.theme.colors.primary};
  }
`;

const ExpiringPointDiv = styled(S.RowDiv)`
  align-items: center;
  justify-content: space-between;

  svg > path {
    fill: ${S.theme.colors.disabled};
  }
  svg > circle {
    stroke: ${S.theme.colors.disabled};
  }
`;
