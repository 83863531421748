const sampleImg = 'promoSample';
const sampleCon = `새로운 기능과 성능 향상을 포함한 버전 2.0이 출시되었습니다. 이번 업데이트에서는 다음과 같은 변화가 있습니다.\nLorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque erat arcu, bibendum quis purus eu, ullamcorper malesuada quam.\nNam vitae tellus lectus. Sed porttitor nec massa eu faucibus. Maecenas at justo nisi. Fusce eget turpis lorem.`;
export const promoList = [
  {
    id: 1,
    img: sampleImg,
    name: '지역홍보 타이틀 1',
    con: sampleCon,
  },
  {
    id: 2,
    img: sampleImg,
    name: '지역홍보 타이틀 2 , 지역홍보 타이틀 2 , 지역홍보 타이틀 2 , 지역홍보 타이틀 2 , 지역홍보 타이틀 2 , 지역홍보 타이틀 2 , 지역홍보 타이틀 2',
    con: sampleCon,
  },
  {
    id: 3,
    img: sampleImg,
    name: '지역홍보 타이틀 3',
    con: sampleCon,
  },
  {
    id: 4,
    img: sampleImg,
    name: '지역홍보 타이틀 4',
    con: sampleCon,
  },
  {
    id: 5,
    img: sampleImg,
    name: '지역홍보 타이틀 5',
    con: sampleCon,
  },
  {
    id: 6,
    img: sampleImg,
    name: '지역홍보 타이틀 6',
    con: sampleCon,
  },
  {
    id: 7,
    img: sampleImg,
    name: '지역홍보 타이틀 7',
    con: sampleCon,
  },
  {
    id: 8,
    img: sampleImg,
    name: '지역홍보 타이틀 8',
    con: sampleCon,
  },
  {
    id: 9,
    img: sampleImg,
    name: '지역홍보 타이틀 9',
    con: sampleCon,
  },
  {
    id: 10,
    img: sampleImg,
    name: '지역홍보 타이틀 10',
    con: sampleCon,
  },
  {
    id: 11,
    img: sampleImg,
    name: '지역홍보 타이틀 11',
    con: sampleCon,
  },
  {
    id: 12,
    img: sampleImg,
    name: '지역홍보 타이틀 12',
    con: sampleCon,
  },
];
