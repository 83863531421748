import React from 'react';
import styled from 'styled-components';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { Loading } from 'components/loading/Loading';

import AuthenticationService from 'services/authentication/AuthenticationService';
import userAuthenticationStore from 'stores/authentication/AuthenticationStore';

import useNativeAppCommandHandler from 'hooks/native-app/useNativeAppCommandHandler';

import { Header } from './header/Header';

export function ProtectedPageCommonLayout(props) {
  const location = useLocation();
  
  useNativeAppCommandHandler();

  const isAuthenticated = userAuthenticationStore((state) => state.isAuthenticated);
  const setAuthenticated = userAuthenticationStore((state) => state.setAuthenticated);
  const setUserInfo = userAuthenticationStore((state) => state.setUserInfo);

  const [loadingProfileData, setLoadingProfileData] = React.useState(true);

  const show = ['/', '/point', '/challenge', `/community`].some((path) => location.pathname === path);

  React.useEffect(() => {
    console.log('protected-page-common')
    async function fetchProfileData() {
      let userProfile = null;

      try {
        userProfile = await AuthenticationService.getMyInfo();
      } catch(e) {
        console.error(e);
      }

      if (userProfile && userProfile.id) {
        setAuthenticated(true);
        setUserInfo(userProfile);
      } else {
        setAuthenticated(false);
        setUserInfo(null);
      }

      setLoadingProfileData(false);
    }

    if (!isAuthenticated) {
      setLoadingProfileData(true);
      fetchProfileData()
    }
  }, [location]);

  function LayoutContent() { 
    return (
      <>
        <h1>걷쥬</h1>
        <Main $hide={!show}>
          <Outlet />
        </Main>
        {show && <Header />}
      </>
    )
  }

  if (isAuthenticated) {
    return <LayoutContent />
  }

  if (loadingProfileData) {
    return (
      <Loading />
    )
  } else if (!isAuthenticated) {
    return <Navigate to="/login" replace />
  } else {
    return <LayoutContent />
  }
}

const Main = styled.main`
  height: ${({ $hide }) => (!$hide ? 'calc(100svh - 64px)' : '100svh')};
`;
