import React from 'react';
import * as S from './LoadingStyle';

export function Loading({ type, ...props }) {
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <S.LoadingStyles onClick={onClick} $type={type} {...props}>
      <div className="loader" />
    </S.LoadingStyles>
  );
}
