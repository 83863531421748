import React from 'react';
import { Container } from 'layouts';
import { useSubMenu } from 'hooks';
import { SubMenuList } from 'components';

export function PointHome(props) {
  const subMenu = useSubMenu();

  return (
    <Container title="포인트">
      <SubMenuList list={subMenu} />
    </Container>
  );
}
