import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import * as D from 'data';
import { Container } from 'layouts';

function ChallengeListView({ list, historyList, navigate, tabIndex, currentTab, onChange, filters }) {
  return (
    <Container title="챌린지">
      <C.Tab list={['챌린지', '참여이력']} tabIndex={tabIndex} currentTab={currentTab} />
      {tabIndex === 0 && (
        <S.Div $pTop={16}>
          <C.Dropdown name="area" title="지역 선택" value={filters.area} onChange={onChange} list={D.areaList} />
          <S.RowDiv $gap={4} $pTop={8}>
            {D.dateList.map((btn) => (
              <C.Button
                key={`btn_${btn.id}`}
                $size="m"
                type="line"
                $color="gray700"
                $focusColor="gray800"
                $active={btn.val === filters.period}
                onClick={() => onChange('period', btn.val)}
              >
                {btn.name}
              </C.Button>
            ))}
          </S.RowDiv>
          <C.Date filters={filters} onChange={onChange} $mTop={8} disabled={filters?.period !== 'direct'} />
          <S.Div $pTop={16}>
            <S.GridList $gap={14}>
              {list?.map((item, index) => (
                <S.ListItem key={`item_${index}`} onClick={() => navigate(`${item.id}`, { state: item })}>
                  <C.ChallengeBox item={item} />
                </S.ListItem>
              ))}
            </S.GridList>
          </S.Div>
        </S.Div>
      )}
      {tabIndex === 1 && (
        <S.Div $padding="16px 0">
          <S.RowDiv $gap={4}>
            <C.Input
              name="challengeName"
              value={filters.challengeName}
              onChange={onChange}
              placeholder="챌린지명 입력"
              icon="Search"
              $width="100%"
            />
            <C.Button $type="sub" $size="m" $minWidth={60} $width={60} onClick={() => alert('검색')}>
              검색
            </C.Button>
          </S.RowDiv>
          <S.RowDiv $gap={4} $pTop={8}>
            {D.dateList.map((btn) => (
              <C.Button
                key={`btn_${btn.id}`}
                $size="m"
                type="line"
                $color="gray700"
                $focusColor="gray800"
                $active={btn.val === filters.period}
                onClick={() => onChange('period', btn.val)}
              >
                {btn.name}
              </C.Button>
            ))}
          </S.RowDiv>
          <C.Date filters={filters} onChange={onChange} $mTop={8} disabled={filters?.period !== 'direct'} />
          <S.List $pTop={16}>
            {historyList?.map((item, index) => (
              <S.RowListItem
                key={`item_${index}`}
                $mTop={index !== 0 ? 8 : 0}
                $justify="space-between"
                $padding="16px"
                $shadow="lineShadow"
                $radius={10}
                onClick={() => item.state !== 0 && navigate(`${item.id}/success`, { state: item })}
              >
                <S.RowDiv $gap={8} $justify="space-between" $width="100%">
                  <S.Div>
                    <S.RowDiv $gap={4}>
                      <C.Badge
                        $bgColor={
                          D.areaList.find((area) => area.name === item.host)?.color ||
                          D.areaList.find((area) => area.name === '기타').color
                        }
                      >
                        {item.host}
                      </C.Badge>
                      <C.Badge $bgColor={item.state === 0 ? 'gray300' : 'mint'} icon="BadgeCheck">
                        {item.state === 0 ? '실패' : '성공'}
                      </C.Badge>
                    </S.RowDiv>
                    <S.Text $padding="8px 0" $color="gray800" $type="m" $whiteSpace="pre">
                      {item.info}
                      <br />
                      {item.challenge}
                    </S.Text>
                    <S.Text $type="b" $color={item.state === 0 ? 'gray300' : 'primary'}>
                      <S.Span $type="b14" $color={item.state === 0 ? 'gray300' : 'primary'}>
                        획득포인트&nbsp;
                      </S.Span>
                      {item.state === 0 ? `-` : `${item.point}P`}
                    </S.Text>
                    <S.Text $padding="2px 0" $type="m14" $color="gray700">
                      {item.startDate}~{item.endDate}
                    </S.Text>
                    <S.Text $color="gray700" $type="b">
                      <S.Span $pRight={8} $type="m14" $color="gray700">
                        나의 걸음수
                      </S.Span>
                      {item.walk.toLocaleString()}
                      <S.Span $type="m12" $color="gray700" $pLeft={4}>
                        / {item.total.toLocaleString()}
                      </S.Span>
                    </S.Text>
                  </S.Div>
                  <C.Img src={item.state === 0 ? 'challengeFail' : 'challengeSuccess'} $minWidth={72} $width={90} />
                </S.RowDiv>
              </S.RowListItem>
            ))}
          </S.List>
        </S.Div>
      )}
    </Container>
  );
}

export default ChallengeListView;
