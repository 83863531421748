import React, { useState, useRef } from 'react';
import { useOutside, useTimer } from 'hooks';
import { isCheckInvalids, formatPhone } from 'utils';
import { Button } from 'components/button';
import { Dropdown, Input } from 'components/form';
import { ColDiv, Div, RowDiv, Text } from 'styles';

const carrierList = [
  { name: 'SKT', value: '1' },
  { name: 'KT', value: '2' },
  { name: 'LG U+', value: '3' },
  { name: 'SKT 알뜰폰', value: '4' },
  { name: 'KT 알뜰폰', value: '5' },
  { name: 'LG U+ 알뜰폰', value: '6' },
];

export function AuthStep2({ setStep, sampleData, type, values, setValues }) {
  const checkValid = ['userName', 'carrier', 'phone', 'phoneCode'];
  const ref = useRef();
  const inputRefs = useRef([]);
  const [formIndex, setFormIndex] = useState(0);
  const [codeSent, setCodeSent] = useState(false);
  const { settingTime, setSettingTime, resetTimer, formatTime } = useTimer(600, codeSent);

  const [invalids, setInvalids] = useState({
    userName: { type: '', text: '이름을 다시 입력해 주세요.' },
    carrier: { type: '', text: '통신사를 다시 선택해 주세요.' },
    phone: { type: '', text: '휴대폰 번호를 다시 입력해 주세요.' },
    phoneCode: { type: '', text: '인증번호를 다시 입력해 주세요.' },
  });

  // 유효성 검사
  const validateInput = {
    userName: (value) => value.length >= 2,
    carrier: (value) => !!value,
    phone: (value) => /^\d{10,11}$/.test(value),
    phoneCode: (value) => value.length === 6,
  };

  useOutside([ref], () => {
    Object.keys(values).forEach((name) => {
      validation(name, values[name]);
    });
  });

  const validation = (name, value) => {
    if (validateInput[name]) {
      const isValid = validateInput[name](value);
      const currentIndex = checkValid.indexOf(name);
      if (isValid && formIndex === currentIndex && !codeSent) {
        setFormIndex(currentIndex + 1);
        focusNext(currentIndex + 1);
      }

      if (invalids[name]?.type === 'error') {
        setInvalids((invalid) => ({
          ...invalid,
          [name]: { ...invalid[name], type: '' },
        }));
      }
    }
  };

  const onChange = (name, val) => {
    if (name === 'phone' || name === 'carrier') {
      // 폰번호 또는 통신사 변경 시 인증번호와 타이머 초기화
      setValues((values) => ({
        ...values,
        [name]: val,
        phoneCode: '',
      }));
      setCodeSent(false);
      resetTimer();
    } else if (name === 'userName' && codeSent) {
      // 이름 변경 시 인증번호 초기화 및 코드 발송 상태 초기화
      setValues((values) => ({
        ...values,
        phoneCode: '',
      }));
      setCodeSent(false);
      resetTimer();
    } else {
      setValues((values) => ({
        ...values,
        [name]: val,
      }));
    }
    validation(name, val);
  };

  const focusNext = (index) => {
    if (inputRefs.current[index] && !codeSent) {
      inputRefs.current[index].focus();
    }
  };

  const checkCode = () => {
    let isSuccess = true;
    const tempInvalids = { ...invalids };

    checkValid.forEach((name) => {
      if (sampleData && values[name] !== sampleData[name]) {
        isSuccess = false;
        tempInvalids[name] = {
          type: 'error',
          text: invalids[name].text,
        };
      } else {
        tempInvalids[name] = { type: '', text: '' };
      }
    });

    setInvalids(tempInvalids);

    if (isSuccess) {
      setInvalids({ ...invalids, phoneCode: { type: 'success', text: '인증을 완료하였습니다.' } });
      resetTimer();
      setSettingTime(0);
      setFormIndex(4);
    }
  };

  const submit = () => {
    setStep(type === 'pw' || type === 'join' ? 'step3' : 'success'); // 'failed'
  };

  const codeSend = () => {
    setCodeSent(true);
    resetTimer();
    setFormIndex(3);
  };

  const codeResend = () => {
    setValues({ ...values, phoneCode: '' });
    resetTimer();
  };

  return (
    <>
      <Div $height={type === 'join' ? 'calc(100svh - 286px)' : 'calc(100svh - 152px)'} ref={ref}>
        {type !== 'join' && (
          <Text $type="b24" $color="gray900">
            실명 인증을
            <br />
            해주세요.
          </Text>
        )}
        <Div $pTop={16}>
          <ColDiv $padding="16px 0" $gap={16}>
            <Input
              setRef={(el) => (inputRefs.current[0] = el)}
              name="userName"
              value={values.userName}
              onChange={onChange}
              placeholder="이름 입력"
              $invalid={invalids.userName}
              disabled={invalids.phoneCode.type === 'success'}
              onKeyDown={() => validation('userName', values.userName)}
            />
            {formIndex > 0 && (
              <Dropdown
                name="carrier"
                value={values.carrier}
                onChange={onChange}
                placeholder="통신사 선택"
                title="통신사 선택"
                list={carrierList}
                $invalid={invalids.carrier}
                disabled={invalids.phoneCode.type === 'success'}
              />
            )}
            {formIndex > 1 && (
              <Input
                setRef={(el) => (inputRefs.current[1] = el)}
                name="phone"
                maxLength={13}
                value={formatPhone(values.phone)}
                onChange={onChange}
                type="tel"
                placeholder="전화번호 입력 (숫자만 입력 가능)"
                $invalid={invalids.phone}
                disabled={invalids.phoneCode.type === 'success'}
              />
            )}
            {codeSent && (
              <RowDiv $gap={8}>
                <Input
                  setRef={(el) => (inputRefs.current[2] = el)}
                  $code
                  settingTime={settingTime}
                  formatTime={formatTime}
                  name="phoneCode"
                  value={values.phoneCode}
                  onChange={onChange}
                  type="tel"
                  placeholder="인증번호 입력"
                  maxLength={6}
                  $invalid={invalids.phoneCode}
                  disabled={invalids.phoneCode.type === 'success'}
                />
                <Button $width={74} $type="sub" onClick={codeResend} disabled={invalids.phoneCode.type === 'success'}>
                  재전송
                </Button>
              </RowDiv>
            )}
          </ColDiv>
        </Div>
      </Div>
      {formIndex > 1 && !codeSent && (
        <Button $size="l" disabled={!/^\d{10,11}$/.test(values.phone)} onClick={codeSend}>
          인증번호 전송
        </Button>
      )}
      {codeSent && formIndex !== 4 && (
        <Button $size="l" onClick={checkCode} disabled={!isCheckInvalids(invalids) || !values.phoneCode}>
          확인
        </Button>
      )}
      {formIndex === 4 && (
        <Button $size="l" onClick={submit}>
          다음
        </Button>
      )}
    </>
  );
}
