import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import * as D from 'data';
import styled from 'styled-components';

export function MyCommunityBox({ item }) {
  return (
    <MyWrap>
      <ImgBox>
        <S.RowDiv $position="absolute" $zIndex={1} $left={8} $top={8} $gap={8}>
          <C.Badge
            className="badge"
            $bgColor={
              D.areaList.find((area) => area.name === item.host)?.color ||
              D.areaList.find((area) => area.name === '기타').color
            }
          >
            {item.host}
          </C.Badge>
          <C.Badge className="badge" $bgColor={item.state === 0 && 'gray400'} icon="BadgeCheck">
            {item.state === 0 ? '참여가능' : '참여중'}
          </C.Badge>
        </S.RowDiv>
        <C.Img src={item.img} />
      </ImgBox>
      <S.Div $padding="16px">
        <S.Text $color="gray500" $type="label12">
          {item.startDate} ~ {item.endDate}
        </S.Text>
        <S.Text $color="gray800" $type="b">
          홍성군 공식 커뮤니티
        </S.Text>
        <S.RowDiv $gap={16}>
          <TextBox>
            <p>게시글수</p>
            <p>100</p>
          </TextBox>
          <TextBox>
            <p>댓글수</p>
            <p>200</p>
          </TextBox>
          <TextBox>
            <p>친구추천수</p>
            <p>15</p>
          </TextBox>
        </S.RowDiv>
      </S.Div>
    </MyWrap>
  );
}

const MyWrap = styled(S.Div)`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: ${S.theme.colors.lineShadow};
`;

const ImgBox = styled(S.Div)`
  position: relateive;

  img {
    height: 130px;
    object-fit: cover;
  }
`;

const TextBox = styled(S.RowDiv)`
  gap: 4px;

  p {
    color: ${S.theme.colors.gray500};
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
`;
