import React, { useRef, useState, useEffect } from 'react';
import { usePassword } from 'hooks';
import { ColDiv, Div, Text } from 'styles';
import { Button } from 'components/button';
import { Input } from 'components/form';
import { isEmptyValue, isCheckInvalids } from 'utils';

export function AuthStep3({ setStep, values, setValues }) {
  const ref = useRef();
  const [btnActive, setBtnActive] = useState(false);
  const { passwordInvalids, checkedValid } = usePassword(values.password, values.checkPassword);
  const initInvalids = {
    userId: { type: '', text: '' },
    ...passwordInvalids,
  };
  const [invalids, setInvalids] = useState(initInvalids);

  const onChange = (name, value) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const submit = () => {
    setStep('success');
    // setStep('failed');
  };

  useEffect(() => {
    setInvalids((prevInvalids) => ({
      ...prevInvalids,
      ...passwordInvalids,
    }));
  }, [passwordInvalids]);

  useEffect(() => {
    const empty = !isEmptyValue(values);
    const checkInvalids = isCheckInvalids(invalids);

    setBtnActive(empty && checkInvalids && checkedValid);
  }, [values, invalids]);

  return (
    <>
      <Div $height="calc(100svh - 152px)" ref={ref}>
        <Text $type="b24" $color="gray900">
          새로운 비밀번호를
          <br />
          설정해 주세요.
        </Text>
        <ColDiv $gap={16} $padding="16px 0">
          <Input
            label="아이디"
            required
            name="userId"
            value={values.userId}
            onChange={onChange}
            placeholder="사용중인 아이디 입력"
            $invalid={invalids.userId}
          />
          <Input
            label="비밀번호"
            type="password"
            required
            name="password"
            value={values.password}
            onChange={onChange}
            placeholder="비밀번호 입력"
            $invalid={invalids.password}
          />
          <Input
            label="비밀번호 확인"
            type="password"
            required
            name="checkPassword"
            value={values.checkPassword}
            onChange={onChange}
            placeholder="비밀번호 다시 입력"
            $invalid={invalids.checkPassword}
          />
        </ColDiv>
      </Div>
      <Button $size="l" disabled={!btnActive} onClick={submit}>
        비밀번호 재설정
      </Button>
    </>
  );
}
