import { useState, useEffect } from 'react';

export const useTimer = (initialTime, isActive) => {
  const [settingTime, setSettingTime] = useState(initialTime);

  useEffect(() => {
    if (!isActive || settingTime <= 0) return;

    const timer = setInterval(() => {
      setSettingTime((time) => time - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [isActive, settingTime]);

  const resetTimer = () => {
    setSettingTime(initialTime);
  };

  const formatTime = () => {
    const minutes = Math.floor(settingTime / 60);
    const seconds = settingTime % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return { settingTime, setSettingTime, resetTimer, formatTime };
};
