import { useState } from 'react';

export function useTab(init) {
  const [tabIndex, setTabIndex] = useState(init);

  const currentTab = (index) => {
    setTabIndex(index);
  };

  return [tabIndex, currentTab];
}
