import React, { useState, useEffect } from 'react';
import * as D from 'data';
import * as S from 'styles';
import { useTab } from 'hooks';
import * as C from 'components';
import { Container } from 'layouts';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function CommunityList(props) {
  const navigate = useNavigate();
  const initFilters = {
    area: '1',
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, currentTab] = useTab(parseInt(searchParams.get('tab'), 10) || 0);
  const [filters, setFilters] = useState(initFilters);
  const [list, setList] = useState(D.challengeList);

  const onChange = (name, val) => {
    setFilters((filters) => ({
      ...filters,
      [name]: val,
    }));
  };

  const onClick = (item) => {
    if (item.state === 0) {
      navigate(`profile`, { state: { type: 'add' } });
    } else {
      navigate(`${item.id}`, { state: item });
    }
  };

  useEffect(() => {
    setSearchParams({ tab: tabIndex, ...filters }, { replace: true });
  }, [tabIndex, filters, setSearchParams]);

  return (
    <Container title="커뮤니티">
      <C.Tab list={['커뮤니티', '나의 커뮤니티']} tabIndex={tabIndex} currentTab={currentTab} />
      <S.Div $padding="16px 4px 0">
        {tabIndex === 0 && (
          <C.Dropdown name="area" title="지역 선택" value={filters.area} onChange={onChange} list={D.areaList} />
        )}
        <S.GridList $gap={14} $pTop={16} $grid={tabIndex === 0 ? 2 : 1}>
          {tabIndex === 0 &&
            list?.map((item, index) => (
              <S.ListItem key={`item_${index}`} onClick={() => onClick(item)}>
                <C.CommunityBox item={item} />
              </S.ListItem>
            ))}
          {tabIndex === 1 &&
            list?.map((item, index) => (
              <S.ListItem key={`item_${index}`} onClick={() => navigate(`${item.id}`, { state: item })}>
                <C.MyCommunityBox item={item} />
              </S.ListItem>
            ))}
        </S.GridList>
      </S.Div>
    </Container>
  );
}
