import React from 'react';
import * as S from 'styles';
import * as C from 'components';

export function JoinView({ step, setStep, values, setValues, sampleData }) {
  const stepText = {
    step1: '서비스 이용약관에\n동의해 주세요.',
    step2: '실명 인증을\n해주세요.',
    step3: '회원 정보를\n입력해 주세요.',
    step4: '회원가입이\n완료 되었습니다!',
  };
  return (
    <>
      <h1>걷쥬</h1>
      <S.H2 $display="none">회원가입</S.H2>
      <C.AppBar>회원가입</C.AppBar>
      <S.Div $padding="20px">
        <C.JoinStepTab step={step} />
        <S.Text $whiteSpace="pre" $type="b24" $color="gray900" $padding="16px 0" $minHeight={104}>
          {stepText[step]}
        </S.Text>
        {step === 'step1' && <C.JoinStep1 setStep={setStep} values={values} setValues={setValues} />}
        {step === 'step2' && (
          <C.JoinStep2 sampleData={sampleData} setStep={setStep} values={values} setValues={setValues} />
        )}
        {step === 'step3' && <C.JoinStep3 setStep={setStep} values={values} setValues={setValues} />}
        {step === 'step4' && <C.JoinStep4 setStep={setStep} />}
      </S.Div>
    </>
  );
}
