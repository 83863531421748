import { create } from 'zustand'
import { produce } from 'immer'

const useSettingsStore = create((set) => ({
  pushNotificationEnabled: false,
  activity: false,
  beaconYN: false,
  pointOption: false,
  
  appSettings: {
    appExeCnt: localStorage.getItem('appSetting.appExeCnt'),
    fcm_token: localStorage.getItem('appSetting.fcm_token'),
    fcmYN: localStorage.getItem('appSetting.fcmYN'),
    stepYN: localStorage.getItem('appSetting.stepYN'),
    gpsYN: localStorage.getItem('appSetting.gpsYN'),
    beaconYN: localStorage.getItem('appSetting.beaconYN'),
    point_option: localStorage.getItem('appSetting.point_option'),
    activity: localStorage.getItem('appSetting.activity'),
    frend: localStorage.getItem('appSetting.frend'),
    battery: localStorage.getItem('appSetting.battery'),
    appIsFirst: localStorage.getItem('appSetting.appIsFirst'),
    app_token: localStorage.getItem('appSetting.app_token'),
    app_version: localStorage.getItem('appSetting.app_version'),
    app_model_no: localStorage.getItem('appSetting.app_model_no'),
    beaconList: localStorage.getItem('appSetting.beaconList'),
    eventList: localStorage.getItem('appSetting.eventList'),
    auto_mem_id: localStorage.getItem('appSetting.auto_mem_id'),
    auto_logout: localStorage.getItem('appSetting.auto_logout'),
    ci: localStorage.getItem('appSetting.ci'),
    brithday: localStorage.getItem('appSetting.brithday'),
    di: localStorage.getItem('appSetting.di'),
    gender: localStorage.getItem('appSetting.gender'),
    name: localStorage.getItem('appSetting.name'),
    nation: localStorage.getItem('appSetting.nation'),
    news_agency: localStorage.getItem('appSetting.news_agency'),
    mem_id: localStorage.getItem('appSetting.mem_id'),
    mem_pw: localStorage.getItem('appSetting.mem_pw'),
    mem_nick_nm: localStorage.getItem('appSetting.mem_nick_nm'),
    mem_mobile_num: localStorage.getItem('appSetting.mem_mobile_num'),
    mem_email: localStorage.getItem('appSetting.mem_email'),
    mem_postNum: localStorage.getItem('appSetting.mem_postNum'),
    mem_addr1: localStorage.getItem('appSetting.mem_addr1'),
    mem_addr2: localStorage.getItem('appSetting.mem_addr2'),
    mem_date_of_birth: localStorage.getItem('appSetting.mem_date_of_birth'),
    mem_ko_nm: localStorage.getItem('appSetting.mem_ko_nm'),
    mem_law: localStorage.getItem('appSetting.mem_law'),
    mem_gender: localStorage.getItem('appSetting.mem_gender'),
    mem_age: localStorage.getItem('appSetting.mem_age'),
    mem_cn_yn: localStorage.getItem('appSetting.mem_cn_yn'),
    mem_sns_login_yn: localStorage.getItem('appSetting.mem_sns_login_yn'),
    mem_day_goal_step: localStorage.getItem('appSetting.mem_day_goal_step'),
    login_type: localStorage.getItem('appSetting.login_type'),
    sns_token_yn: localStorage.getItem('appSetting.sns_token_yn'),
    sns_user_pw: localStorage.getItem('appSetting.sns_user_pw'),
    sns_user_id: localStorage.getItem('appSetting.sns_user_id'),
    sns_email: localStorage.getItem('appSetting.sns_email'),
    sns_nickname: localStorage.getItem('appSetting.sns_nickname'),
    get_sns_profileImagePath: localStorage.getItem('appSetting.get_sns_profileImagePath'),
    sendDate: localStorage.getItem('appSetting.sendDate'),
    send_time: localStorage.getItem('appSetting.send_time'),
    setpWho: localStorage.getItem('appSetting.setpWho'),
    stepCount: localStorage.getItem('appSetting.stepCount'),
    stepCountSubtract: localStorage.getItem('appSetting.stepCountSubtract'),
    gpsSendStepCount: localStorage.getItem('appSetting.gpsSendStepCount'),
    sendStepCount: localStorage.getItem('appSetting.sendStepCount'),
    noStepCount: localStorage.getItem('appSetting.noStepCount'),
    step_type: localStorage.getItem('appSetting.step_type'),
    last_step: localStorage.getItem('appSetting.last_step'),
    devcie_name: localStorage.getItem('appSetting.devcie_name'),
    device_address: localStorage.getItem('appSetting.device_address'),
    devcie_name_watch: localStorage.getItem('appSetting.devcie_name_watch'),
    device_address_watch: localStorage.getItem('appSetting.device_address_watch'),
    devcie_hrm: localStorage.getItem('appSetting.devcie_hrm'),
    device_info: localStorage.getItem('appSetting.device_info'),
    polar_expires_in: localStorage.getItem('appSetting.polar_expires_in'),
    polar_x_user_id: localStorage.getItem('appSetting.polar_x_user_id'),
    polar_scope: localStorage.getItem('appSetting.polar_scope'),
    polar_token_type: localStorage.getItem('appSetting.polar_token_type'),
    polar_refresh_token: localStorage.getItem('appSetting.polar_refresh_token'),
    polar_access_token: localStorage.getItem('appSetting.polar_access_token'),
    polar_access_token_raw_response: localStorage.getItem('appSetting.polar_access_token_raw_response'),
    misfitToken: localStorage.getItem('appSetting.misfitToken'),
    token: localStorage.getItem('appSetting.token'),
    gtoken: localStorage.getItem('appSetting.gtoken'),
    gsecret: localStorage.getItem('appSetting.gsecret'),
    grtoken: localStorage.getItem('appSetting.grtoken'),
    lon: localStorage.getItem('appSetting.lon'),
    lat: localStorage.getItem('appSetting.lat'),
    friendListData: localStorage.getItem('appSetting.friendListData'),
    friendList: localStorage.getItem('appSetting.friendList'),
    FriendSelectedList: localStorage.getItem('appSetting.FriendSelectedList'),
    friendStep: localStorage.getItem('appSetting.friendStep'),
    memberId: localStorage.getItem('appSetting.memberId'),
    username: localStorage.getItem('appSetting.username'),
    profileUrl: localStorage.getItem('appSetting.profileUrl'),
    nickname: localStorage.getItem('appSetting.nickname'),
    tel: localStorage.getItem('appSetting.tel'),
    communityID: localStorage.getItem('appSetting.communityID'),
    setAlarmDate: localStorage.getItem('appSetting.setAlarmDate'),
    foodHistory: localStorage.getItem('appSetting.foodHistory'),
    memb_seq: localStorage.getItem('appSetting.memb_seq'),
    memb_id: localStorage.getItem('appSetting.memb_id'),
    memb_hash: localStorage.getItem('appSetting.memb_hash'),
    memb_names: localStorage.getItem('appSetting.memb_names'),
    app_popup_id: localStorage.getItem('appSetting.app_popup_id'),
    app_popup_check_yn: localStorage.getItem('appSetting.app_popup_check_yn'),
    newCommunityList: localStorage.getItem('appSetting.newCommunityList'),
    beaconListData: localStorage.getItem('appSetting.beaconListData'),
    patron_model: localStorage.getItem('appSetting.patron_model'),
    patron_model_watch: localStorage.getItem('appSetting.patron_model_watch'),
    backgPermi: localStorage.getItem('appSetting.backgPermi'),
    backgPermiCheck: localStorage.getItem('appSetting.backgPermiCheck'),
    campaign_passwd: localStorage.getItem('appSetting.campaign_passwd'),
    challenge_passwd: localStorage.getItem('appSetting.challenge_passwd'),
    cns_agree: localStorage.getItem('appSetting.cns_agree'),
    today: localStorage.getItem('appSetting.today'),
    goalToday: localStorage.getItem('appSetting.goalToday'),
    select_yn: localStorage.getItem('appSetting.select_yn'),
    boardFriendList: localStorage.getItem('appSetting.boardFriendList'),
    walking: localStorage.getItem('appSetting.walking'),
    FirecrackerDate: localStorage.getItem('appSetting.FirecrackerDate'),
    PointCheckDate: localStorage.getItem('appSetting.PointCheckDate'),
    SensorCheckDate: localStorage.getItem('appSetting.SensorCheckDate'),
    RewardsPointCheckDate: localStorage.getItem('appSetting.RewardsPointCheckDate'),
    communityNoticePush: localStorage.getItem('appSetting.communityNoticePush'),
    communityBoardPush: localStorage.getItem('appSetting.communityBoardPush'),
    communityChallengePush: localStorage.getItem('appSetting.communityChallengePush'),
    communityReplyPush: localStorage.getItem('appSetting.communityReplyPush'),
    pushNum: localStorage.getItem('appSetting.pushNum'),
    pushQNA: localStorage.getItem('appSetting.pushQNA'),
    pushNOTICE: localStorage.getItem('appSetting.pushNOTICE'),
    member_location: localStorage.getItem('appSetting.member_location'),
    CommunityList: localStorage.getItem('appSetting.CommunityList'),
    ChallengeHistoryList: localStorage.getItem('appSetting.ChallengeHistoryList'),
    cns_reboot: localStorage.getItem('appSetting.cns_reboot'),
  },

  setPushNotificationEnabled: (pushNotificationEnabled) => set(() => ({ pushNotificationEnabled })),
  setActivity: (activity) => set(() => ({ activity })),
  setBeaconYN: (beaconYN) => set(() => ({ beaconYN })),
  setPointOption: (pointOption) => set(() => ({ pointOption })),
  
  setAppSettings: (appSettings) => set(() => ({ appSettings })),

  setAppSetting: (name, value) => {
    localStorage.setItem(`appSetting.${name}`, value);

    set(
      produce((state) => {
        state.appSettings[name] = value;
      })
    )
  
  },
}))

export default useSettingsStore;