import React, { useState } from 'react';
import { Container } from 'layouts';
import * as C from 'components';
import * as S from 'styles';
import * as D from 'data';
import { useNavigate } from 'react-router-dom';

export function CommunitySetting(props) {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Container title="커뮤니티 설정" $padding="0">
        <S.Div $padding="20px">
          <C.Img src="CommunityImg" $height={160} />
          <S.Div $pBottom={16}>
            <C.Badge
              $shadow="lineShadow"
              $bgColor={
                D.areaList.find((area) => area.name === '충남체육회')?.color ||
                D.areaList.find((area) => area.name === '기타').color
              }
            >
              충남체육회
            </C.Badge>
            <S.Text $padding="4px 0" $type="b20" $color="gray900">
              충남체육회 공식 커뮤니티
            </S.Text>
            <S.RowDiv $align="center" $gap={4}>
              <C.Icon name="Users" $pathFill="gray500" $width={16} $height={16} />
              <S.Text $color="gray500" $type="label12">
                38,918 참여중
              </S.Text>
            </S.RowDiv>
          </S.Div>
        </S.Div>
        <S.Div $height={8} $width="100%" $bgColor="fill03" />
        <S.Div $padding="20px">
          <S.Text $type="m14" $color="gray600">
            모임 알림
          </S.Text>
          <S.List $pBottom={16} $borderBottom>
            <S.RowListItem $padding="15px 8px" $align="center" $justify="space-between">
              <div>
                <S.Text $type="m16" $color="gray900">
                  활동 알림
                </S.Text>
                <S.Text $type="m12" $color="gray500" $whiteSpace="pre">
                  작성한 글, 좋아요, 댓글 등 알림
                </S.Text>
              </div>
              <C.Switch />
            </S.RowListItem>
            <S.RowListItem $padding="15px 8px" $align="center" $justify="space-between">
              <div>
                <S.Text $type="m16" $color="gray900">
                  새 글 알림
                </S.Text>
                <S.Text $type="m12" $color="gray500" $whiteSpace="pre">
                  새로운 게시글 알림
                </S.Text>
              </div>
              <C.Switch />
            </S.RowListItem>
          </S.List>
          <S.Div $padding="16px 0" $borderBottom>
            <S.Text $type="m14" $color="gray600">
              프로필
            </S.Text>
            <S.Div $padding="15px 8px" onClick={() => navigate('/community/profile', { state: { type: 'edit' } })}>
              <S.Text $type="m16" $color="gray900">
                내 프로필
              </S.Text>
              <S.Text $type="m12" $color="gray500" $whiteSpace="pre">
                내 프로필 확인 및 수정
              </S.Text>
            </S.Div>
          </S.Div>
          <S.Div $pTop={16}>
            <S.Text $type="m14" $color="gray600">
              기타
            </S.Text>
            <S.Div $padding="15px 8px" onClick={() => navigate(`friend`)}>
              <S.Text $type="m16" $color="gray900">
                친구추천 이력
              </S.Text>
              <S.Text $type="m12" $color="gray500" $whiteSpace="pre">
                나의 추천 친구 확인
              </S.Text>
            </S.Div>
            <S.Div $padding="15px 8px" onClick={() => setModalOpen(true)}>
              <S.Text $type="m16" $color="alert">
                커뮤니티 나가기
              </S.Text>
            </S.Div>
          </S.Div>
        </S.Div>
      </Container>
      <C.Modal
        open={modalOpen}
        $width={312}
        $btn1={{
          text: '취소',
          onClick: () => {
            setModalOpen(false);
          },
        }}
        $btn2={{
          text: '예, 나가겠습니다.',
          color: 'alert',
          onClick: () => {
            setModalOpen(false);
            navigate('/community');
          },
        }}
      >
        <S.Text $textAlign="center" $color="strong">
          정말로 커뮤니티를
          <br />
          나가시겠습니까?
        </S.Text>
      </C.Modal>
    </>
  );
}
