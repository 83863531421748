import React, { useEffect, useState } from 'react';
import { couponList } from 'data/pointData';
import { useNavigate } from 'react-router-dom';
import { CouponListView } from './CouponListView';

export function CouponList(props) {
  const navigate = useNavigate();
  const [list, setList] = useState();

  useEffect(() => {
    if (couponList) {
      setList(couponList);
    }
  }, []);

  return <CouponListView list={list} navigate={navigate} />;
}
