import React from 'react';
import { Text } from 'styles';
import * as C from 'components';
import * as S from './CheckboxStyle';

export function Checkbox({ name, value, onChange, children, checked, required, disabled, type }) {
  const handleOnChange = (e) => {
    const { name, checked } = e.target;
    onChange(name, checked);
  };

  return (
    <S.Label htmlFor={name} required={required} $disabled={disabled} $checked={checked} $children={children}>
      <S.Checkbox
        id={name}
        name={name}
        value={value}
        checked={checked || false}
        onChange={handleOnChange}
        disabled={disabled}
      />
      <S.IconDiv $type={type} $disabled={disabled} $checked={checked || false}>
        <C.Icon name="Check" />
      </S.IconDiv>
      {children && (
        <Text $type={(type === 'sub' && '14') || 'm'} $color="gray800">
          {children}
        </Text>
      )}
    </S.Label>
  );
}

export function ButtonCheckbox({ name, value, onChange, children, checked, disabled }) {
  const handleOnChange = (e) => {
    const { value, checked } = e.target;
    onChange(name, value, checked);
  };

  return (
    <S.BtnCheckLabel $disabled={disabled} $checked={checked || false}>
      <S.Checkbox
        id={`${name}-${value}`}
        name={name}
        value={value}
        checked={checked || false}
        onChange={handleOnChange}
        disabled={disabled}
      />
      {children && (
        <Text $type="m14" $color="gray700">
          {children}
        </Text>
      )}
    </S.BtnCheckLabel>
  );
}
