import React, { useState } from 'react';
import * as C from 'components';
import { Div, Span, Text } from 'styles';
import * as S from './InputStyle';
import { InputLabel } from '../InputLabel';

export function Input({
  name,
  value,
  label,
  type,
  onChange,
  required,
  readonly,
  disabled,
  placeholder,
  icon,
  $invalid,
  $hideErrorText,
  $code,
  $height,
  maxLength,
  setRef,
  onKeyDown,
  resend,
  formatTime,
  settingTime,
  $hide,
  ...props
}) {
  const [tempType, setTempType] = useState(type || 'text');

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    const maxLength = props.maxLength || 0;
    const valueMaxLength = maxLength ? value.substring(0, maxLength) : value;
    const tempValue = type === 'tel' ? valueMaxLength.replaceAll(/\D/g, '') : valueMaxLength;

    onChange(name, tempValue);
  };

  const onKeyDownHandler = (event) => {
    if (onKeyDown) {
      if (event.key === 'Enter') {
        onKeyDown();
      }
    }
  };

  return (
    <Div {...props}>
      {label && <InputLabel $disabled={disabled} required={required} label={label} />}
      <S.InputBox $disabled={disabled} $invalid={$invalid?.type === 'error'} $height={$height}>
        {icon && <C.Icon name={icon} />}
        <S.CustomInput
          ref={setRef}
          name={name}
          value={value}
          type={tempType !== 'tel' ? tempType : 'text'}
          onChange={handleOnChange}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          onKeyPress={onKeyDownHandler}
          inputMode={type === 'tel' ? 'numeric' : 'text'}
          pattern={type === 'tel' ? '[0-9]*' : undefined}
          readOnly={readonly}
        />
        {/* auth code ->  */}
        {$code && (
          <S.CodeSpan $visibility={settingTime > 0 ? 'visible' : 'hidden'} $color="primary300" $type="14">
            {formatTime(settingTime)}
          </S.CodeSpan>
        )}
        {/* <- auth code */}
        {/* maxLength -> */}
        {!$hide && maxLength && (
          <Text $type="label12" $color="gray500" $whiteSpace="pre">
            <Span $type="label12" $color="gray700">
              {value.length}&nbsp;
            </Span>
            / {maxLength}
          </Text>
        )}
        {/* <- maxLength */}
      </S.InputBox>
      {($invalid?.type === 'error' || $invalid?.type === 'success' || $invalid?.type === 'helper') &&
        !$hideErrorText && (
          <Text
            $padding="4px 8px 10px 8px"
            $type="12"
            $color={$invalid.type === 'error' ? 'alert' : $invalid.type === 'success' ? 'assistive' : 'supportive'}
          >
            {$invalid.text}
          </Text>
        )}
    </Div>
  );
}
