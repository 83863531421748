import React from 'react';
import { Div, RowDiv, Text } from 'styles';
import { Img } from 'components/common';
import { Button } from 'components/button';
import { useNavigate } from 'react-router-dom';

export function Failed({ type }) {
  const navigate = useNavigate();
  return (
    <>
      <Div $gap={16} $height="calc(100svh - 152px)">
        <Div $width={96} $height={96} $margin="auto">
          <Img src="failed" alt="실패" />
        </Div>
        <Text $type="m24" $color="black" $textAlign="center" $padding="16px 0">
          {type === 'id' ? '아이디 찾기 실패' : '비밀번호 재설정 실패'}
        </Text>
        {type === 'id' ? (
          <>
            <Text $textAlign="center">
              조회되는 회원정보가 없습니다.
              <br />
              회원가입 후 이용하실 수 있습니다.
            </Text>
          </>
        ) : (
          <Text $textAlign="center">
            알수 없는 오류로 재설정에 실패했습니다.
            <br />
            다시 시도해 주세요.
          </Text>
        )}
      </Div>
      <RowDiv $gap={type === 'id' && 8}>
        <Button $size="l" $type="sub" onClick={() => navigate('/')}>
          홈으로
        </Button>
        {type === 'id' && (
          <Button $size="l" onClick={() => navigate('/join')}>
            회원가입
          </Button>
        )}
      </RowDiv>
    </>
  );
}
