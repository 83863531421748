import React from 'react';
import { Div, RowDiv, Span, Text } from 'styles';
import { Img } from 'components/common';
import { Button } from 'components/button';
import { useNavigate } from 'react-router-dom';

export function Success({ type }) {
  const navigate = useNavigate();
  return (
    <>
      <Div $gap={16} $height="calc(100svh - 152px)">
        <Div $width={96} $height={96} $margin="auto">
          <Img src="success" alt="성공" />
        </Div>
        <Text $type="m24" $color="black" $textAlign="center" $padding="16px 0">
          {type === 'id' ? '아이디 찾기 완료' : '비밀번호 재설정 완료'}
        </Text>
        {type === 'id' ? (
          <>
            <Text $textAlign="center">
              고객님의 아이디는
              <Span $type="b" $color="gray900">
                &nbsp;ABCDE123&nbsp;
              </Span>
              입니다.
            </Text>
          </>
        ) : (
          <Text $textAlign="center">
            고객님의 비밀번호 재설정이 완료되었습니다.
            <br />
            다시 로그인 해주시기 바랍니다.
          </Text>
        )}
      </Div>
      <RowDiv $gap={8}>
        <Button $size="l" $type="sub" onClick={() => navigate('/')}>
          홈으로
        </Button>
        <Button $size="l" onClick={() => navigate('/login')}>
          로그인
        </Button>
      </RowDiv>
    </>
  );
}
