import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HistoryDetailView } from './HistoryDetailView';

export function HistoryDetail(props) {
  const location = useLocation();
  const item = location.state;
  const [toast, setToast] = useState('');

  return <HistoryDetailView item={item} toast={toast} setToast={setToast} />;
}
