import { isWindowAvailable } from 'utils';

export const isAndroid = () => {
  const result = window.android !== undefined;
  return result;
};

export const isIOS = () => {
  const result = window.webkit !== undefined;
  return result;
};

export const isBrowser = () => {
  const result = isWindowAvailable();
  return result;
};

const updateLocation = (latitude, longitude) => {
  if (!isBrowser()) {
    return;
  }

  window.dispatchEvent(new CustomEvent('updatelocation', { detail: { latitude, longitude } }));
};

const changeLocTermState = (state) => {
  const dispatchLocTermConfigEvent = (allowed) => {
    window.dispatchEvent(new CustomEvent('getLocTermConfig', { detail: { allowed } }));
  };

  const successCallback = (position) => {
    const { latitude, longitude } = position.coords || {};

    updateLocation(latitude, longitude);
    dispatchLocTermConfigEvent(true);
  };
  const errorCallback = () => {
    dispatchLocTermConfigEvent(false);
  };
  const options = {
    enableHighAccuracy: true,
    maximumAge: 0,
  };

  switch (state) {
    case 'prompt':
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
      break;
    case 'denied':
      dispatchLocTermConfigEvent(false);
      break;
    case 'granted':
      dispatchLocTermConfigEvent(true);
      break;
    default:
      break;
  }
};

const getBrowserGeoLocation = () => {
  if (!isBrowser()) {
    return;
  }

  navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
    const state = permissionStatus.state || {};

    // Add event listener
    permissionStatus.onchange = () => {
      changeLocTermState(permissionStatus.state);
    };

    changeLocTermState(state);
  });
};

export const nativeFunctions = {
  /**
   * @example window.dispatchEvent(new CustomEvent("getLocTermConfig", { detail: { allowed: true } }))
   */
  getLocTermConfig: () => {
    if (isAndroid()) {
      window.android.requestLocTermConfig();
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "GET_LOC_TERM",
      });
    } else {
      getBrowserGeoLocation();
    }
  },

  getLocation: () => {
    if (isAndroid()) {
      window.android.requestCurrentLocation();
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "GET_LOCATION",
      });
    }
  },


  openPhoneVerify: (str) => {
    if (isAndroid()) {
      window.android.openPhoneVerifyPopup();
    } else if (isIOS()) {
      // TODO
    }
  }
};

export default nativeFunctions;
