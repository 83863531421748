import React, { useState } from 'react';
import * as C from 'components';
import { Div, Text } from 'styles';
import * as S from './DropdownStyle';

export function Dropdown({ title, list, onChange, value, name, placeholder, $invalid, $hideErrorText, disabled, onClose }) {
  const [open, setOpen] = useState(false);

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  const handleOnChange = (item) => {
    handleOnClose();
    
    onChange(name, item.value);
  };

  const toggleDropdown = () => {
    setOpen((prev) => !prev);
  };

  const selected = list.find((item) => item.value === value)?.name;

  return (
    <>
      <Div>
        <S.DropDiv onClick={toggleDropdown} $invalid={$invalid?.type} $disabled={disabled}>
          <Text $color={disabled ? 'disabled' : selected ? 'gray900' : 'supportive'}>{selected || placeholder}</Text>
          <C.Icon name="FormArrow" />
        </S.DropDiv>
        {($invalid?.type === 'error' || $invalid?.type === 'success') && !$hideErrorText && (
          <Text $padding="4px 8px 10px 8px" $type="12" $color={$invalid.type === 'error' ? 'alert' : 'assistive'}>
            {$invalid.text}
          </Text>
        )}
      </Div>
      {!disabled && (
        <C.BottomSheet open={open} setOpen={handleOnClose}>
          <S.ConDiv>
            <Text $type="b14" $color="gray600">
              {title}
            </Text>
            <S.List $count={list?.length}>
              {list.map((item, index) => (
                <S.ListItem
                  key={`item_${index}`}
                  $selected={(item.value || item) === value}
                  onClick={() => handleOnChange(item)}
                >
                  <Text>{item.name}</Text>
                </S.ListItem>
              ))}
            </S.List>
          </S.ConDiv>
        </C.BottomSheet>
      )}
    </>
  );
}
