const colors = {
  primary900: '#005CA1',
  primary800: '#007CC2',
  primary700: '#008DD6',
  primary600: '#00A0EA',
  primary500: '#00AEF8',
  primary: '#00BBF9',
  primary300: '#46C7F9',
  primary200: '#7ED7FB',
  primary100: '#B2E7FC',
  primary50: '#E1F6FE',

  secondary900: '#EF7B22',
  secondary800: '#F5A32E',
  secondary700: '#F8BB34',
  secondary600: '#FBD23B',
  secondary500: '#FEE540',
  secondary: '#FDE759',
  secondary300: '#FEEC77',
  secondary200: '#FFF19D',
  secondary100: '#FFF7C4',
  secondary50: '#FFFCE7',

  mint: '#00ECC1',

  dark: '#9E9FA0',
  lighter: '#E1E2E4 ',
  lightest: '#F3F4F6',
  gray900: '#252729',
  gray800: '#46494B',
  gray700: '#66686B',
  gray600: '#7A7D7F',
  gray500: '#A4A6A9',
  gray400: '#C2C4C8',
  gray300: '#E4E7EA',
  gray200: '#EFF2F5',
  gray100: '#F4F7FA',
  gray50: '#F9FBFF',

  white70: 'rgba(255,255,255,0.7)',
  white50: 'rgba(255,255,255,0.5)',
  white33: 'rgba(255,255,255,0.33)',
  white15: 'rgba(255,255,255,0.15)',
  white05: 'rgba(255,255,255,0.5)',

  dimSoft: 'rgba(0,0,0,0.4)',
  dimHard: 'rgba(0,0,0,0.6)',

  strong: '#171717',
  normal: 'rgba(23,23,23,0.87)',
  assistive: 'rgba(23,23,23,0.7)',
  supportive: 'rgba(23,23,23,0.4)',
  disabled: 'rgba(23,23,23,0.15)',

  fill20: 'rgba(23,23,23,0.2)',
  fill12: 'rgba(23,23,23,0.12)',
  fill05: 'rgba(23,23,23,0.05)',
  fill03: 'rgba(23,23,23,0.03)',
  fill02: 'rgba(23,23,23,0.02)',

  alert: '#F14841',
  alertHover: '#CF2922',
  alertFocus: '#C62721',
  warning: '#FBB54A',
  success: '#3CCB39',
  toast: 'rgba(23, 23, 23, 0.78)',

  shadows1: '0px 1px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
  shadows2: '0px 2px 6px 1px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
  shadows3: '0px 3px 8px 2px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
  shadows4: '0px 3px 10px 2px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
  shadows5: '0px 4px 10px 3px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
  shadows6: '0px 4px 10px 4px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
  lineShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.08);',
};

export const theme = {
  colors,
};
