import styled, { css } from 'styled-components';
import { common, flex, spacing, typography, border } from './setting';
import { theme } from './theme';

const mixed = css`
  ${spacing}
  ${common}
  ${flex}
  ${border}
`;

export const Section = styled.section`
  ${mixed}
`;

export const rowCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const colCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// div
export const Div = styled.div`
  ${mixed}
`;

export const RowDiv = styled(Div)`
  display: flex;
`;

export const RowCenterDiv = styled(RowDiv)`
  align-items: center;
  justify-content: center;
`;

export const ColDiv = styled(Div)`
  display: flex;
  flex-direction: column;
`;

export const ColCenterDiv = styled(ColDiv)`
  align-items: center;
  justify-content: center;
`;

export const ScrollDiv = styled(Div)`
  overflow-y: auto;
`;

export const LineDiv = styled(Div)`
  line-height: 0;
  border: 1px solid ${theme.colors.gray200};
  border-radius: 10px;
  padding: ${({ $padding }) => ($padding && $padding) || '15px'};
  ${({ $shadow }) => $shadow && `box-shadow:${theme.colors.lineShadow}`};
`;

// ul
export const List = styled.ul`
  ${mixed}
`;

export const RowList = styled(List)`
  display: flex;
`;

export const ColList = styled(List)`
  display: flex;
  flex-direction: column;
  ${({ $shadow }) => $shadow && `box-shadow:${theme.colors.lineShadow}`};
`;

export const GridList = styled(List)`
  display: grid;
  grid-template-columns: ${({ $grid }) => `repeat(${$grid || 2}, 1fr)`};
`;

export const ListItem = styled.li`
  ${mixed}
`;

export const RowListItem = styled(ListItem)`
  display: flex;
`;

export const ColListItem = styled(ListItem)`
  display: flex;
  flex-direction: column;
`;

// typography
const typo = css`
  ${typography}
  ${spacing}
  ${common}
`;

export const H2 = styled.h2`
  ${typo}
`;

export const H3 = styled.h3`
  ${typo}
`;

export const Text = styled.p`
  ${typo}
`;

export const Span = styled.span`
  ${typo}
`;
