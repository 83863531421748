import * as C from 'components';
import { Container } from 'layouts';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HttpRequest from 'services/HttpRequest';
import { getNoticeDetail } from 'services/notice/NoticeService';
import * as S from 'styles';
import { formatApiDate } from 'utils';

export function NoticeDetail(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const { state } = location;

	const [isLoading, setIsLoading] = useState(true);
	const [noticeDetail, setNoticeDetail] = useState({});

	const fetchNoticeDetail = async (id) => {
		const response = await getNoticeDetail(id);

		setIsLoading(false);

		if (!response) {
			return;
		}

		setNoticeDetail(response);
	};

	useEffect(() => {
		if (!state || !state.id) {
			return;
		}

		fetchNoticeDetail(state.id);
	}, [state]);

	return (
		<>
			{isLoading && <C.Loading />}
			{!isLoading && (
				<Container title={noticeDetail.title}>
					<S.Div $minHeight="calc(100% - 50px)">
						<S.Div $pBottom={15} $borderBottom>
							<S.Text $type="b18" $color="gray900">
								{noticeDetail.title}
							</S.Text>
							{noticeDetail.createdDate && (
								<S.Text $pTop={4} $type="14" $color="gray500">
									{formatApiDate(noticeDetail.createdDate, 'YYYY.MM.DD')}
								</S.Text>
							)}
						</S.Div>
						<S.Text $color="gray800" $padding="16px 0 " $whiteSpace="pre-wrap">
							{noticeDetail.content}
						</S.Text>
						{noticeDetail.mainImgUrl && (
							<S.Div $pBottom={20}>
								<C.Img src={HttpRequest.getImageUrl(noticeDetail.mainImgUrl)} />
							</S.Div>
						)}
					</S.Div>
					<C.Button $size="l" $type="sub" $leftIcon="List" $gap={4} onClick={() => navigate(-1)}>
						목록
					</C.Button>
				</Container>
			)}
		</>
	);
}
