import React from 'react';
import * as S from 'styles';
import styled from 'styled-components';

export function ProgressBar({ $height, $percentage, $mTop, $tooltip }) {
  return (
    <>
      {$tooltip && (
        <Tooltip $percentage={$percentage}>
          <S.Text $color="gray900" $type="label12" $whiteSpace="pre">
            {$percentage} %
          </S.Text>
        </Tooltip>
      )}
      <ProgressDiv $height={$height} $percentage={$percentage} $mTop={$mTop} />
    </>
  );
}

const Tooltip = styled(S.Div)`
  padding: 4px 8px;
  background-color: white;
  border-radius: 16px;
  box-shadow: ${S.theme.colors.shadows2};
  width: fit-content;
  position: absolute;
  transform: translateX(-50%);
  bottom: 28px;
  left: ${({ $percentage }) => `calc(${$percentage}% - 4px)`};

  &::before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid white;
  }
`;

const ProgressDiv = styled.div`
  margin-top: ${({ $mTop }) => ($mTop && `${$mTop}px`) || '8px'};
  position: relative;
  background-color: ${S.theme.colors.secondary50};
  width: 100%;
  height: ${({ $height }) => ($height && `${$height}px`) || '20px'};
  border-radius: 15px;
  overflow: hidden;

  &::before {
    content: '';
    height: ${({ $height }) => ($height && `${$height}px`) || '20px'};
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
    ${({ $percentage }) => ` width: ${$percentage}%`};
    background-color: ${S.theme.colors.secondary500};
    transition: width 1s linear;
  }
`;
