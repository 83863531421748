import styled, { css } from 'styled-components';
import { ColCenterDiv, Div, RowDiv, theme } from 'styles';

export const ModalWrap = styled(Div)`
  max-width: 312px;
  min-height: 146px;
  background-color: white;
  border-radius: 10px;
`;

export const ModalConDiv = styled(ColCenterDiv)`
  padding: 20px;
  min-width: 250px;
  min-height: 96px;
`;

export const BtnDiv = styled(RowDiv)`
  border-top: 1px solid ${theme.colors.fill05};
  height: 50px;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
`;

const btnStyle = css`
  flex: 5;
  color: ${theme.colors.gray700};
  background-color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
`;

export const Btn1 = styled.button`
  ${btnStyle}
  border-radius: 0 0 0 10px;
`;
export const Btn2 = styled.button`
  ${btnStyle}
  border-radius: 0 0 10px 0;
  border-left: 1px solid ${theme.colors.fill05};
  color: ${({ $color }) => ($color && theme.colors[$color]) || 'gray700'};
`;
