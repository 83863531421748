import * as C from 'components';
import { Container } from 'layouts';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from 'styles';
import { formatApiDate } from 'utils';

export function FaqDetail(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const item = location.state;

	return (
		<Container title={item.title}>
			<S.Div $minHeight="calc(100% - 50px)">
				<S.Div $pBottom={15} $borderBottom>
					<S.Text $type="b18" $color="gray900">
						{item.title}
					</S.Text>
					{item.regDate && (
						<S.Text $pTop={4} $type="14" $color="gray500">
							{formatApiDate(item.regDate, 'YYYY.MM.DD')}
						</S.Text>
					)}
				</S.Div>
				<S.Text $color="gray800" $padding="16px 0 " $whiteSpace="pre-wrap">
					{item.content}
				</S.Text>
			</S.Div>
			<C.Button $size="l" $type="sub" $leftIcon="List" $gap={4} onClick={() => navigate(-1)}>
				목록
			</C.Button>
		</Container>
	);
}
