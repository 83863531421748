import React, { useState } from 'react';
import { sideMenu } from 'data/menuData';
import { useCookie, useLogout } from 'hooks';
import { useNavigate } from 'react-router-dom';
import userAuthenticationStore from 'stores/authentication/AuthenticationStore';

import { Button, Icon, Img, Modal, AppBar } from 'components';
import { Div, List, RowCenterDiv, RowDiv, Span, Text } from 'styles';
import * as S from './HomeBarStyle';

export function HomeBar(props) {
  const isAuthenticated = userAuthenticationStore((state) => state.isAuthenticated);
  const userInfo = userAuthenticationStore((state) => state.userInfo);

  const { getCookie } = useCookie();
  const logout = useLogout();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [sideOpen, setSideOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState();

  const onClick = (menu) => {
    setSelectedMenu((selected) => (selected === menu ? null : menu));
  };

  return (
    <>
      <S.Wrap>
        <Icon name="Menu" onClick={() => setSideOpen(true)} />
        <RowCenterDiv $flex={1} $pLeft={24}>
          <Img src="logo" alt="걷쥬 로고" $width={75} $height={24} />
        </RowCenterDiv>
        <RowDiv $gap={16}>
          <Icon name="Search" />
          <Icon name="Bell" />
        </RowDiv>
      </S.Wrap>
      <S.SideBar $open={sideOpen}>
        <AppBar onClick={() => setSideOpen(false)}>전체메뉴</AppBar>
        <Div $padding="20px">
          <RowDiv $justify="space-between" $align="center" $padding="8px 0">
            <Text $type="20" $color="gray900">
              {isAuthenticated && (
                <Span $type="m24" $color="gray900">
                  {userInfo?.name}&nbsp;
                </Span>
              )}
              회원님,
              <br />
              안녕하세요!
            </Text>
            <RowDiv $gap={8}>
              {(isAuthenticated && (
                <Button type="sub" $size="s" $width={69} onClick={() => setModalOpen(true)}>
                  로그아웃
                </Button>
              )) || (
                <>
                  <Button type="sub" $size="s" $width={69} onClick={() => navigate('login')}>
                    로그인
                  </Button>
                  <Button $size="s" $width={81} onClick={() => navigate('join')}>
                    회원가입
                  </Button>
                </>
              )}
            </RowDiv>
          </RowDiv>
          <S.MenuList>
            {sideMenu.map((menu, index) => (
              <li key={`menu_${index}`}>
                <S.SideMenu $second={index === 1} $selected={selectedMenu === menu} onClick={() => onClick(menu)}>
                  <Icon name={menu.icon} />
                  {menu.name}
                </S.SideMenu>
              </li>
            ))}
          </S.MenuList>
        </Div>
        {selectedMenu && (
          <List $padding="12px 20px">
            {selectedMenu.sub &&
              selectedMenu.sub.map((subMenu, subIndex) => (
                <li key={`bottom_${subIndex}`}>
                  <S.SideSubMenu to={subMenu.path ? `${selectedMenu.path}/${subMenu.path}` : `${selectedMenu.path}`}>
                    {subMenu.name}
                  </S.SideSubMenu>
                </li>
              ))}
          </List>
        )}
      </S.SideBar>
      <Modal
        open={modalOpen}
        $btn1={{ text: '닫기', onClick: () => setModalOpen(false) }}
        $btn2={{
          text: '로그아웃',
          color: 'alert',
          onClick: () => {
            logout();
            setModalOpen(false);
            setSideOpen(false);
          },
        }}
      >
        <Text $textAlign="center">
          로그아웃
          <br />
          하시겠습니까?
        </Text>
      </Modal>
    </>
  );
}
