import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import * as D from 'data';
import styled from 'styled-components';
import { Button } from 'components/button';

export function CommunityBox({ item }) {
  return (
    <CallengeWrap>
      <C.Badge
        className="badge"
        $bgColor={
          D.areaList.find((area) => area.name === item.host)?.color ||
          D.areaList.find((area) => area.name === '기타').color
        }
      >
        {item.host}
      </C.Badge>
      <ImgBox>
        <C.Img src={item.img} $height={130} $objectFit="cover" />
        <InfoBox>
          <C.Icon name="Users" $width={16} $height={16} $pathFill="mint" />
          <S.Text $type="label12" $color="mint">
            {item.join.toLocaleString()}명 참여중
          </S.Text>
        </InfoBox>
      </ImgBox>
      <S.Text $margin="8px 0" $type="b" $color="gray800" $maxLines={1}>
        홍성군 공식 커뮤니티 홍성군 공식 커뮤니티
      </S.Text>
      <Button type={item.state === 1 && 'sub'} $size="m">
        {item.state === 0 ? '참여하기' : '참여중'}
      </Button>
    </CallengeWrap>
  );
}

const CallengeWrap = styled(S.Div)`
  padding: 4px;
  position: relative;
  border-radius: 4px;
  background-color: ${S.theme.colors.fill02};

  .badge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  }
`;

const ImgBox = styled(S.Div)`
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: 1px solid ${S.theme.colors.gray200};
  min-height: 120px;
`;

const InfoBox = styled(S.RowDiv)`
  ${S.rowCenter}
  padding: 2px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.92);
`;
