/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable new-cap */

import React, { useRef, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import * as P from 'pages';
import { Layout } from 'layouts';
import { theme, GlobalStyles } from 'styles';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AlertContextProvider } from 'hooks';

import useSettingsHandler from 'hooks/settings/useSettingsHandler';
import useOldLocalDataMigration from 'hooks/settings/useOldLocalDataMigration';

import { ProtectedPageCommonLayout } from 'layouts/ProtectedPageCommonLayout';

import i18n from './i18n/i18n';

function App() {
  useSettingsHandler();
  useOldLocalDataMigration();

  const initNaverLogin = () =>{
    const naverIdLogin = new window.naver_id_login(
      process.env.REACT_APP_NAVER_LOGIN_CLIENT_ID,
      process.env.REACT_APP_NAVER_LOGIN_CLIENT_CALLBACK_URL,
    );
    const state = naverIdLogin.getUniqState();
    naverIdLogin.setButton('white', 2, 40);
    naverIdLogin.setDomain(process.env.REACT_APP_NAVER_LOGIN_SERVICE_URL);
    naverIdLogin.setState(state);
    naverIdLogin.init_naver_id_login();

  }

  useEffect(() => {
    if (!Kakao || !Kakao.isInitialized()) {
      Kakao.init(process.env.REACT_APP_KAKAO_LOGIN_REST_API_KEY);
    }

    initNaverLogin();
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <AlertContextProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <BrowserRouter>
            <Routes>
              <Route element={<Layout />}>
                <Route path="login" element={<P.Login />} />
                <Route path="join" element={<P.Join />} />
                <Route path="findid" element={<P.FindId />} />
                <Route path="findpassword" element={<P.FindPassword />} />
                <Route path="loginKakaoCallback" element={<P.KaKaoCallback />} />
                <Route path="loginNaverCallback" element={<P.NaverCallback />} />
              </Route>
              <Route element={<ProtectedPageCommonLayout />}>
                <Route path="/" element={<P.Home />} />
                <Route path="/point" element={<P.Point />}>
                  <Route path="" element={<P.PointHome />} />
                  <Route path="mall" element={<P.Mall />} />
                  <Route path="donation" element={<P.DonationList />} />
                  <Route path="donation/:1d" element={<P.DonationDetail />} />
                  <Route path="ad" element={<P.AdList />} />
                  <Route path="history" element={<P.HistoryList />} />
                  <Route path="history/:id" element={<P.HistoryDetail />} />
                  <Route path="coupon" element={<P.CouponList />} />
                  <Route path="coupon/:id" element={<P.CouponDetail />} />
                </Route>
                <Route path="/challenge" element={<P.Challenge />}>
                  <Route path="" element={<P.ChallengeHome />} />
                  <Route path="basic" element={<P.ChallengeList />} />
                  <Route path="basic/:id" element={<P.ChallengeDeatil />} />
                  <Route path="basic/:id/ranking" element={<P.ChallengeRanking />} />
                  <Route path="basic/:id/success" element={<P.ChallengeFile />} />
                  <Route path="stamp" element={<P.StampList />} />
                  <Route path="stamp/:id" element={<P.StampDetail />} />
                  <Route path="stamp/:id/:listId" element={<P.StampDetailList />} />
                  <Route path="stamp/:id/:listId/info" element={<P.StampInfo />} />
                  <Route path="stamp/history/:id" element={<P.StampHistoryDetailList />} />
                  <Route path="stamp/history/:id/:listId" element={<P.StampHistoryDetail />} />
                </Route>
                <Route path="/community" element={<P.CommunityHome />}>
                  <Route path="" element={<P.CommunityList />} />
                  <Route path="profile" element={<P.Profile />} />
                  <Route path=":id" element={<P.MyCommunity />} />
                  <Route path=":id/board" element={<P.CommunityBoard />} />
                  <Route path=":id/myscore" element={<P.MyScore />} />
                  <Route path=":id/setting" element={<P.CommunitySetting />} />
                  <Route path=":id/setting/friend" element={<P.CommunityFriend />} />
                  <Route path=":id/challenge" element={<P.CommunityCallenge />} />
                  <Route path=":id/challenge/notice" element={<P.CommunityNotice />} />
                  <Route path=":id/challenge/notice/:noticeId" element={<P.CommunityNoticeDetail />} />
                  <Route path=":id/challenge/:listId" element={<P.CommunityCallengeDetail />} />
                  <Route path=":id/challenge/ranking" element={<P.CommunityRanking />} />
                </Route>
                <Route path="/my" element={<P.My />}>
                  <Route path="" element={<P.MyHome />} />
                  <Route path="inquiry" element={<P.MyInquiryList />} />
                  <Route path="inquiry/:id" element={<P.MyInquiryDetail />} />
                  <Route path="info" element={<P.MyInfo />} />
                  <Route path="device" element={<P.MyDevice />} />
                </Route>
                <Route path="/center" element={<P.Center />}>
                  <Route path="" element={<P.CenterHome />} />
                  <Route path="notice" element={<P.NoticeList />} />
                  <Route path="notice/:id" element={<P.NoticeDetail />} />
                  <Route path="promo" element={<P.PromoList />} />
                  <Route path="promo/:id" element={<P.PromoDetail />} />
                  <Route path="faq" element={<P.FaqList />} />
                  <Route path="faq/:id" element={<P.FaqDetail />} />
                  <Route path="inquiry" element={<P.CenterInquiry />} />
                </Route>
                <Route path="/setting" element={<P.Setting />}>
                  <Route path="" element={<P.SettingList />} />
                  <Route path="info" element={<P.SettingInfoList />} />
                  <Route path="info/:id" element={<P.SettingInfoDetail />} />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </AlertContextProvider>
    </I18nextProvider>
  );
}

export default App;
