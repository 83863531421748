import React, { useEffect, useState } from 'react';
import * as D from 'data';
import moment from 'moment';
import { useTab } from 'hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ChallengeListView from './ChallengeListView';

export function ChallengeList(props) {
  const initFilters = {
    area: '1',
    period: 'sixMonth',
    challengeName: '',
    startDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [list, setList] = useState(D.challengeList);
  const [historyList, setHistoryList] = useState(D.challengeHistory);
  const [tabIndex, currentTab] = useTab(parseInt(searchParams.get('tab'), 10) || 0);
  const [filters, setFilters] = useState(initFilters);

  const onChange = (name, val) => {
    if (name === 'period') {
      let startDate = '';
      const endDate = moment().format('YYYY-MM-DD');

      if (val === 'sixMonth') {
        startDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
      } else if (val === '1year') {
        startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
      } else if (val === '2year') {
        startDate = moment().subtract(2, 'years').format('YYYY-MM-DD');
      } else if (val === 'direct') {
        startDate = '';
      }

      setFilters((filters) => ({
        ...filters,
        period: val,
        startDate,
        endDate,
      }));
    } else if (name === 'startDate' || name === 'endDate') {
      setFilters((filters) => ({
        ...filters,
        [name]: moment(val).format('YYYY-MM-DD'),
      }));
    } else {
      setFilters((filters) => ({
        ...filters,
        [name]: val,
      }));
    }
  };

  useEffect(() => {
    setFilters(initFilters);
    if (tabIndex === 0) {
      setList(D.challengeList);
    } else {
      setHistoryList(D.challengeHistory);
    }
  }, [tabIndex]);

  useEffect(() => {
    setSearchParams({ tab: tabIndex, ...filters }, { replace: true });
  }, [tabIndex, filters, setSearchParams]);

  return (
    <ChallengeListView
      list={list}
      historyList={historyList}
      navigate={navigate}
      tabIndex={tabIndex}
      currentTab={currentTab}
      onChange={onChange}
      filters={filters}
    />
  );
}
