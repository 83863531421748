import { API_DATETIME_FORMAT, API_DATE_FORMAT} from 'data/constants';
import moment from 'moment';

export const formatApiDateTime = (dateStr, format) => {
	const date = moment(dateStr, API_DATETIME_FORMAT);
	return date.format(format);
};

export const formatApiDate = (dateStr, format) => {
	const date = moment(dateStr, API_DATE_FORMAT);
	return date.format(format);
};

export default {
	formatApiDateTime,
	formatApiDate,
};
