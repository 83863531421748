import { isAndroid, isIOS } from 'mobile-bridge/functions';

export const nativeAppSettingsFunctions = {
  requestPushNotification: (enable) => {
    if (isAndroid()) {
      window.androidSettings.requestPushNotification(enable);
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "REQUEST_PUSH_NOTIFICATION",
        data: enable
      });
    }
  },

  getPushNotificationStatus: () => {
    if (isAndroid()) {
      window.androidSettings.getPushNotificationStatus();
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "GET_PUSH_NOTIFICATION_STATUS"
      });
    }
  },

  getLocalDataMigrated: () => {
    if (isAndroid()) {
      window.androidSettings.getLocalDataMigrated();
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "GET_LOCAL_DATA_MIGRATED"
      });
    }
  },

  setLocalDataMigrated: (value) => {
    if (isAndroid()) {
      window.androidSettings.setLocalDataMigrated(value);
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "SET_LOCAL_DATA_MIGRATED",
        data:  value
      });
    }
  },

  getLocalData: () => {
    if (isAndroid()) {
      window.androidSettings.getLocalData();
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "GET_LOCAL_DATA"
      });
    }
  },

  setSharedPreferenceBoolean: (key, value) => {
    if (isAndroid()) {
      window.androidSettings.setSharedPreferenceBoolean(key, value);
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "SET_SHARED_PREFERENCE",
        key,
        value
      });
    }
  },

  getSharedPreferenceBoolean: (key) => {
    if (isAndroid()) {
      window.androidSettings.getSharedPreferenceBoolean(key);
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "GET_SHARED_PREFERENCE",
        key
      });
    }
  },

  setSharedPreferenceString: (key, value) => {
    if (isAndroid()) {
      window.androidSettings.setSharedPreferenceString(key, value);
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "SET_SHARED_PREFERENCE",
        key,
        value
      });
    }
  },

  getSharedPreferenceString: (key) => {
    if (isAndroid()) {
      window.androidSettings.getSharedPreferenceString(key);
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "GET_SHARED_PREFERENCE",
        key
      });
    }
  },

  setSharedPreferenceInt: (key, value) => {
    if (isAndroid()) {
      window.androidSettings.setSharedPreferenceInt(key, value);
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "SET_SHARED_PREFERENCE",
        key,
        value
      });
    }
  },

  getSharedPreferenceInt: (key) => {
    if (isAndroid()) {
      window.androidSettings.getSharedPreferenceInt(key);
    } else if (isIOS()) {
      window.webkit.messageHandlers.native.postMessage({
        type: "GET_SHARED_PREFERENCE",
        key
      });
    }
  }
};

export default nativeAppSettingsFunctions;
