import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAlertContext from 'hooks/useAlertContext';

import HttpRequest from 'services/HttpRequest';

import serviceFunctions from 'mobile-bridge/serviceFunctions';

const useNativeAppCommandHandler = () => {
  const navigate = useNavigate();
  const { alert, toggleAlert } = useAlertContext();

  useEffect(() => {
    const handleGoToPage = (e) => {
      const { url, dataString } = e.detail || {};
      let state = null;

      if (dataString && dataString.length > 0) {
        try {
          state = JSON.parse(dataString);
        } catch (e) {
          console.error(e);
        }
      }

      navigate(url, state)
    };

    window.addEventListener('goToPage', handleGoToPage);
    return () => window.removeEventListener('goToPage', handleGoToPage);
  }, []);

  useEffect(() => {
    const handleMakeHttpRequest = async (e) => {
      const { id, url, paramsString, bodyString, method } = e.detail || {};

      let params = {};
      let body = {};

      try {
        params = JSON.parse(paramsString);
      } catch (e) {
        console.error(e);
      }

      try {
        body = JSON.parse(bodyString);
      } catch (e) {
        console.error(e);
      }

      const response = await HttpRequest.request(url, params, body, method || 'GET');
    };

    window.addEventListener('makeHttpRequest', handleMakeHttpRequest);
    return () => window.removeEventListener('makeHttpRequest', handleMakeHttpRequest);
  }, []);

  useEffect(() => {
    const handleShowAlertModal = async (e) => {
      const { type, message, btnOkText, btnCancelText } = e.detail || {};

      let $btn2 = null;

      if (btnOkText) {
        $btn2 = {
          text: btnOkText,
          color: 'primary',
          onClick: () => {
            if (type === 'checkBatteryOption') {
              serviceFunctions.openPowerManager();
            }
            if (type === 'requestPermissionBluetoothScan') {
              serviceFunctions.requestPermission('android.permission.BLUETOOTH_SCAN', 2);
            }
            if (type === 'requestPermissionBluetoothScan') {
              serviceFunctions.requestPermission('android.permission.BLUETOOTH_CONNECT', 3);
            }

            toggleAlert(false)
          }
        }
      }

      alert({
        type,
        message,
        $btn1: {text: btnCancelText || '닫기', onClick: () => toggleAlert(false)},
        $btn2: $btn2
      })
    };

    window.addEventListener('showAlertModal', handleShowAlertModal);
    return () => window.removeEventListener('showAlertModal', handleShowAlertModal);
  }, []);
};

export default useNativeAppCommandHandler;