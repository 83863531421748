import React from 'react';
import { Icon } from 'components/common';
import * as S from './ButtonStyle';

export function Button({ children, type, $leftIcon, $rightIcon, $size, $width, $active, disabled, onClick, ...props }) {
  return (
    <S.Btn
      onClick={onClick}
      type={type}
      $type={type}
      $leftIcon={$leftIcon}
      $rightIcon={$rightIcon}
      $size={$size}
      $width={$width}
      $active={$active}
      disabled={disabled}
      {...props}
    >
      {$leftIcon && (
        <Icon
          name={$leftIcon}
          $pathFill={type === 'sub' ? (disabled ? 'disabled' : 'gray700') : disabled ? 'white05' : 'white'}
        />
      )}
      {children}
      {$rightIcon && (
        <Icon
          name={$rightIcon}
          $pathFill={type === 'sub' ? (disabled ? 'disabled' : 'gray700') : disabled ? 'white05' : 'white'}
        />
      )}
    </S.Btn>
  );
}
