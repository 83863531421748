import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import styled from 'styled-components';

export function DonationListView({ list, navigate }) {
  return (
    <Container title="기부통">
      <S.List>
        {list?.map((item, index) => (
          <S.ListItem
            key={`point_donation_${index}`}
            onClick={() => navigate(`${item.id}`, { state: item })}
            $mTop={index !== 0 ? 20 : 0}
            $radius={10}
            $overflow="hidden"
          >
            <S.LineDiv $padding="0" $shadow>
              <S.Div $position="relative">
                <C.Badge
                  $position="absolute"
                  $left={8}
                  $top={8}
                  $zIndex={1}
                  $bgColor={item.state === 0 && 'gray400'}
                  icon="BadgeCheck"
                >
                  {item.state === 0 ? '참여가능' : '참여중'}
                </C.Badge>
                <C.Img src={item.img} $objectFit="cover" $height={138} />
              </S.Div>
              <S.Div $padding="16px">
                <S.Text $type="b20" $color="gray900">
                  {item.name}
                </S.Text>
                <C.ProgressBar $height={8} $percentage={(item.currentPoint / item.targetPoint) * 100} />
                <S.RowDiv $justify="space-between" $mTop={4}>
                  <S.Text $type="b18" $color="secondary700">
                    {(item.currentPoint / item.targetPoint) * 100}%
                  </S.Text>
                  <InfoDiv>
                    <S.RowDiv>
                      <S.Span $type="m14" $color="gray700">
                        현재
                      </S.Span>
                      <S.Text $type="b18" $color="gray900">
                        {item.currentPoint.toLocaleString()}
                      </S.Text>
                      <S.Span $color="gray900">P</S.Span>
                    </S.RowDiv>
                    <S.RowDiv>
                      <S.Span $type="m14" $color="gray700">
                        목표
                      </S.Span>
                      <S.Text $type="m18" $color="gray700">
                        {item.targetPoint.toLocaleString()}
                      </S.Text>
                      <S.Span $color="gray700">P</S.Span>
                    </S.RowDiv>
                  </InfoDiv>
                </S.RowDiv>
              </S.Div>
            </S.LineDiv>
          </S.ListItem>
        ))}
      </S.List>
    </Container>
  );
}

const InfoDiv = styled(S.Div)`
  & > div {
    align-items: center;
    justify-content: flex-end;

    & > p {
      padding: 0 4px 0 8px;
      min-width: 80px;
      text-align: end;
    }
  }

  & > span:nth-child(1) {
    line-height: 14px;
  }
`;
