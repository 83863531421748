import React, { useState } from 'react';
import {useLocation} from 'react-router-dom';


import { JoinView } from './JoinView';

export function Join(props) {
  const [step, setStep] = useState('step1');

  const location = useLocation();
  const loginState = location.state;
  console.log(loginState);

  const sampleData = {
    userName: '홍길동',
    carrier: '2',
    phone: '01012341234',
    phoneCode: '123456',
  };

  const [values, setValues] = useState({
    // step2
    name: '',
    carrier: '',
    tel: '',
    phoneCode: '',
    age: '',
    // step3
    username: loginState?.userId,
    password: '',
    checkPassword: '',
    gender: 'MALE',
    birthday: '',
    zipCode: '',
    address: '',
    detailAddress: '',
    email: loginState?.email,
    memNickNm: loginState?.nickname,
    // goalStep: 0,
    // point: 0
  });

  return <JoinView step={step} setStep={setStep} values={values} setValues={setValues} />;
}
