/* eslint-disable no-prototype-builtins */

import { useEffect } from 'react';

import useSettingsStore from 'stores/settings/SettingsStore';
import {ACCESS_TOKEN, REFRESH_TOKEN} from "data/constants";
import nativeAppSettingsFunctions from 'mobile-bridge/appSettingsFunctions';

const KEY_OLD_LOCAL_DATA_MIGRATED = 'OLD_LOCAL_DATA_MIGRATED';

const useOldLocalDataMigration = () => {
  const setAppSettings = useSettingsStore((state) => state.setAppSettings);

  useEffect(() => {
    const handleOldLocalData = (e) => {
      const localData = e.detail;

      if (localData) {
        for (const property in localData) {
          if (localData.hasOwnProperty(property)) {
            localStorage.setItem(`appSetting.${property}`, localData[property]);
          }
        }

        if (localData.app_token) {
          localStorage.setItem(ACCESS_TOKEN, localData.app_token);
        }

        setAppSettings(localData);
      }

      localStorage.setItem(KEY_OLD_LOCAL_DATA_MIGRATED, true)
      nativeAppSettingsFunctions.setLocalDataMigrated(true);
    };

    window.addEventListener('settings.localData', handleOldLocalData);
    return () => window.removeEventListener('settings.localData', handleOldLocalData);
  }, []);

  useEffect(() => {
    const handleOldLocalDatMigrated = (e) => {
      const { migrated } = e.detail || {};

      const oldLocalDataMigrated = localStorage.getItem(KEY_OLD_LOCAL_DATA_MIGRATED);
      
      if (!migrated && !oldLocalDataMigrated) {
        nativeAppSettingsFunctions.getLocalData();
      }
    };

    window.addEventListener('settings.oldLocalDataMigrated', handleOldLocalDatMigrated);
    return () => window.removeEventListener('settings.oldLocalDataMigrated', handleOldLocalDatMigrated);
  }, []);

  useEffect(() => {
    nativeAppSettingsFunctions.getLocalDataMigrated();
  }, [])
};

export default useOldLocalDataMigration;