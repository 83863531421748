import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import styled from 'styled-components';

export function LoginView({ values, onChange, submit, invalids,
                            btnActive, navigate, handleKakaoLogin, handleNaverLogin }) {
  return (
    <>
      <C.AppBar>로그인</C.AppBar>
      <S.Div $padding="20px">
        <S.Text $type="m" $color="gray900">
          아이디로 로그인
        </S.Text>
        <FormDiv>
          <C.Input
            name="id"
            value={values.id}
            onChange={onChange}
            placeholder="아이디 입력"
            $invalid={invalids.id}
            $hideErrorText
          />
          <C.Input
            name="password"
            type="password"
            value={values.password}
            onChange={onChange}
            placeholder="비밀번호 입력"
            $invalid={invalids.password}
            $hideErrorText
          />
          {(invalids.id.type === 'error' || invalids.password.type === 'error') && (
            <S.Text $padding="4px 8px 10px 8px" $type="12" $color="alert">
              {invalids.id.type === 'error' && invalids.password.type === 'error'
                ? '아이디와 비밀번호를 다시 입력해 주세요.'
                : invalids.id.type === 'error'
                  ? '아이디를 다시 입력해 주세요.'
                  : '비밀번호를 다시 입력해 주세요.'}
            </S.Text>
          )}
        </FormDiv>
        <C.Button $size="m" onClick={submit} disabled={!btnActive}>
          걷쥬 로그인
        </C.Button>
        <BtnDiv>
          <S.Text $type="14" onClick={() => navigate('/join')}>
            회원가입
          </S.Text>
          <S.RowDiv $gap={8}>
            <S.Text $type="14" onClick={() => navigate('/findid')}>
              아이디 찾기
            </S.Text>
            <C.Line $height={8} />
            <S.Text $type="14" onClick={() => navigate('/findpassword')}>
              비밀번호 찾기
            </S.Text>
          </S.RowDiv>
        </BtnDiv>
        <S.Div $pTop={48}>
          <LineText>
            <S.Text $type="14">SNS 로그인</S.Text>
          </LineText>
          <S.ColDiv $pTop={20} $gap={16}>
            <SnsDiv $bgColor="#FEE500" onClick={handleKakaoLogin}>
              <C.Img src="kakao" />
            </SnsDiv>
            <SnsDiv $bgColor="#03C75A" onClick={handleNaverLogin}>
              <C.Img src="naver" />
            </SnsDiv>
          </S.ColDiv>
        </S.Div>
      </S.Div>
    </>
  );
}

const FormDiv = styled(S.ColDiv)`
  padding: 16px 0;
  gap: 8px;
`;

const BtnDiv = styled(S.RowDiv)`
  padding-top: 16px;
  justify-content: space-between;

  p {
    color: ${S.theme.colors.gray500};
  }
`;

const LineText = styled(S.RowCenterDiv)`
  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${S.theme.colors.gray200};
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }

  p {
    width: 155px;
    text-align: center;
    color: ${S.theme.colors.gray400};
  }
`;

const SnsDiv = styled(S.Div)`
  width: 100%;
  height: 40px;
  border-radius: 6px;
`;
