import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import { useRandomColor } from 'hooks';

export function ChallengeRankingBox({ item, tabIndex, list, myIndex, currentTab }) {
  const randomColor = useRandomColor();

  return (
    <Container title={`${item.title} 순위`} $padding="20px 16px">
      <C.Tab list={['전체', '친구']} tabIndex={tabIndex} currentTab={currentTab} />
      {tabIndex === 0 && (
        <>
          <S.Div $padding="16px 0">
            <S.RowDiv $justify="space-between" $align="center">
              <S.RowDiv $align="center" $gap={4}>
                <C.Icon name="Ranking" />
                <S.Text $color="gray700" $type="m14">
                  참여자
                </S.Text>
              </S.RowDiv>
              <S.Text $color="gray900" $type="b24">
                798,145&nbsp;
                <S.Span $color="gray900" $type="20">
                  명
                </S.Span>
              </S.Text>
            </S.RowDiv>
            <S.RowDiv $justify="space-between">
              <S.RowDiv $align="center" $gap={4}>
                <C.Icon name="Ranking" />
                <S.Text $color="gray700" $type="m14">
                  목표 달성자
                </S.Text>
              </S.RowDiv>
              <S.Text $color="gray900" $type="b24">
                6&nbsp;
                <S.Span $color="gray900" $type="20">
                  명
                </S.Span>
              </S.Text>
            </S.RowDiv>
          </S.Div>
          <S.List>
            {list?.map((item, index) => (
              <S.ListItem
                key={`item_${index}`}
                $padding="16px 8px"
                $borderBottom={index !== myIndex + 1 && item !== ' ' && list.length - 1 !== index}
                $bgColor={index === myIndex + 1 && 'gray50'}
                $radius={10}
              >
                {item === ' ' ? (
                  <S.Text $textAlign="center">...</S.Text>
                ) : (
                  <S.RowDiv $align="center" $gap={8}>
                    <S.Text $color="gray700" $textAlign="center" $type="m12" $minWidth={50}>
                      {item.ranking?.toLocaleString()}
                    </S.Text>
                    <S.RowDiv $gap={8} $flex={1} $align="center">
                      <S.Div $width={24} $height={24} $radius="50%" $bgColor={!item.img && randomColor()}>
                        <C.Img src={item.img || 'randomUser'} />
                      </S.Div>
                      <S.Text $color="gray700" $type="14">
                        {index === myIndex + 1 ? '나' : item.userName}
                      </S.Text>
                    </S.RowDiv>
                    <S.Text $color={index === myIndex + 1 ? 'primary' : 'gray700'} $type="m">
                      {item.walk?.toLocaleString()}
                    </S.Text>
                  </S.RowDiv>
                )}
              </S.ListItem>
            ))}
          </S.List>
        </>
      )}
      {tabIndex === 1 && (
        <>
          <S.Div $padding="16px 0">
            <S.List>
              {list?.map((item, index) => (
                <S.ListItem
                  key={`item_${index}`}
                  $padding="16px 8px"
                  $borderBottom={index !== myIndex + 1 && item !== ' ' && list.length - 1 !== index}
                  $bgColor={index === myIndex + 1 && 'gray50'}
                  $radius={10}
                >
                  <S.RowDiv $align="center" $gap={8}>
                    <S.Text $color="gray700" $textAlign="center" $type="m12" $minWidth={50}>
                      {item.ranking?.toLocaleString()}
                    </S.Text>
                    <S.RowDiv $gap={8} $flex={1} $align="center">
                      <S.Div $width={24} $height={24} $radius="50%" $bgColor={!item.img && randomColor()}>
                        <C.Img src={item.img || 'randomUser'} />
                      </S.Div>
                      <S.Text $color="gray700" $type="14">
                        {item.userName}
                      </S.Text>
                    </S.RowDiv>
                    <S.Text $color="gray700" $type="m">
                      {item.walk?.toLocaleString()}
                    </S.Text>
                  </S.RowDiv>
                </S.ListItem>
              ))}
            </S.List>
          </S.Div>
        </>
      )}
    </Container>
  );
}
