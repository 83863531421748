import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import styled from 'styled-components';

export function CouponDetailView({ item }) {
  return (
    <Container title="쿠폰함" $padding="0">
      <S.Div $padding="20px">
        <S.LineDiv $shadow>
          <C.Img src={item.img} alt="쿠폰" $height={82} $padding="20px 0" />
          <S.Text $pTop={8} $type="b20" $color="gray900">
            {item.name}
          </S.Text>
          <S.Text $pTop={8} $type="m12" $color="gray600" $lineHeight="14px">
            구매일자 : {item.createDate}
          </S.Text>
        </S.LineDiv>
        <S.Div $padding="20px 0">
          <S.Text $type="m12" $color="gray600">
            쿠폰 유효기간
          </S.Text>
          <S.Text $type="b" $color="gray900">
            {item.startDate} ~ {item.endDate}
          </S.Text>
          <C.Img src={item.barcode} alt="barcode" $padding="30px 37px 7px" />
        </S.Div>
      </S.Div>
      <S.Div $bgColor="fill03" $padding="16px 20px">
        <S.Text $type="b14" $color="gray500">
          주의사항
        </S.Text>
        <S.Div $padding="16px 8px 0">
          <InfoTextDiv>
            <span>-&nbsp;</span>
            <p>한 번 구매한 쿠폰은 취소할 수 없습니다.</p>
          </InfoTextDiv>
          <InfoTextDiv>
            <span>-&nbsp;</span>
            <p>쿠폰 결제 후 걷쥬 시스템에서는 수 분 후에 사용 이력이 적용됩니다.</p>
          </InfoTextDiv>
          <InfoTextDiv>
            <span>-&nbsp;</span>
            <p>쿠폰 결제 완료 시 앱에서 따로 완료 메시지 수신은 없습니다.</p>
          </InfoTextDiv>
          <InfoTextDiv>
            <span>-&nbsp;</span>
            <p>쿠폰 사용 결과는 쿠폰 리스트에서 조회하여 확인할 수 있습니다</p>
          </InfoTextDiv>
          <InfoTextDiv>
            <span>-&nbsp;</span>
            <p>잔액 관리형 쿠폰은 유효일자 내에 남은 금액을 사용할 수 있습니다.</p>
          </InfoTextDiv>
        </S.Div>
      </S.Div>
    </Container>
  );
}

const InfoTextDiv = styled(S.RowDiv)`
  span,
  p {
    font-size: 14px;
    line-height: 1.5;
    color: ${S.theme.colors.gray700};
  }
`;
