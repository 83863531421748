import React from 'react';
import { theme } from 'styles';
import { checkPixel } from 'utils';
import * as Icons from 'assets/icons';
import styled from 'styled-components';

export function Icon({ name, onClick, ...props }) {
  const IconComponent = Icons[name];

  if (!IconComponent) {
    return null;
  }

  return (
    <IconBox onClick={onClick} {...props}>
      <IconComponent className="icon" />
    </IconBox>
  );
}

const IconBox = styled.span`
  display: inline-block;
  line-height: 0;
  transition: opacity 150ms ease;
  ${({ $transform }) => $transform && `transform:${$transform}`};

  .icon {
    ${({ $width }) => $width && `width:${checkPixel($width)}`};
    ${({ $height }) => $height && `height:${checkPixel($height)}`};
    ${({ $fill }) => $fill && `fill:${theme.colors[$fill] || $fill}`};
    ${({ $stroke }) => $stroke && `stroke:${theme.colors[$stroke] || $stroke}`};
    & path {
      ${({ $pathFill }) => $pathFill && `fill:${theme.colors[$pathFill] || $pathFill}`};
      ${({ $pathStroke }) => $pathStroke && `stroke:${theme.colors[$pathStroke] || $pathStroke}`};
      ${({ $pathOpacity }) => $pathOpacity && `fill-opacity:${$pathOpacity}`};
    }
    & rect {
      ${({ $rectFill }) => $rectFill && `fill:${theme.colors[$rectFill] || $rectFill}`};
    }
    & circle {
      ${({ $circleStroke }) => $circleStroke && `stroke:${theme.colors[$circleStroke] || $circleStroke}`};
    }
  }
`;
