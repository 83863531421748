import { rowCenter, RowCenterDiv, theme } from 'styles';
import styled, { css } from 'styled-components';

const types = {
  default: {
    enabled: css`
      border: 1px solid ${theme.colors.gray300};
      background-color: white;
    `,
    disabled: css`
      background-color: ${theme.colors.lightest};
      border-color: ${theme.colors.lighter};
    `,
  },
  sub: {
    enabled: css`
      background-color: white;
    `,
    disabled: css`
      background-color: ${theme.colors.lightest};
      border-color: ${theme.colors.lighter};
    `,
  },
};

export const Label = styled.label`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  visibility: hidden;
  position: absolute;
`;

export const IconDiv = styled(RowCenterDiv)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  ${({ $type = 'default', $disabled, $checked }) => css`
    ${types[$type][$disabled ? 'disabled' : 'enabled']}

    ${$checked &&
    css`
      background-color: ${$type === 'sub' ? 'white' : theme.colors.primary};
      border-color: ${$type === 'sub' ? 'white' : theme.colors.primary};

      path {
        stroke: ${$type === 'sub' ? theme.colors.primary : 'white'};
      }
    `}
  `}
`;

// button checkbox style
export const BtnCheckLabel = styled.label`
  ${rowCenter}
  border-radius: 6px;
  background-color: white;
  border: 1px solid ${theme.colors.gray200};
  padding: 0 8px;
  height: 40px;
  flex: 1;

  ${({ $checked }) =>
    $checked &&
    css`
      border-color: ${theme.colors.primary};
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 30%;
    `}
`;
