import React from 'react';
import { useSubMenu } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { SettingListView } from './SettingListView';

export function SettingList(props) {
  const navigate = useNavigate();
  const subMenu = useSubMenu();

  return <SettingListView subMenu={subMenu} navigate={navigate} />;
}
