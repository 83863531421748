import React, { useState, useEffect } from 'react';
import { Container } from 'layouts';
import * as S from 'styles';
import * as D from 'data';
import * as C from 'components';
import { useTab } from 'hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function CommunityCallenge(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, currentTab] = useTab(parseInt(searchParams.get('tab'), 10) || 0);
  const [filters, setFilters] = useState({ type: '' });

  const onChange = (name, val) => {
    setFilters((filters) => ({
      ...filters,
      [name]: val,
    }));
  };

  useEffect(() => {
    setSearchParams({ tab: tabIndex, ...filters }, { replace: true });
  }, [tabIndex, filters, setSearchParams]);

  return (
    <Container title="커뮤니티 챌린지">
      <C.Tab list={['참여중', '참여가능', '준비중', '종료']} tabIndex={tabIndex} currentTab={currentTab} />
      <S.List $pTop={16}>
        {D.challengeList.map((item, index) => (
          <S.ListItem
            key={`item_${index}`}
            $mTop={index !== 0 && 16}
            $radius={10}
            $overflow="hidden"
            $shadow="lineShadow"
            onClick={() => navigate(`${item.id}`, { state: item })}
          >
            <S.Div $position="relative">
              <S.RowDiv $position="absolute" $top={8} $left={8} $gap={8} $zIndex={10}>
                <C.Badge $bgColor={item.state === 0 ? 'gray400' : 'primary'} icon="BadgeCheck">
                  {item.state === 0 ? '참여가능' : '참여중'}
                </C.Badge>
              </S.RowDiv>
              <C.Img src={item.img} $height={130} $objectFit="cover" />
            </S.Div>
            <S.Div $padding="16px">
              <S.RowDiv $padding="2px 0" $align="center" $justify="space-between">
                <S.Text $color="gray500" $type="label12">
                  {item.startDate} ~ {item.endDate}
                </S.Text>
                <S.RowDiv $align="center">
                  <C.Icon name="ChallengeWalk" />
                  <S.Text $color="mint" $type="label12">
                    {item.join.toLocaleString()}명 참여중
                  </S.Text>
                </S.RowDiv>
              </S.RowDiv>
              <S.Text $type="b" $color="gray800">
                {item.title}
              </S.Text>
            </S.Div>
          </S.ListItem>
        ))}
      </S.List>
      {/* !list -> */}
      {/* <S.ColCenterDiv $radius={10} $height="calc(100% - 64px)" $mTop={16} $border={{ style: 'dashed' }}>
        <S.Text $color="gray500" $type="14">
          조회된 챌린지가 없습니다.
        </S.Text>
      </S.ColCenterDiv> */}
      {/* <- !list  */}
    </Container>
  );
}
