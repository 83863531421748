import React from 'react';
import * as S from 'styles';
import * as C from 'components';

export function RankingBox(props) {
  return (
    <>
      <S.Div $mTop={8} $shadow="shadows1" $padding="16px" $radius={8}>
        <S.Text $type="m14" $color="assistive">
          순위
        </S.Text>
        <S.RowDiv $mTop={8} $justify="space-between" $align="center">
          <C.Img src="ranking" $height={56} $width={140} />
          <S.Div>
            <S.RowDiv $align="center" $justify="flex-end">
              <S.Text $color="gray600" $type="14">
                798,145
              </S.Text>
              <S.Span color="gray600" $type="m11" $lineHeight="21px">
                &nbsp;명 중
              </S.Span>
            </S.RowDiv>
            <S.Text $color="gray900" $type="b24" $whiteSpace="pre">
              713,780
              <S.Span color="gray900" $type="14">
                &nbsp;번째
              </S.Span>
            </S.Text>
          </S.Div>
        </S.RowDiv>
      </S.Div>
      <S.GridList $grid={3} $gap={8} $pTop={8}>
        <S.ListItem $padding="8px" $radius={8} $border>
          <S.Text $color="gray700" $type="label12" $textAlign="center">
            1위
          </S.Text>
          <S.Text $pTop={4} $color="gray900" $type="14" $textAlign="center">
            천하장사
          </S.Text>
        </S.ListItem>
        <S.ListItem $padding="8px" $radius={8} $border>
          <S.Text $color="gray700" $type="label12" $textAlign="center">
            2위
          </S.Text>
          <S.Text $pTop={4} $color="gray900" $type="14" $textAlign="center">
            이뿌니
          </S.Text>
        </S.ListItem>
        <S.ListItem $padding="8px" $radius={8} $border>
          <S.Text $color="gray700" $type="label12" $textAlign="center">
            3위
          </S.Text>
          <S.Text $pTop={4} $color="gray900" $type="14" $textAlign="center">
            개천용
          </S.Text>
        </S.ListItem>
      </S.GridList>
    </>
  );
}
