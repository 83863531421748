import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { RowCenterDiv, theme } from 'styles';

export const Wrap = styled(RowCenterDiv)`
  gap: 16px;
  height: 62px;
  border-bottom: 1px solid ${theme.colors.gray100};
  padding: 0 8px;
`;

export const SideBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  background-color: white;
  transform: ${({ $open }) => ($open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 300ms linear;
  z-index: 10;
`;

export const MenuList = styled.ul`
  padding-top: 16px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
  row-gap: 8px;
`;

export const SideMenu = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  border-radius: 28px;
  border: 1px solid ${theme.colors.gray200};
  background-color: ${({ $selected }) => ($selected && theme.colors.primary) || 'white'};
  ${({ $selected }) => $selected && `box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08)`};
  color: ${({ $selected }) => ($selected && 'white') || theme.colors.gray700};

  svg {
    path {
      ${({ $second, $selected }) =>
        $second && $selected
          ? `fill:white;`
          : $selected
            ? `stroke: white;`
            : $second
              ? `fill: ${theme.colors.primary};`
              : `stroke: ${theme.colors.primary};`}
    }

    circle {
      ${({ $selected }) => ($selected ? `stroke: white;` : `stroke: ${theme.colors.primary};`)}
    }
  }
`;

export const SideSubMenu = styled(NavLink)`
  padding: 8px;
  width: 100%;
  line-height: 1.5;
  color: ${theme.colors.normal};
`;

// export const SideSubMenu = styled.a`
//   padding: 8px;
//   width: 100%;
//   line-height: 1.5;
//   color: ${theme.colors.normal};
// `;
