import React, { useState } from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import { inquiryList } from 'data/centerData';
import { useNavigate, useLocation } from 'react-router-dom';

export function MyInquiryDetail(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;
  const [modalOpen, setModalOpen] = useState();

  const findType = (item) => {
    const itemType = inquiryList.find((el) => String(el.value) === String(item.type));
    if (itemType) {
      return itemType.name;
    }
  };

  return (
    <>
      <Container title={`[${findType(item)}] ${item.name}`} $padding="0" $height="calc(100svh - 140px)">
        <S.Div $padding="20px">
          <S.Div $pBottom={16} $borderBottom>
            <S.Text $type="b18" $color="gray900">
              [{findType(item)}]&nbsp;{item.name}
            </S.Text>
            <S.Text $pTop={4} $type="14" $color="gray500">
              {item.createDate}
            </S.Text>
          </S.Div>
          <S.Text $color="gray800" $padding={item.img ? '16px 0' : '16px 0 0'} $whiteSpace="pre-wrap">
            {item.con}
          </S.Text>
          {item.img && (
            <S.RowDiv $gap={8}>
              {item.img.map((img, index) => (
                <C.Img
                  key={`img_${index}`}
                  onClick={() => setModalOpen(img)}
                  src={img}
                  $width={72}
                  $height={72}
                  $objectFit="cover"
                  $radius={10}
                  $overflow="hidden"
                />
              ))}
            </S.RowDiv>
          )}
        </S.Div>
        <S.Div $height={8} $width="100%" $bgColor="fill03" />
        {item.answer && (
          <S.Div $padding="20px" $bgColor="white">
            <S.Div $pBottom={16} $borderBottom>
              <S.Text $type="b18" $color="gray900">
                [{findType(item)}]&nbsp;{item.name}에 대한 답변입니다.
              </S.Text>
              <S.Text $pTop={4} $type="14" $color="gray500">
                {item.answerDate}
              </S.Text>
            </S.Div>
            <S.Div $pTop={16}>
              <S.Text $color="gray800">{item.answer}</S.Text>
            </S.Div>
          </S.Div>
        )}
      </Container>
      <S.Div $padding="8px 20px 20px">
        <C.Button $size="l" $type="sub" $leftIcon="List" $gap={4} onClick={() => navigate(-1)}>
          목록
        </C.Button>
      </S.Div>
      <C.FullModal open={modalOpen} setOpen={setModalOpen}>
        <C.Img src={modalOpen} $height="100%" />
      </C.FullModal>
    </>
  );
}
