import styled from 'styled-components';
import { theme } from 'styles';

export const BottomSheet = styled.div`
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: ${({ $open }) => ($open ? '0' : '-100%')};
  background-color: white;
  border-radius: 28px 28px 0 0;
  transition: bottom 350ms linear;
  z-index: 100;
`;

export const Handle = styled.div`
  width: 32px;
  height: 4px;
  margin: 0 auto;
  background-color: ${theme.colors.dark};
  border-radius: 100px;
`;

export const HandleWrap = styled.div`
  padding: 16px;
`;
