import React, { useState, useEffect } from 'react';
import { Div } from 'styles';
import { checkPixel } from 'utils';
import * as Imgs from 'assets/images';
import styled from 'styled-components';
import { Loading } from 'components/loading/Loading';

export function Img({ src, alt, $width, $height, $maxHeight, $objectFit, loadingCallback, ...props }) {
  const [loading, setLoading] = useState(true);
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    if (src) {
      if (src.startsWith('data:image')) {
        setImgSrc(src);
        setLoading(false);
      } else if (Imgs[src]) {
        setImgSrc(Imgs[src]);
        const imgLoader = new window.Image();
        imgLoader.src = Imgs[src];
        imgLoader.onload = () => {
          setLoading(false);
          if (loadingCallback) loadingCallback(false);
        };
      } else {
        setImgSrc(src);
        const imgLoader = new window.Image();
        imgLoader.src = src;
        imgLoader.onload = () => {
          setLoading(false);
          if (loadingCallback) loadingCallback(false);
        };
      }
    }

    return () => setLoading(true);
  }, [src, loadingCallback]);

  return (
    <>
      {loading && <Loading type="inside" />}
      <ImgWrap {...props}>
        <Image
          src={imgSrc}
          alt={alt}
          $width={$width}
          $height={$height}
          $maxHeight={$maxHeight}
          $objectFit={$objectFit}
          $loading={loading}
        />
      </ImgWrap>
    </>
  );
}

const ImgWrap = styled(Div)`
  position: relative;
  height: fit-content;
  line-height: 0;
`;

const Image = styled.img`
  width: ${({ $width }) => ($width && checkPixel($width)) || '100%'};
  height: ${({ $height }) => ($height && checkPixel($height)) || '100%'};
  ${({ $maxHeight }) => $maxHeight && `max-height:${checkPixel($maxHeight)}`};
  object-fit: ${({ $objectFit }) => ($objectFit && $objectFit) || 'contain'};
  transition: all 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
  ${({ $loading }) => ($loading ? `filter: blur(4px);` : `filter: blur(0px);`)}
`;
