import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Checkbox } from 'components/form';
import { Button } from 'components/button';
import nativeFunctions from 'mobile-bridge/functions'
import { ColDiv, Div, H3, rowCenter, RowDiv, theme } from 'styles';
import useAlertContext from 'hooks/useAlertContext';

export function JoinStep1({ setStep, values, setValues }) {
  const { alert, toggleAlert } = useAlertContext();

  const agreeItems = [
    { id: 'agree1', label: '걷쥬 이용약관 (필수)', required: true },
    { id: 'agree2', label: '개인정보 수집이용 동의 (필수)', required: true },
    { id: 'agree3', label: '위치정보 수집이용에 대한 동의 (필수)', required: true },
    { id: 'agree4', label: '제3자 정보제공에 대한 동의 (필수)', required: true },
    { id: 'agree5', label: '이벤트 및 마케팅 정보 제공 동의 (선택)' },
  ];


  const [checkItems, setCheckItems] = useState([]);

  const singleCheck = (name, value) => {
    const updatedItems = value ? [...checkItems, name] : checkItems.filter((el) => el !== name);
    setCheckItems(updatedItems);
  };

  const allCheck = (name, value) => {
    const idArray = value ? agreeItems.map((el) => el.id) : [];
    setCheckItems(idArray);
  };

  useEffect(() => {
    const handleDispatchEvent = (e)=>{
      const detail = e.detail || {};
      console.log(detail);
      if(detail.resultCode !== '00'){
        alert({
          message: "본인인증 오류 발생",
          $btn1: {text: '닫기', onClick: () => toggleAlert(false)}
        });
      }else{
        const {ci, di, hpNum, birthday, gender, name} = detail;
        const data = {ci, di, birthday, name, tel: hpNum, gender: gender === '1'? 'MALE' : 'FEMALE'};
        const vals = { ...values, ...data };
        setValues(vals);
        setStep('step3')
      }
    }

    window.addEventListener('verifyPhoneEvent', handleDispatchEvent);
    return () => window.removeEventListener('verifyPhoneEvent', handleDispatchEvent);
  }, []);

  return (
    <>
      <Div $height="calc(100svh - 286px)">
        <H3 $display="none">서비스 이용약관에 동의해 주세요.</H3>
        <Div $padding="16px 0">
          <Checkbox name="allCheck" onChange={allCheck} checked={agreeItems.length === checkItems.length}>
            아래의 내용에 모두 동의합니다.
          </Checkbox>
        </Div>
        <ColDiv $gap={16}>
          {agreeItems.map(({ id, label }) => (
            <AgreeDiv key={id}>
              <Checkbox
                type="sub"
                name={id}
                value={values[id]}
                onChange={(name, value) => singleCheck(name, value)}
                checked={checkItems.includes(id)}
              >
                {label}
              </Checkbox>
              <TextBtn>보기</TextBtn>
            </AgreeDiv>
          ))}
        </ColDiv>
      </Div>
      <Button
        $size="l"
        onClick={(e) => {
          nativeFunctions.openPhoneVerify();
        }}
        disabled={!agreeItems.every((item) => !item.required || checkItems.includes(item.id))}
      >
        다음
      </Button>
    </>
  );
}

const AgreeDiv = styled(RowDiv)`
  justify-content: space-between;
  align-items: center;
`;

const TextBtn = styled.button`
  ${rowCenter}
  width:40px;
  height: 24px;
  color: ${theme.colors.gray400};
  font-size: 12px;
  font-weight: 500;
  background-color: transparent;
`;
