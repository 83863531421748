import qs from 'qs';
import { isEmptyString } from 'utils';
import { ACCESS_TOKEN } from '../data/constants';

const get = async (urlPath = '', params = {}) => {
  const queryString = qs.stringify(params);
  const url = `${process.env.REACT_APP_API_DOMAIN}${urlPath}?${queryString}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: getAuthorizationHeader(),
      },
    });

    const jsonResponse = await response.json();
    if (!jsonResponse) {
      return null;
    } else {
      return jsonResponse;
    }
  } catch (e) {
    console.error(e);
  }

  return null;
};

const post = async (urlPath = '', params = {}, headers = {}, body = {}) => {
  const queryString = qs.stringify(params);
  const url = `${process.env.REACT_APP_API_DOMAIN}${urlPath}?${queryString}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: getAuthorizationHeader(),
      },
      body,
    });

    const jsonResponse = await response.json();

    if (!jsonResponse) {
      return null;
    } else {
      return jsonResponse;
    }
  } catch (e) {
    console.error(e);
  }

  return null;
};

const request = async (urlPath = '', params = {}, body = {}, method = 'POST', credentials = null) => {
  const queryString = qs.stringify(params);
  const url = `${process.env.REACT_APP_API_DOMAIN}${urlPath}?${queryString}`;

  try {
    const options = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      body: JSON.stringify(body),
    }

    if (credentials) {
      options.credentials = credentials;
    }

    const response = await fetch(url, options);

    const jsonResponse = await response.json();

    if (!jsonResponse) {
      return null;
    } else {
      return jsonResponse;
    }
  } catch (e) {
    console.error(e);
  }

  return null;
};

const ajaxPost = async (urlPath = '', params = {}, body = {}, method = 'POST', credentials = null) => {
  const queryString = qs.stringify(params);
  const url = `${process.env.REACT_APP_API_DOMAIN}${urlPath}?${queryString}`;

  try {
    const options = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      body: JSON.stringify(body),
    }

    if (credentials) {
      options.credentials = credentials;
    }

    const response = await fetch(url, options);

    const jsonResponse = await response.json();

    if (!jsonResponse) {
      return null;
    } else {
      return {status: response.status, result: jsonResponse};
    }
  } catch (e) {
    console.error(e);
  }

  return null;
};

const requestMultipart = async (urlPath = '', params = {}, headers = {}, formData = null, method = 'POST') => {
  const queryString = qs.stringify(params);
  const url = `${process.env.REACT_APP_API_DOMAIN}${urlPath}?${queryString}`;

  try {
    const response = await fetch(url, {
      method,
      headers: {
        ...headers,
        Authorization: getAuthorizationHeader(),
      },
      body: formData,
    });

    if (!response) {
      return null;
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
  }

  return null;
};

const download = async (urlPath = '', params = {}, headers = {}, method = 'GET') => {
  const queryString = qs.stringify(params);
  const url = `${process.env.REACT_APP_API_DOMAIN}${urlPath}?${queryString}`;

  try {
    const response = await fetch(url, {
      method,
      headers: {
        ...headers,
        Authorization: getAuthorizationHeader(),
      },
    });

    if (!response) {
      return null;
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
  }

  return null;
};

const getAPIUrlFromPath = (urlPath, params) => {
  if (isEmptyString(urlPath)) {
    return '';
  }
  const queryString = qs.stringify(params);
  return `${process.env.REACT_APP_API_DOMAIN}${urlPath}?${queryString}`;
};

const getAuthorizationHeader = () => {
  let authorizationValue = '';
  if (localStorage.getItem(ACCESS_TOKEN)) {
    authorizationValue = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
  }

  return authorizationValue
};

const getImageUrl = (path) => {
  if (isEmptyString(path)) {
    return '';
  }
  return `${process.env.REACT_APP_IMG_DOMAIN}${path}`;
};

export default {
  get,
  post,
  request,
  ajaxPost,
  requestMultipart,
  download,
  getAPIUrlFromPath,
  getAuthorizationHeader,
  getImageUrl,
};
