import HttpRequest from '../HttpRequest';

export const getFaqList = async (type, page, size) => {
	const params = { type, page, size };
	const response = await HttpRequest.get('/api/app-qna/faq-list', params);

	return response;
};

export const write = async (data) => {
	const dataForm = new FormData();

	for (const key in data) {
		if (Object.prototype.hasOwnProperty.call(data, key)) {
			const element = data[key];

			if (key === 'imgFile') {
				for (const file of element) {
					console.debug(file);
					dataForm.append(key, file, file.name);
				}
			} else {
				dataForm.append(key, element);
			}
		}
	}
	const response = await HttpRequest.requestMultipart('/api/app-qna/write', {}, {}, dataForm);

	if (response.status === 200) {
		return true;
	} else {
		return false;
	}
};

export default {
	getFaqList,
	write,
};
