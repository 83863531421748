import React from 'react';
import * as S from 'styles';
import * as D from 'data';
import { Container } from 'layouts';
import { useNavigate } from 'react-router-dom';

export function CommunityNotice(props) {
  const navigate = useNavigate();

  return (
    <Container title="커뮤니티 공지사항">
      <S.List>
        {D.myNoticeList.map((item, index) => (
          <S.ListItem
            key={`list_${item.id}`}
            onClick={() => navigate(`${item.id}`, { state: { parentId: item.id, item } })}
            $padding="14.5px 8px"
            $borderBottom={D.myNoticeList.length - 1 !== index}
          >
            <S.Text $color="gray900" $type="m">
              {item.name}
            </S.Text>
            <S.Text $pTop={4} $color="gray500" $type="12" $lineHeight="14px">
              {item.createDate}
            </S.Text>
          </S.ListItem>
        ))}
      </S.List>
    </Container>
  );
}
