import React, { useRef } from 'react';
import { Div, Span, Text } from 'styles';
import * as S from './TextareaStyle';

export function Textarea({ name, value, onChange, placeholder, disabled, $invalid, $hideErrorText, maxLength, ...props }) {
  const ref = useRef();

  const handleOnChange = (e) => {
    let val = e.target.value;

    if (maxLength && val.length > maxLength) {
      val = val.slice(0, maxLength);
    }

    const textarea = ref.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;

    onChange(name, val);
  };

  return (
    <S.TextareaBox $disabled={disabled} $invalid={$invalid?.type === 'error'}>
      <S.Textarea
        ref={ref}
        name={name}
        value={value}
        maxLength={maxLength}
        onChange={handleOnChange}
        placeholder={placeholder}
        disabled={disabled}
        $invalid={$invalid?.type === 'error'}
        {...props}
      />
      {/* maxLength -> */}
      {maxLength && (
        <Text $type="label12" $color="gray500" $whiteSpace="pre" $textAlign="right">
          <Span $type="label12" $color="gray700">
            {value.length}&nbsp;
          </Span>
          / {maxLength}
        </Text>
      )}
      {/* <- maxLength */}
      {($invalid?.type === 'error' || $invalid?.type === 'success' || $invalid?.type === 'helper') &&
        !$hideErrorText && (
          <Text
            $padding="4px 8px 10px 8px"
            $type="12"
            $color={$invalid.type === 'error' ? 'alert' : $invalid.type === 'success' ? 'assistive' : 'supportive'}
          >
            {$invalid.text}
          </Text>
        )}
    </S.TextareaBox>
  );
}
