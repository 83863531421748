import React, { useState } from 'react';
import * as S from 'styles';
import * as C from 'components';
import { useNavigate } from 'react-router-dom';
import { MyInfoView } from './MyInfoView';

export function MyInfo(props) {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [values, setValues] = useState({
    userId: 'ASSAA2022',
    userName: '홍길동',
    phone: '01012341234',
    gender: 'man',
    birthday: '1980. 08. 20',
    postCode: '32256',
    address: '충청남도 홍성군 홍북읍 청사로174번길',
    detailAddress: '',
    email: '',
    acceptAgree: 'true',
    acceptChannel: ['sms', 'email'],
  });

  // 변경 가능한 value 빈값 체크용
  const [invalids, setInvalids] = useState({
    postCode: { type: '', text: '' },
    address: { type: '', text: '' },
    acceptAgree: { type: '', text: '' },
    acceptChannel: { type: '', text: '' },
  });

  const onChange = (name, value, checked) => {
    if (name === 'acceptChannel') {
      let temp = [...(values.acceptChannel || [])];
      if (checked) {
        temp.push(value);
      } else {
        temp = temp.filter((item) => item !== value);
      }
      setValues((values) => ({ ...values, acceptChannel: temp }));
    } else {
      setValues((values) => ({ ...values, [name]: value }));
    }

    if (invalids[name]?.type === 'error' && value === '') {
      setInvalids((prevInvalids) => ({
        ...prevInvalids,
        [name]: { ...prevInvalids[name], type: '' },
      }));
    }
  };

  const close = () => {
    setModalOpen(false);
    navigate('/');
  };

  return (
    <>
      <MyInfoView
        values={values}
        invalids={invalids}
        onChange={onChange}
        setModalOpen={setModalOpen}
        navigate={navigate}
      />
      <C.Modal open={modalOpen} $btn1={{ text: '닫기', onClick: close }}>
        <S.ColCenterDiv>
          <C.Img src="success" $width={60} $height={60} />
          <S.Text $textAlign="center" $pTop={16}>
            나의 정보가 변경되었습니다.
          </S.Text>
        </S.ColCenterDiv>
      </C.Modal>
    </>
  );
}
