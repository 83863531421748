import { Modal } from 'components';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Text } from 'styles';

const AlertContext = createContext({
  alert: () => {},
  toggleAlert: () => {},
});

export function AlertContextProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    type: 'primary', // primary, delete
    message: '',
    $btn1: undefined,
    $btn2: undefined,
    close: () => {},
    onClose: undefined,
  });

  const alert = useCallback((alertData) => {
    toggleAlert(true);
    const closeAlert = () => toggleAlert(false);
    const type = alertData.type || 'primary';

    setData({
      ...alertData,
      close: type === 'primary' ? alertData.close || closeAlert : undefined,
      $btn1: alertData.$btn1,
      $btn2: alertData.$btn2,
    });
  }, []);

  const toggleAlert = useCallback((value) => {
    setOpen((prevState) => value ?? !prevState);
  }, []);

  const value = useMemo(() => {
    const val = {
      alert,
      toggleAlert,
    };

    return val;
  }, [alert, toggleAlert]);

  useEffect(() => {
    if (!open) {
      if (data.onClose && typeof data.onClose === 'function') data.onClose();
    }
  }, [open]);

  return (
    <AlertContext.Provider value={value}>
      {children}

      <Modal
        open={open}
        $btn1={data.$btn1}
        $btn2={data.$btn2}
      >
        <Text $textAlign="center">
          {data.message}
        </Text>
      </Modal>
    </AlertContext.Provider>
  );
}

export function useAlertContext() {
  const context = useContext(AlertContext);
  return context;
}

export default useAlertContext;
