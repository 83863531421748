import React, { useEffect, useState } from 'react';
import { myNoticeList } from 'data/myData';
import { useNavigate } from 'react-router-dom';
import { HomeView } from './HomeView';

export function Home(props) {
  const navigate = useNavigate();
  const max = 6000;
  const count = 2580;
  const percentage = Math.round((count / max) * 100);

  const [notice, setNotice] = useState();

  useEffect(() => {
    if (myNoticeList) {
      setNotice(myNoticeList.slice(0, 2));
    }
  }, []);

  return <HomeView percentage={percentage} count={count} max={max} navigate={navigate} notice={notice} />;
}
