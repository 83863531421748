import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import * as D from 'data';
import { Container } from 'layouts';
import { useNavigate } from 'react-router-dom';

export function ChllengeDetailBox({ values, invalids, onChange, submit, item, sheetOpen, setSheetOpen }) {
  const navigate = useNavigate();

  const max = 6000;
  const count = 4520;
  const percentage = Math.round((count / max) * 100);

  return (
    <>
      <Container title={item.title} $height="unset">
        <C.Img src={item.img} $radius={8} $overflow="hidden" $height={180} $objectFit="cover" />
        <S.RowDiv $padding="8px 0 16px" $gap={16}>
          <C.Badge
            $bgColor={
              D.areaList.find((area) => area.name === item.host)?.color ||
              D.areaList.find((area) => area.name === '기타').color
            }
          >
            {item.host}
          </C.Badge>
          <S.RowDiv $align="center" $padding="4px" $gap={4}>
            <C.Icon name="ChallengeWalk" />
            <S.Text $type="label12" $color="mint">
              {item.join.toLocaleString()}명 참여중
            </S.Text>
          </S.RowDiv>
        </S.RowDiv>
        <S.ColDiv $gap={16} $padding="0 4px 32px">
          <S.RowDiv $align="center" $gap={8}>
            <S.Text $minWidth={80} $width={80} $color="gray500" $type="m14">
              챌린지명
            </S.Text>
            <S.Text $color="gray900">{item.title}</S.Text>
          </S.RowDiv>
          <S.RowDiv $align="center" $gap={8}>
            <S.Text $minWidth={80} $width={80} $color="gray500" $type="m14">
              기간
            </S.Text>
            <S.Text $color="gray900">
              {item.startDate} ~ {item.endDate}
            </S.Text>
          </S.RowDiv>
          <S.RowDiv $align="center" $gap={8}>
            <S.Text $minWidth={80} $width={80} $color="gray500" $type="m14">
              주최기관
            </S.Text>
            <S.Text $color="gray900">{item.host}</S.Text>
          </S.RowDiv>
          <S.RowDiv $align="center" $gap={8}>
            <S.Text $minWidth={80} $width={80} $color="gray500" $type="m14">
              제공 포인트
            </S.Text>
            <S.Text $color="gray900">{item.point.toLocaleString()} 포인트</S.Text>
          </S.RowDiv>
        </S.ColDiv>
        <C.Button $size="l" type={item.state === 1 && 'sub'} onClick={() => item.state === 0 && setSheetOpen(true)}>
          {item.state === 0 ? '참여하기' : '참여중'}
        </C.Button>
      </Container>
      {item.state === 1 && (
        <S.Div $bgColor="fill03" $pTop={8}>
          <S.Div $padding="20px" $bgColor="white">
            <S.Text $color="gray800" $type="b14">
              내 챌린지 현황
            </S.Text>
            <S.ColDiv $pTop={16} $gap={16}>
              <S.Div $shadow="shadows1" $padding="16px" $radius={8}>
                <S.Text $type="m14" $color="assistive">
                  목표 달성
                </S.Text>
                <S.RowDiv $pTop={8} $gap={16} $align="flex-end" $justify="space-between">
                  <S.Div $width={140} $position="relative">
                    <C.ProgressBar $percentage={percentage} $mTop="0" $tooltip />
                  </S.Div>
                  <S.Div>
                    <S.RowDiv $align="center" $justify="flex-end">
                      <S.Text $color="gray600" $type="14">
                        {max}
                      </S.Text>
                      <S.Span color="gray600" $type="m11" $lineHeight="21px">
                        &nbsp;걸음 중
                      </S.Span>
                    </S.RowDiv>
                    <S.Text $color="gray900" $type="b24" $whiteSpace="pre">
                      {count}
                      <S.Span color="gray900" $type="14">
                        &nbsp;걸음
                      </S.Span>
                    </S.Text>
                  </S.Div>
                </S.RowDiv>
              </S.Div>
              <S.Div
                $shadow="shadows1"
                $padding="16px"
                $radius={8}
                onClick={() => navigate(`ranking`, { state: item })}
              >
                <S.Text $type="m14" $color="assistive">
                  순위
                </S.Text>
                <S.RowDiv $pTop={8} $gap={8} $justify="space-between" $align="center">
                  <C.Img src="ranking" $height={56} $width={140} />
                  <S.Div>
                    <S.RowDiv $align="center" $justify="flex-end">
                      <S.Text $color="gray600" $type="14">
                        798,145
                      </S.Text>
                      <S.Span color="gray600" $type="m11" $lineHeight="21px">
                        &nbsp;명 중
                      </S.Span>
                    </S.RowDiv>
                    <S.Text $color="gray900" $type="b24" $whiteSpace="pre">
                      713,780
                      <S.Span color="gray900" $type="14">
                        &nbsp;번째
                      </S.Span>
                    </S.Text>
                  </S.Div>
                </S.RowDiv>
              </S.Div>
            </S.ColDiv>
          </S.Div>
        </S.Div>
      )}
      <S.Div $bgColor="fill03" $width="100vw" $padding="16px 20px">
        <S.Text $type="m14" $color="gray500" $pTop={16}>
          챌린지 상세 규칙
        </S.Text>
        <S.ColDiv $pTop={16} $gap={21}>
          <S.Text $color="gray700" $type="14">
            미션조건(규칙)을 반드시 확인해 주세요.
          </S.Text>
          <S.Text $color="gray700" $type="14">
            [미션조건]
            <br />
            {item.mission}
          </S.Text>
          <S.Text $color="gray700" $type="14">
            **포인트 지급은 23년 1월 1일부터 정책변경으로, “충남도민” 유저에게만 지급이 됩니다. 주소를 꼭 확인하여
            주세요. 주소 미확인 시 받는 불이익은(포인트 미지급 등) 걷쥬에서 책임지지 않습니다.
          </S.Text>
          <S.Text $color="gray700" $type="14">
            *기타 알림사항
            <br />
            -정확한 걸음 수 업로드를 위해 하루에 한 번, 반드시 챌린지에서 걸음 수를 확인해 주세요.
            <br />
            -미션 시작일과 관계 없이 챌린지 참여 신청일부터 종료일까지의 걸음 수를 산정합니다.
            <br />
            -걸음 수를 확인하지 않아 생기는 불이익에 대하여 걷쥬에서는 책임지지 않습니다.
          </S.Text>
        </S.ColDiv>
      </S.Div>
      <C.BottomSheet open={sheetOpen} setOpen={setSheetOpen}>
        <S.Div $padding="0 20px 20px">
          <S.Text $color="gray900" $type="b18">
            챌린지 참여하기
          </S.Text>
          <S.Text $color="gray800" $type="m" $pTop={8}>
            챌린지 참여를 위한 주소를 확인해 주세요.
            <br />
            주소를 변경하시면 나의 정보에 저장됩니다.
          </S.Text>
          <S.Div $padding="16px 0">
            <S.RowDiv $gap={4}>
              <C.Input
                name="postCode"
                required
                value={values.postCode}
                placeholder="우편번호 입력"
                $invalid={invalids.postCode}
                disabled
                $flex={1}
              />
              <C.Button $size="m" $width={60} onClick={() => alert('주소 찾기 연결')}>
                조회
              </C.Button>
            </S.RowDiv>
            <C.Input name="address" required value={values.address} $invalid={invalids.address} $mTop={4} disabled />
            <C.Input
              name="detailAddress"
              placeholder="상세 주소 입력"
              onChange={onChange}
              value={values.detailAddress}
              $invalid={invalids.detailAddress}
              $mTop={4}
            />
          </S.Div>
          <S.RowDiv $gap={4}>
            <C.Button $size="l" type="sub" onClick={() => setSheetOpen(false)}>
              취소
            </C.Button>
            <C.Button
              $size="l"
              onClick={submit}
              //  disabled={!values.postCode && !values.detailAddress}
            >
              확인
            </C.Button>
          </S.RowDiv>
        </S.Div>
      </C.BottomSheet>
    </>
  );
}
