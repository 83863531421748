/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable new-cap */

import React, { useEffect, useState } from 'react';
import { useCookie } from 'hooks';
import { useSearchParams, useNavigate } from "react-router-dom";
import AuthenticationService from 'services/authentication/AuthenticationService';
import userAuthenticationStore from 'stores/authentication/AuthenticationStore';
import useScript from 'hooks/useScript';
import jquery from 'jquery';

import { ACCESS_TOKEN, REFRESH_TOKEN } from 'data/constants';

export function NaverCallback(props) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setCookies } = useCookie();

  const setAuthenticated = userAuthenticationStore((state) => state.setAuthenticated);
  const setUserInfo = userAuthenticationStore((state) => state.setUserInfo);

  const getToken = ()=>{
    let token = '';
    const hashes = window.location.hash.substr(1).split('&');

    for (let i = 0; i < hashes.length; i + 1) {
      if (hashes[i].indexOf('access_token') >= 0) {
        token = hashes[i].split('=')[1];
        break;
      }
    }

    return token;
  }

  useScript('https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js', false, () => {
    const naverIdLogin = new window.naver_id_login(process.env.REACT_APP_NAVER_LOGIN_CLIENT_ID, process.env.REACT_APP_NAVER_LOGIN_CLIENT_CALLBACK_URL);

    const naverToken = getToken();

    naverIdLogin.oauthParams.access_token = naverToken;

    if (!window.naverSignInCallback) {
      window.naverSignInCallback = () => {
        const id = naverIdLogin.getProfileData('id');
        const userId = "NAVER_" + id;
        const email = naverIdLogin.getProfileData('email');
        const name = naverIdLogin.getProfileData('name');
        const nickname = naverIdLogin.getProfileData('nickname');
        console.log(nickname);

        AuthenticationService.login(userId, userId).then((response)=>{
          if(response && response.access_token){
            setCookies(ACCESS_TOKEN, response.access_token);
            localStorage.setItem(ACCESS_TOKEN, response.access_token);
            localStorage.setItem(REFRESH_TOKEN, response.refresh_token);

            setAuthenticated(true);

            AuthenticationService.getMyInfo().then((account)=>{
              setUserInfo(account);
            });

            navigate('/');
          }else{
            const params = {userId, email, nickname: name};
            navigate('/join', {state: params});
          }
        });

      };
    }
    if (!window.$) {
      window.$ = jquery;
      window.jQuery = jquery;
    }
    naverIdLogin.get_naver_userprofile('naverSignInCallback()');

  });

  useEffect(() => {

  }, []);

  return (
    <></>
  );
}
