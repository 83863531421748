import React, { useEffect, useState } from 'react';
import { donationList } from 'data/pointData';
import { useNavigate } from 'react-router-dom';
import { DonationListView } from './DonationListView';

export function DonationList(props) {
  const navigate = useNavigate();
  const [list, setList] = useState();

  useEffect(() => {
    if (donationList) {
      setList(donationList);
    }
  }, []);

  return <DonationListView list={list} navigate={navigate} />;
}
