import { useNavigate } from 'react-router-dom';
import AuthenticationService from 'services/authentication/AuthenticationService';
import userAuthenticationStore from 'stores/authentication/AuthenticationStore';

import { ACCESS_TOKEN, REFRESH_TOKEN } from 'data/constants';

import { useCookie } from './useCookie';

export function useLogout(props) {
  const navigate = useNavigate();
  const setAuthenticated = userAuthenticationStore((state) => state.setAuthenticated);
  const setUserInfo = userAuthenticationStore((state) => state.setUserInfo);

  const logout = () => {
    AuthenticationService.logout().then((success)=>{
      if(success){
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        setUserInfo(null);
        setAuthenticated(false);
        navigate('/');
      }
    });

  };

  return logout;
}
