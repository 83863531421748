import React from 'react';
import { H3 } from 'styles';
import { AuthStep2 } from '../nameAuth/AuthStep2';

export function JoinStep2({ setStep, sampleData, values, setValues }) {
  return (
    <>
      <H3 $display="none">실명 인증을 해주세요.</H3>
      <AuthStep2 type="join" setStep={setStep} sampleData={sampleData} values={values} setValues={setValues} />
    </>
  );
}
