import styled, { css } from 'styled-components';
import { theme } from 'styles';

const types = {
  default: css`
    border: 1px solid ${theme.colors.gray200};
    background-color: #ffffff;
  `,
  focused: css`
    border: 1px solid ${theme.colors.primary};
    box-shadow: inset 0px 0px 0px 3px #dff4f5;
    background-color: #ffffff;
  `,
  disabled: css`
    background-color: ${theme.colors.fill02};
    border: 1px solid ${theme.colors.fill05};
  `,
  error: css`
    border: 1px solid ${theme.colors.alert};
    background-color: #ffffff;
    box-shadow: inset 0px 0px 0px 3px rgba(225, 44, 37, 0.2);
  `,
};

export const TextareaBox = styled.div`
  gap: 4px;
  border-radius: 8px;
  padding: 8px;
  min-height: 120px;
  transition: all 150ms;
  align-items: center;

  ${({ disabled, $invalid }) => (disabled ? types.disabled : $invalid ? types.error : types.default)}

  &:focus-within {
    ${({ disabled, $invalid }) => !disabled && !$invalid && types.focused}
  }
`;

export const Textarea = styled.textarea`
  min-height: 120px;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 8px;
  overflow: hidden;
`;
// ${({ disabled, $invalid }) => (disabled ? types.disabled : $invalid ? types.error : types.default)}

// &:focus {
//   ${types.focused}
//   outline: none;
// }

// &::placeholder {
//   color: ${theme.colors.supportive};
// }
