import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';

export function MyDeviceView({ list, onClick }) {
  return (
    <Container title="디바이스 연결 관리">
      <S.List>
        {list.map((item, index) => (
          <S.ListItem
            key={`item_${item.id}`}
            $display="flex"
            $padding="0 8px"
            $height={62}
            $align="center"
            $justify="space-between"
            $borderBottom={list.length - 1 !== index}
          >
            <S.RowDiv $gap={8} $align="center">
              <S.RowCenterDiv $width={24} $height={24} $radius="50%" $bgColor={item.connection === 1 && 'gray100'}>
                <C.Icon name="Link" $pathStroke={item.connection === 1 && 'primary'} />
              </S.RowCenterDiv>
              <S.Text $color="gray900">{item.name}</S.Text>
            </S.RowDiv>
            <C.Button onClick={() => onClick(item.id)} type={item.connection === 1 && 'sub'} $size="s" $width={81}>
              {item.connection === 0 ? '기기연결' : '연결해제'}
            </C.Button>
          </S.ListItem>
        ))}
      </S.List>
    </Container>
  );
}
