import React from 'react';
import * as S from 'styles';
import { Container } from 'layouts';
import { useLocation } from 'react-router-dom';

export function SettingInfoDetail(props) {
  const location = useLocation();
  const { state } = location;

  return (
    <Container title={state.name}>
      <S.List>
        {state?.con?.map((item, idx) => (
          <S.ListItem key={`${state.id}_${idx}`}>
            {item.title && (
              <S.Text $type="b14" $color="gray900">
                {item.title}
              </S.Text>
            )}
            {item.subTitle && (
              <S.Text $whiteSpace="pre-wrap" $type="m14" $color="gray900">
                {item.subTitle}
              </S.Text>
            )}
            {item.sub && (
              <S.Text $type="b14" $color="gray900" $pTop={state.type === 'personal' ? 20 : idx !== 0 ? 60 : 20}>
                {item.sub}
              </S.Text>
            )}
            {item.con && (
              <S.Text $whiteSpace="pre-wrap" $type="m14" $color="gray900" $pTop={20}>
                {item.con}
              </S.Text>
            )}
            {item.subCon && (
              <S.Text $whiteSpace="pre-wrap" $type="m14" $color="gray900" $padding="20px 0 6px">
                {item.subCon}
              </S.Text>
            )}
            {item.desc && (
              <S.Text $whiteSpace="pre-wrap" $type="m14" $color="gray900" $pTop={item.pTop && item.pTop}>
                {item.desc}
              </S.Text>
            )}
            {item.con2 && (
              <S.Text $whiteSpace="pre-wrap" $type="m14" $color="gray900" $pTop={item.pTop && item.pTop}>
                {item.con2}
              </S.Text>
            )}
            {item.desc2 && (
              <S.Text $whiteSpace="pre-wrap" $type="m14" $color="gray900" $pTop={item.pTop && item.pTop}>
                {item.desc2}
              </S.Text>
            )}
          </S.ListItem>
        ))}
      </S.List>
    </Container>
  );
}
