import React, { useState, useRef } from 'react';
import * as S from 'styles';
import * as C from 'components';
import { Container } from 'layouts';
import { useRandomColor } from 'hooks';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

export function Profile(props) {
  const fileRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { type } = location.state;
  const [modalOpen, setModalOpen] = useState();
  const [profileColor, setProfileColor] = useState(useRandomColor());
  const [values, setValues] = useState({
    img: '',
    nickName: '',
    desc: '',
  });

  const onChange = (name, val) => {
    setValues({ ...values, [name]: val });
  };

  const imgOnChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setValues((values) => ({
          ...values,
          img: loadEvent.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Container title={type === 'edit' ? '내 프로필' : '참여신청'} $height="calc(100% - 142px)">
        {type !== 'edit' && (
          <>
            <S.Text $type="b24" $color="gray900">
              먼저 모임 프로필을
              <br />
              만들어주세요.
            </S.Text>
            <S.Text $color="gray700" $type="14">
              모든 모임에서 동일하게
              <br />
              사용하는 프로필이에요.
            </S.Text>
          </>
        )}
        <ProfileBox $bgColor={profileColor}>
          <S.Div $width={96} $height={96} $overflow="hidden" $radius="50%">
            <C.Img
              src={values.img ? values.img : 'randomUser'}
              alt="프로필"
              $width={96}
              $height={96}
              $objectFit="cover"
            />
          </S.Div>
          <CameraBox onClick={() => fileRef.current.click()}>
            <C.Icon name="Camera" />
            <input type="file" ref={fileRef} onChange={imgOnChange} accept="image/*" />
          </CameraBox>
        </ProfileBox>
        <S.ColDiv $pTop={32} $gap={16}>
          <C.Input
            name="nickName"
            value={values.nickName}
            onChange={onChange}
            placeholder="닉네임 입력"
            maxLength={12}
          />
          <C.Textarea name="desc" value={values.desc} onChange={onChange} placeholder="자기소개 입력" maxLength={200} />
        </S.ColDiv>
      </Container>
      {type !== 'edit' ? (
        <>
          <S.Div $padding="8px 20px 20px">
            <C.Button $size="l" onClick={() => setModalOpen(true)}>
              참여신청
            </C.Button>
          </S.Div>
          <C.Modal
            open={modalOpen}
            $btn1={{
              text: '닫기',
              onClick: () => {
                setModalOpen(false);
                navigate(-1);
              },
            }}
          >
            <S.ColCenterDiv>
              <C.Img src="success" $width={60} $height={60} />
              <S.Text $textAlign="center" $pTop={16}>
                참여 신청이
                <br />
                완료되었습니다.
              </S.Text>
            </S.ColCenterDiv>
          </C.Modal>
        </>
      ) : (
        <>
          <S.RowDiv $padding="8px 20px 20px" $gap={8}>
            <C.Button $size="l" $type="sub" onClick={() => navigate(-1)}>
              취소
            </C.Button>
            <C.Button $size="l" onClick={() => setModalOpen(true)}>
              저장
            </C.Button>
          </S.RowDiv>
          <C.Modal
            open={modalOpen}
            $btn1={{
              text: '닫기',
              onClick: () => {
                setModalOpen(false);
                navigate(-1);
              },
            }}
          >
            <S.ColCenterDiv>
              <C.Img src="success" $width={60} $height={60} />
              <S.Text $textAlign="center" $pTop={16}>
                프로필 정보가
                <br />
                변경되었습니다.
              </S.Text>
            </S.ColCenterDiv>
          </C.Modal>
        </>
      )}
    </>
  );
}

const ProfileBox = styled(S.RowDiv)`
  position: relative;
  margin-top: 16px;
  width: 96px;
  height: 96px;
  border-radius: 50%;x
  box-shadow: ${S.theme.colors.shadows1};
`;

const CameraBox = styled(S.RowCenterDiv)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${S.theme.colors.gray700};
  box-shadow: ${S.theme.colors.lineShadow};

  input {
    display: none;
  }
`;
