import React from 'react';
import * as S from 'styles';
import * as C from 'components';
import * as D from 'data';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from 'layouts';
import styled from 'styled-components';

export function StampHistoryDetailList(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;

  return (
    <Container title={`${item} 스탬프 투어`} $height="calc(100svh - 62px)">
      <S.List>
        {D.stampHistoryList.map((i, index) => (
          <ListBox key={`list_${index}`} onClick={() => navigate(`${i.id}`, { state: i })} $mTop={index !== 0 && 16}>
            <C.Img src={i.img} $height={130} $objectFit="cover" />
            <C.Badge
              className="badge"
              $bgColor={
                D.areaList.find((area) => area.name === item)?.color ||
                D.areaList.find((area) => area.name === '기타').color
              }
            >
              {item}
            </C.Badge>
            <S.Div $padding="16px">
              <S.Text $color="gray800" $type="b">
                {i.title} 투어
              </S.Text>
              <S.RowDiv $pTop={4} $gap={8} $align="center" $justify="flex-end">
                <S.Text $color="gray700" $type="m14">
                  나의 스탬프
                </S.Text>
                <S.RowDiv $align="center">
                  <S.Text $color="primary" $type="b24">
                    {i.myStamp}
                  </S.Text>
                  <S.Text $color="gray600">&nbsp;/&nbsp;{i.stamp}</S.Text>
                </S.RowDiv>
              </S.RowDiv>
            </S.Div>
          </ListBox>
        ))}
      </S.List>
    </Container>
  );
}

const ListBox = styled(S.ListItem)`
  position: relative;
  border: 1px solid ${S.theme.colors.fill05};
  border-radius: 10px;
  overflow: hidden;
  line-height: 0;

  .badge {
    position: absolute;
    top: 8px;
    left: 8px;
  }
`;
