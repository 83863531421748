import React, { useEffect } from 'react';
import { useTab } from 'hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StampListView } from './StampListView';

export function StampList(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [tabIndex, currentTab] = useTab(parseInt(searchParams.get('tab'), 10) || 0);

  useEffect(() => {
    setSearchParams({ tab: tabIndex }, { replace: true });
  }, [tabIndex, setSearchParams]);

  return <StampListView navigate={navigate} tabIndex={tabIndex} currentTab={currentTab} />;
}
